import axios from 'axios';
import {throwToastError} from "../Companent/utils";
import moment from "moment";

export default class API {
  static ApiUrl = process.env.REACT_APP_API_URL;
  // static ApiUrl = 'http://localhost:3100';
  static Token = localStorage.getItem('token') ?? "";

  static async GetToken(login, password) {
    const curToken = await axios.post(this.ApiUrl + '/user/auth', {
      "user": login,
      "password": password
    })
    localStorage.setItem("token", curToken.data.token);
    localStorage.setItem("group", curToken.data.group);
    this.Token = curToken.data.token;
    return !!curToken.data.token;
  }

  static checkToken(resp) {
    return resp === 'no auth'
  }

  static async Get(url) {
    try {
      const query = await axios({
        method: 'get',
        url: this.ApiUrl + url,
        headers: {
          token: this.Token,
        }
      })
      if (query.data.error) {
        if (this.checkToken(query.data.error)) {
          localStorage.setItem("token", "");
          window.location.reload();
        }
        alert(query.data.error)
      } else {
        return query
      }

    } catch (error) {
      if (error.response.status === 401) {
        localStorage.setItem("token", "");
        window.location.reload();
      }
      throwToastError(`${error.message} (${moment(new Date()).format('DD.MM.YYYY HH:mm"')})`)
      return error.response
    }
  }

  static async Post(url, data) {
    try {
      const query = await axios({
        method: 'post',
        url: this.ApiUrl + url,
        headers: {
          token: this.Token,
        },
        data: data
      })
      if (query.data.error) {
        if (this.checkToken(query.data.error)) {
          localStorage.setItem("token", "");
          window.location.reload();
        }
      } else {
        return query
      }

    } catch (error) {
      if (error.response.status === 401) {
        localStorage.setItem("token", "");
        window.location.reload();
      }
      throwToastError(`${error.message} (${moment(new Date()).format('DD.MM.YYYY HH:mm"')})`)
      return error.response
    }
  }

  static async Put(url, data) {
    try {
      const query = await axios({
        method: 'put',
        url: this.ApiUrl + url,
        headers: {
          token: this.Token,
        },
        data: data
      })
      if (query.data.error) {
        if (this.checkToken(query.data.error)) {
          localStorage.setItem("token", "");
          window.location.reload();
        }
        alert(query.data.error)
      } else {
        return query
      }

    } catch (error) {
      if (error.response.status === 401) {
        localStorage.setItem("token", "");
        window.location.reload();
      }
      throwToastError(`${error.message} (${moment(new Date()).format('DD.MM.YYYY HH:mm"')})`)
      return error.response
    }
  }

  static async Delete(url) {
    try {
      const query = await axios({
        method: 'delete',
        url: this.ApiUrl + url,
        headers: {
          token: this.Token,
        }
      })
      if (query.data.error) {
        if (this.checkToken(query.data.error)) {
          localStorage.setItem("token", "");
          window.location.reload();

        }
        alert(query.data.error)
      } else {
        return query
      }

    } catch (error) {
      if (error.response.status === 401) {
        localStorage.setItem("token", "");
        window.location.reload();
      }
      throwToastError(`${error.message} (${moment(new Date()).format('DD.MM.YYYY HH:mm"')})`)
      return error.response
    }
  }

  static async GetApps(offset, limit, clientId, search) {
    return await this.Get(`/app/get?offset=${offset}&limit=${limit}${parseInt(clientId) !== 0 && clientId !== null ? `&client_id=${clientId}` : ''}&search=${search}`)
  }

  static async GetClients(offset, limit, search) {
    return await this.Get(`/client/get?offset=${offset}&limit=${limit}&search=${search}`)
  }

  static async GetCampaigns(offset, app, clientId, status, dateStart, dateStop, limit) {
    return await this.Get(
      `/campaign/get?offset=${offset}${parseInt(app) !== 0 ? `&app=${app}` : ''}${parseInt(clientId) !== 0 ? `&client_id=${clientId}` : ''}${parseInt(status) !== 0 ? `&status=${status}` : ''}${parseInt(dateStart) !== 0 ? `&date_start=${dateStart}` : ''}${parseInt(dateStop) !== 0 ? `&date_stop=${dateStop}` : ''}&limit=${limit}`
    )
  }

  static async GetSuppliers(offset, limit, search) {
    return await this.Get(`/supplier/get?offset=${offset}&limit=${limit}&search=${search}`)
  }

  static async GetAsoApps() {
    return await this.Get('/app/aso/get')
  }

  static async NewCampaign(data) {
    return await this.Post('/campaign/new', data)
  }

  static async NewApp(data) {
    return await this.Post('/app/new', data)
  }

  static async NewClient(data) {
    return await this.Post('/client/new', data)
  }

  static async EditClient(data) {
    return await this.Post('/client/edit', data)
  }

  static async NewSupplier(data) {
    return await this.Post('/supplier/new', data)
  }

  static async EditSupplier(data) {
    return await this.Post('/supplier/edit', data)
  }

  static async addClientBalance(data) {
    return await this.Post('/client/addBalance', data)
  }

  static async addSupplierBalance(data) {
    return await this.Post('/supplier/addBalance', data)
  }

  static async GetLogs(data) {
    return await this.Post('/logs/get', data)
  }

  static async findOneClient(id) {
    return await this.Get('/client/one?id=' + id)
  }

  static async returnMoney(data) {
    return await this.Put('/campaign/cancel', data);
  }

  static async archiveCompany(data) {
    return await this.Post('/campaign/delete', data);
  }

  static async getInfoCampaign(id) {
    return await this.Get('/campaign/one?id=' + id);
  }

  static async appDelete(data) {
    return await this.Post('/app/delete', data);
  }

  static async analyticsByClients(dateStart, dateStop) {
    return await this.Get(`/analytics/byClients?dateStart=${dateStart}&dateStop=${dateStop}`);
  }

  static async analyticsByApps(dateStart, dateStop) {
    return await this.Get(`/analytics/byApps?dateStart=${dateStart}&dateStop=${dateStop}`);
  }

  static async analyticsByDate(dateStart, dateStop, client, app) {
    return await this.Get(`/analytics/byDate?dateStart=${dateStart}&dateStop=${dateStop}${client ? `&client=${client}` : ''}${app ? `&app=${app}` : ''}`);
  }

  static async getAppStatistic(id, dateStart, dateStop) {
    return await this.Get(`/app/info?id=${id}&dateStart=${dateStart}&dateStop=${dateStop}`);
  }

  static async findOneApp(appId) {
    if (appId) {
      return await this.Get(`/app/one?id=${appId}`)
    }
  }

  static async getAllUsers(offset, limit, search) {
    return await this.Get(`/user/get?search=${search}`);
  }

  static async getAllTariffs(offset, limit, search) {
    return await this.Get(`/user/tariffs?search=${search}`);
  }

  static async getClientsList(id) {
    return await this.Get(`/user/clientsList?id=${id}`);
  }

  static async changeCustomTariff(data) {
    return await this.Post('/user/changeCustomTariff', data);
  }

  static async changeGroup(data) {
    return await this.Put('/user/changeGroup', data);
  }

  static async changeDefaultTariff(id) {
    return await this.Put(`/user/changeDefaultTariff?id=${id}`);
  }

  static async addTariff(data) {
    return await this.Post('/user/addTariff', data);
  }

  static async editTariff(data) {
    return await this.Put('/user/editTariff', data);
  }

  static async deleteTariff(id) {
    return await this.Delete(`/user/deleteTariff?id=${id}`);
  }

  static async editAdminTariff(data) {
    return await this.Put('/user/editAdminTariff', data);
  }
}
