import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import NewCampaignForm from "../Modules/NewCampaignForm";
import NewCampaignCountriesList from "../Modules/NewCampaignCountriesList";
import NewCampaignTotalBlock from "../Modules/NewCampaignTotalBlock";
import {
  LS_NEW_CAMPAIGN_APP,
  LS_NEW_CAMPAIGN_CHECKBOXES_VALUE,
  LS_NEW_CAMPAIGN_CLIENT,
  LS_NEW_CAMPAIGN_COUNTRIES,
  LS_NEW_CAMPAIGN_DATE_START,
  LS_NEW_CAMPAIGN_DATE_STOP,
  LS_NEW_CAMPAIGN_DELAY_TIME,
  LS_NEW_CAMPAIGN_IMPORTED,
  LS_NEW_CAMPAIGN_SHADOW_INSTALLS_BP_COUNTRIES,
  LS_NEW_CAMPAIGN_SHADOW_INSTALLS_COUNTRIES,
  LS_NEW_CAMPAIGN_SHADOW_RATES_COUNTRIES,
  LS_NEW_CAMPAIGN_SUPPLIER,
  LS_NEW_CAMPAIGN_TYPE
} from "../constants";
import {Loader} from "rsuite";
import {getDataFromLS, setDataToLS} from '../../utils'
import {Modal} from "@mui/material";
import ImportFileModule from "../Modules/ImportFileModule";

const CreateNewCampaign = forwardRef(({
                                        allSuppliersList,
                                        allApplicationsList,
                                        allClientsList,
                                        selectedSupplier,
                                        selectedType,
                                        selectedClient,
                                        selectedApplication,
                                        countriesList,
                                        currentClientCost,
                                        selectedApplicationInfo,
                                        handleSetCurrentClientCost,
                                        handleSetCountriesList,
                                        selectedDateStop,
                                        selectedDateStart,
                                        handleSetApplication,
                                        selectedClientInfo,
                                        handleSetClient,
                                        handleSetDateStart,
                                        handleSetDateStop,
                                        handleSetSupplier,
                                        handleSetType,
                                        handleSendNewCampaign,
                                        getNewAllAppsList,
                                        checkboxesValue,
                                        handleSetCheckboxesValue,
                                        selectedDelayStart,
                                        handleSetSelectedDelayStart
                                      }, ref) => {
  const [storageIsLoading, setStorageIsLoading] = useState(true);
  const [isImported, setIsImported] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  const fetchDataFromStorage = async () => {
    return new Promise((resolve) => {
      const supplier = Number(getDataFromLS(LS_NEW_CAMPAIGN_SUPPLIER));
      !!supplier && handleSetSupplier(supplier);
      const type = Number(getDataFromLS(LS_NEW_CAMPAIGN_TYPE));
      !!type && handleSetType(type);
      const client = Number(getDataFromLS(LS_NEW_CAMPAIGN_CLIENT));
      !!client && handleSetClient(client)
      const app = Number(getDataFromLS(LS_NEW_CAMPAIGN_APP));
      if (!!app && allApplicationsList.find((appItem) => appItem.id === Number(app))) {
        handleSetApplication(app);
      }
      const dateStart = getDataFromLS(LS_NEW_CAMPAIGN_DATE_START);
      !!dateStart ? handleSetDateStart(new Date(dateStart)) : handleSetDateStart(new Date());
      const dateStop = getDataFromLS(LS_NEW_CAMPAIGN_DATE_STOP);
      !!dateStop ? handleSetDateStop(new Date(dateStop)) : handleSetDateStop(null);
      const checkboxes = getDataFromLS(LS_NEW_CAMPAIGN_CHECKBOXES_VALUE);
      !!checkboxes && handleSetCheckboxesValue(checkboxes);
      const delayTime = getDataFromLS(LS_NEW_CAMPAIGN_DELAY_TIME);
      !!delayTime && handleSetSelectedDelayStart(delayTime);
      const countries = getDataFromLS(LS_NEW_CAMPAIGN_COUNTRIES);
      countries && !!countries.length && handleSetCountriesList(countries)
      const imported = getDataFromLS(LS_NEW_CAMPAIGN_IMPORTED);
      Boolean(imported) && setIsImported(true);

      resolve();
    })
  }

  useEffect(() => {
    fetchDataFromStorage().then(() => {
      setStorageIsLoading(false)
    })
  }, [])

  const handleSelectSupplier = (supplierId) => {
    handleSetSupplier(supplierId)
    setDataToLS(LS_NEW_CAMPAIGN_SUPPLIER, supplierId);
  }

  const handleSelectClient = (clientId) => {
    handleSetClient(clientId)
    setDataToLS(LS_NEW_CAMPAIGN_CLIENT, clientId);
  }

  const handleSelectType = (newType) => {
    handleSetType(newType)

    const typeKeys = {
      '1': LS_NEW_CAMPAIGN_SHADOW_INSTALLS_COUNTRIES,
      '2': LS_NEW_CAMPAIGN_SHADOW_RATES_COUNTRIES,
      '3': LS_NEW_CAMPAIGN_SHADOW_INSTALLS_BP_COUNTRIES,
    };

    if (selectedType && countriesList.length > 0) {
      const key = typeKeys[selectedType];
      if (key) {
        setDataToLS(key, countriesList);
        handleSetCountriesList([]);
        localStorage.removeItem(LS_NEW_CAMPAIGN_COUNTRIES);
      }
    }

    if (newType) {
      setDataToLS(LS_NEW_CAMPAIGN_TYPE, newType);
      const key = typeKeys[newType];

      if (key) {
        const shadowCountries = localStorage.getItem(key);

        if (shadowCountries) {
          const parsedCountries = JSON.parse(shadowCountries);
          handleSetCountriesList(parsedCountries);
          setDataToLS(LS_NEW_CAMPAIGN_COUNTRIES, parsedCountries);
        }
      }
    } else {
      Object.values(typeKeys).forEach(key => localStorage.removeItem(key));
      localStorage.removeItem(LS_NEW_CAMPAIGN_TYPE);
    }
  }

  const handleSelectApplication = (applicationId) => {
    handleSetApplication(applicationId)
    setDataToLS(LS_NEW_CAMPAIGN_APP, applicationId);
  }

  const handleSelectDates = (dates) => {
    //todo разобраться с чертовщиной с конечной датой
    const [startDate, stopDate] = dates;
    handleSetDateStart(startDate);
    handleSetDateStop(stopDate);
    setDataToLS(LS_NEW_CAMPAIGN_DATE_START, startDate);
    setDataToLS(LS_NEW_CAMPAIGN_DATE_STOP, stopDate);
  }

  const handleSelectCheckboxesValue = (value) => {
    handleSetCheckboxesValue(value);
    setDataToLS(LS_NEW_CAMPAIGN_CHECKBOXES_VALUE, value);
  }

  const handleSelectDelayStartTime = (timeString) => {
    handleSetSelectedDelayStart(timeString);
    setDataToLS(LS_NEW_CAMPAIGN_DELAY_TIME, timeString);
  }

  const handleSelectCountriesList = (newCountriesList) => {
    handleSetCountriesList(newCountriesList);
    setDataToLS(LS_NEW_CAMPAIGN_COUNTRIES, newCountriesList);
  };

  const handleSetIsImported = () => {
    setIsImported(true);
    setDataToLS(LS_NEW_CAMPAIGN_IMPORTED, 1);
  }

  const handleToggleImportModal = () => setOpenImportModal(!openImportModal);

  const handleResetForm = () => {
    handleSelectSupplier(0)
    handleSelectType(0);
    handleSelectApplication(0);
    handleSelectClient(0);
    handleSelectDates([new Date(), null]);
    handleSelectCheckboxesValue(0);
    handleSelectDelayStartTime(null);
    handleSelectCountriesList([]);

    setIsImported(false);
    localStorage.removeItem(LS_NEW_CAMPAIGN_IMPORTED);

    handleSetCurrentClientCost(0);
  }

  useImperativeHandle(ref, () => ({
    handleResetForm: handleResetForm
  }))

  return (
    (allClientsList.length > 0 && allApplicationsList.length > 0 && allSuppliersList.length > 0 && !storageIsLoading) ?
      <>
        <NewCampaignForm
          scenario={isImported ? 'Import' : 'Create'}
          allSuppliersList={allSuppliersList}
          allApplicationsList={allApplicationsList}
          allClientsList={allClientsList}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedApplication={selectedApplication}
          selectedType={selectedType}
          handleSelectSupplier={handleSelectSupplier}
          handleSelectClient={handleSelectClient}
          handleSelectType={handleSelectType}
          handleSelectApplication={handleSelectApplication}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          handleSelectDates={handleSelectDates}
          countriesList={countriesList}
          currentClientCost={currentClientCost}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          handleToggleImportModal={handleToggleImportModal}
          checkboxesValue={checkboxesValue}
          handleSelectCheckboxesValue={handleSelectCheckboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSelectDelayStartTime={handleSelectDelayStartTime}
        />
        <NewCampaignCountriesList
          countriesList={countriesList}
          handleSetCountriesList={handleSelectCountriesList}
          selectedType={selectedType}
          dateStart={selectedDateStart}
          dateStop={selectedDateStop}
        />
        <NewCampaignTotalBlock
          handleSendNewCampaign={handleSendNewCampaign}
          currentClientCost={currentClientCost}
          countriesList={countriesList}
          selectedType={selectedType}
          handleResetForm={handleResetForm}
        />
        <Modal
          open={openImportModal}
          onClose={handleToggleImportModal}
        >
          <ImportFileModule
            allApplicationsList={allApplicationsList}
            getNewAllAppsList={getNewAllAppsList}
            handleSelectApplication={handleSelectApplication}
            handleSelectDates={handleSelectDates}
            handleSelectCountriesList={handleSelectCountriesList}
            handleToggleImportModal={handleToggleImportModal}
            handleSetIsImported={handleSetIsImported}
            handleResetForm={handleResetForm}
          />
        </Modal>
      </> :
      <Loader backdrop size="lg" content="loading..." vertical/>
  );
});

export default CreateNewCampaign;