import React from 'react';
import {BasicInputBlock, Input, InputClearButton, InputErrorText} from "./styled";

const BasicInput = ({onChange, value, onError, disabled, placeholder, errorText, handleClear, size}) => {
  return (
    <BasicInputBlock>
      <Input
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        type={'text'}
        size={size}
        data-on-error={onError}
      />
      {!!value.length && !disabled && <InputClearButton onClick={handleClear}>✖</InputClearButton>}
      {onError && errorText && <InputErrorText>{errorText}</InputErrorText>}
    </BasicInputBlock>
  );
};

export default BasicInput;