export const LS_NEW_CAMPAIGN_SUPPLIER = 'new_campaign_supplier';
export const LS_NEW_CAMPAIGN_TYPE = 'new_campaign_type';
export const LS_NEW_CAMPAIGN_CLIENT = 'new_campaign_client';
export const LS_NEW_CAMPAIGN_APP = 'new_campaign_app';
export const LS_NEW_CAMPAIGN_DATE_START = 'new_campaign_date_start';
export const LS_NEW_CAMPAIGN_DATE_STOP = 'new_campaign_date_stop';
export const LS_NEW_CAMPAIGN_CHECKBOXES_VALUE = 'new_campaign_checkboxes_value';
export const LS_NEW_CAMPAIGN_DELAY_TIME = 'new_campaign_delay_time';
export const LS_NEW_CAMPAIGN_COUNTRIES = 'new_campaign_countries';
export const LS_NEW_CAMPAIGN_SHADOW_INSTALLS_COUNTRIES = 'new_campaign_shadow_installs_countries';
export const LS_NEW_CAMPAIGN_SHADOW_RATES_COUNTRIES = 'new_campaign_shadow_rates_countries';
export const LS_NEW_CAMPAIGN_SHADOW_INSTALLS_BP_COUNTRIES = 'new_campaign_shadow_installs_bp_countries';
export const LS_NEW_CAMPAIGN_IMPORTED = 'new_campaign_imported';

export const LS_PROMOTE_CAMPAIGN_SUPPLIER = 'promote_campaign_supplier';
export const LS_PROMOTE_CAMPAIGN_TYPE = 'promote_campaign_type';
export const LS_PROMOTE_CAMPAIGN_CLIENT = 'promote_campaign_client';
export const LS_PROMOTE_CAMPAIGN_APP = 'promote_campaign_app';
export const LS_PROMOTE_CAMPAIGN_DATE_START = 'promote_campaign_date_start';
export const LS_PROMOTE_CAMPAIGN_DATE_STOP = 'promote_campaign_date_stop';
export const LS_PROMOTE_CAMPAIGN_CHECKBOXES_VALUE = 'promote_campaign_checkboxes_value';
export const LS_PROMOTE_CAMPAIGN_DELAY_TIME = 'promote_campaign_delay_time';
export const LS_PROMOTE_CAMPAIGN_COUNTRIES = 'promote_campaign_countries';
export const LS_PROMOTE_SHADOW_INSTALLS_COUNTRIES = 'promote_shadow_installs_countries';
export const LS_PROMOTE_SHADOW_RATES_COUNTRIES = 'promote_shadow_rates_countries';
export const LS_PROMOTE_SHADOW_INSTALLS_BP_COUNTRIES = 'promote_shadow_installs_bp_countries';


export const LS_DUPLICATE_CAMPAIGN_ID = 'duplicate_campaign_id';
export const LS_DUPLICATE_CAMPAIGN_DATE_START = 'duplicate_campaign_date_start';
export const LS_DUPLICATE_CAMPAIGN_DELAY_TIME = 'duplicate_campaign_delay_time';
export const LS_DUPLICATE_CAMPAIGN_CHECKBOXES_VALUE = 'duplicate_campaign_checkboxes_value';
export const LS_DUPLICATE_CAMPAIGN_DATE_STOP = 'duplicate_campaign_date_stop';
export const LS_DUPLICATE_CAMPAIGN_COUNTRIES = 'duplicate_campaign_countries';


export const LS_EXTEND_CAMPAIGN_ID = 'extend_campaign_id';
export const LS_EXTEND_CAMPAIGN_COUNTRIES = 'extend_campaign_countries';

export const dateFormats = ["DD.MM", "DD,MM", "DD/MM", "DD-MM", "DD.MM.YY", "DD,MM,YY", "DD/MM/YY", "DD-MM-YY", "DD.MM.YYYY", "DD,MM,YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "D.MM", "D.MM.YY", "D.MM.YYYY", "D,MM", "D,MM,YY", "D,MM,YYYY", "D/MM", "D/MM/YY", "D/MM,YYYY", "YYYY-MM-DD"]

export const ERRORS_BITES = {
  lack_app: 1,
  lack_supplier: 2,
  lack_type: 4,
  lack_start_date: 8,
  lack_stop_date: 16,
  lack_client: 32,
  lack_countries: 64,
  unselect_country: 128,
  lack_money: 256,
  too_mach_countries: 512,
  no_values_keys: 1024,
};

export const ERRORS_TEXTS = {
  lack_app: "Please choose an application",
  lack_supplier: "Please choose a supplier",
  lack_type: 'Please choose a type',
  lack_start_date: 'Please select the campaign period',
  lack_stop_date: 'Please select the campaign period',
  lack_client: 'Please choose a client',
  lack_countries: 'Please add at least one country',
  unselect_country: 'Please check selected countries',
  lack_money: 'Not enough money to run this campaign',
  too_mach_countries: 'You can run campaign with delayed start only for one country',
  no_values_keys: 'Please fill in at least one value'
}