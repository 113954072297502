import styled from "styled-components";

export const CheckboxesBlockList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const CheckboxItemRow = styled.div`
  display: flex;
  align-items: ${props => props.role === 'date' ? 'flex-start' : 'center'};
  gap: 40px;
  position: relative;
  padding-left: 160px;
`;

export const CheckboxItemRowLong = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const CheckboxItemDateRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CheckboxItemSmall = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  min-width: 80px;
`;

export const CheckboxItemTitle = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: ${props => !props.disabled ? '#3E3E3E' : '#b9b9b9'};
  width: max-content;
  display: block;

  position: ${props => props.role === 'title' ? 'absolute' : 'relative'};
  left: 0;
  ${props => props.role === 'title' && 'width: 132px;'};
  text-align: right;
`;

export const CheckboxItemInput = styled.input`
  width: 20px;
  height: 20px;
`;

export const CheckboxItemTimeBlock = styled.div`
  width: 150px;
`;

export const TimeZoneSelect = styled.select`
  border: 1px solid #3E3E3E;
  background-color: transparent;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #3E3E3E;
  border-radius: 5px;
  height: 26px;
`


