import React, {useEffect, useState} from 'react';
import {BalanceBlock, BalanceText, BalanceValue} from "./styled";
import {getTotalKeywordsCountFromCountries} from "../../../../../utils";
import {checkboxesAttributes} from "../../../NewCampaignCheckboxesBlock/constants";

const AvailableBalanceBlock = React.memo(({
                                            selectedClientInfo,
                                            selectedApplicationInfo,
                                            countriesList,
                                            selectedType,
                                            currentClientCost,
                                            handleSetCurrentClientCost,
                                            checkboxesValue
                                          }) => {
  const [balance, setBalance] = useState(null);

  const getLaunchCost = (launchCost, attributesValue) => {
    const launchAttributes = [];

    for (let attribute in checkboxesAttributes) {
      if (attribute.includes('day') && (attributesValue & checkboxesAttributes[attribute]) !== 0) {
        launchAttributes.push(attribute);
      }
    }

    if (!launchAttributes.length) return 0;

    if (launchAttributes.length === 1) {
      return launchAttributes.length * launchCost;
    } else {
      return (launchAttributes.length - 1) * launchCost;
    }
  };

  useEffect(() => {
    //устанавливает цену для актуального клиента и приложения
    if (selectedApplicationInfo && selectedClientInfo && selectedType) {
      const appid = selectedApplicationInfo.appid || '';
      const isAppidWithDot = appid.includes('.');

      switch (Number(selectedType)) {
        case 1:
          handleSetCurrentClientCost((isAppidWithDot ? selectedClientInfo.install_cost : selectedClientInfo.a_install_cost) + getLaunchCost(selectedClientInfo.cost_relaunch, checkboxesValue));
          break;
        case 2:
          handleSetCurrentClientCost((isAppidWithDot ? selectedClientInfo.rate_cost : selectedClientInfo.a_rate_cost) + getLaunchCost(selectedClientInfo.cost_relaunch, checkboxesValue));
          break;
        case 3:
          handleSetCurrentClientCost((isAppidWithDot ? selectedClientInfo.install_cost : selectedClientInfo.a_install_cost) + getLaunchCost(selectedClientInfo.cost_relaunch, checkboxesValue));
          break
        default:
          handleSetCurrentClientCost(0);
          break;
      }
    } else {
      handleSetCurrentClientCost(0);
    }
  }, [handleSetCurrentClientCost, selectedApplicationInfo, selectedClientInfo, selectedType, checkboxesValue]);

  useEffect(() => {
    if (selectedClientInfo) {
      setBalance(selectedClientInfo.balance - selectedClientInfo.limit - (getTotalKeywordsCountFromCountries(countriesList) * currentClientCost))
    } else {
      setBalance(0)
    }
  }, [currentClientCost, selectedClientInfo, countriesList])

  return (
    <BalanceBlock>
      <BalanceText>Available client’s balance:</BalanceText>
      {!!selectedClientInfo &&
        <BalanceValue sign={Math.sign(Number(balance)) > 0}>{balance?.toFixed(2)} $</BalanceValue>}
    </BalanceBlock>
  );
});

export default AvailableBalanceBlock;