import styled from "styled-components";
import leftArrow from "../../Images/icons/left-arrow.svg"

export const StyledAside = styled.aside`
  min-width: ${props => props.open ? '270px' : '102px'};
  max-width: ${props => props.open ? '270px' : '102px'};
    transition: ease-in 500ms;
  width: 100%;
  position: sticky;
  background-color: #3E3E3E;
  height: 100vh;
  top: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
    gap: 30px;
  padding-bottom: 110px;

  grid-column: 1 / 2;
`;

export const NavList = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 20px 0 20px;
  box-sizing: border-box;

  a {
    display: block;
    width: 100%;
    text-decoration: none;
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 17px 20px;
  gap: 15px;
  background-color: ${({active}) => active ? "#ffffff" : "#3E3E3E"};
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  span {
    color: ${({active}) => active ? "#3E3E3E" : "#ffffff"};
    white-space: nowrap;
  }

  img {
    ${({active}) => active ? "" : "filter: invert(100%) sepia(38%) saturate(1022%) hue-rotate(178deg) brightness(114%) contrast(97%)"};
  }
`;

export const NavText = styled.span`
  text-decoration: none;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    text-decoration: none;
  }
`;

export const HiddenButton = styled.button`
    width: 34px;
    height: 34px;
    background-color: transparent;
    border: 1px solid #ffffff;
    background-image: url(${leftArrow});
    transition: ease-in 500ms;
    transform: ${props => props.open ? 'unset' : 'rotateZ(180deg)'};
    background-size: 18px 18px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: center;
`;