import styled from 'styled-components';

export const PageContainer = styled.section`
  position: relative;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 60px;
`;

export const PageHeading = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #3E3E3E;
  margin: 0 0 42px;`;

export const ControlBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
`;

export const ControlButtons = styled.div`
  display: flex;
  gap: 40px;
  width: max-content;
`;

export const ControlButton = styled.button`
  background-color: #3e3e3e;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  width: 150px;
`;

export const TableBlock = styled.div`
  overflow-y: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const StyledThead = styled.thead`
  background: rgba(222, 222, 222, 0.82);
  border-top: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledHeadTr = styled.tr`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
`;

export const StyledTbody = styled.tbody``;

export const StyledTr = styled.tr`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #3E3E3E;
  border-bottom: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledTh = styled.th`
  padding: 12px;
`;

export const SearchInput = styled.input`
  background-color: inherit;
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 250px;
  min-height: 30px;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid rgba(62, 62, 62, 0.5);
  border-radius: 5px;
`;