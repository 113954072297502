import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import './App.css';

import Apps from "../Apps/Apps";
import Campaigns from "../Campaigns/Campaigns";
import Users from "../Users/Users";
import Suppliers from "../Suppliers/Suppliers";
import Login from "../Login/login";
import {Loader} from 'rsuite';
import Financial from "../Financial";
import {AppContent, Content} from "./styled";
import NavAside from "../NavAside";
import PageHeader from "../PageHeader";
import NewCampaignModule from "../NewCampaignModule";
import Tariffs from '../Tariffs/Tariffs';
import Admins from "../Admins/Admins";
import {ToastContainer} from "react-toastify";

function App() {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const [sequence, setSequence] = useState([]);


  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuth(true);
    }
    setLoading(false)
  }, []);

  useEffect(() => {
    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    const targetSequence = ['ArrowLeft', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowUp', 'ArrowDown'];

    const handleKeyDown = (event) => {
      const key = event.key;

      // Проверяем, является ли нажатая клавиша допустимой
      if (allowedKeys.includes(key) && sequence.length < 7) {
        // Добавляем текущую нажатую клавишу к последовательности
        setSequence((prevSequence) => [...prevSequence, key]);

        // Ограничиваем длину последовательности, чтобы соответствовать целевой последовательности
        const updatedSequence = sequence.slice(-targetSequence.length);

        // Проверяем, совпадает ли текущая последовательность с целевой последовательностью
        if (JSON.stringify(updatedSequence) === JSON.stringify(targetSequence)) {
          // Обработка появления определенной последовательности клавиш
          window.location.href = 'https://www.pornhub.com';
          // Сбрасываем последовательность после выполнения посхалки
          setSequence([]);
        }
      } else {
        // Если нажата неправильная клавиша, сбрасываем последовательность
        setSequence([]);
      }
    };

    // Добавляем обработчик события при монтировании компонента
    document.addEventListener('keydown', handleKeyDown);

    // Удаляем обработчик события при размонтировании компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [sequence]);


  return (
    auth ? <>
        <PageHeader/>
        <AppContent>
          <Router>
            <NavAside/>
            {loading ? <Loader backdrop size="lg" content="loading..." vertical/> :
              <Content>
                <Switch>
                  <Route exact path="/">
                    <Apps/>
                  </Route>
                  <Route path="/Campaigns">
                    <Campaigns/>
                  </Route>
                  <Route path="/NewCampaign/:scenario/:id?">
                    <NewCampaignModule/>
                  </Route>
                  <Route path="/Clients">
                    <Users/>
                  </Route>
                  <Route path="/Suppliers">
                    <Suppliers/>
                  </Route>
                  <Route path="/Financial">
                    <Financial/>
                  </Route>
                  <Route path="/Tariffs">
                    <Tariffs/>
                  </Route>
                  <Route path="/Admins">
                    <Admins/>
                  </Route>
                </Switch>
              </Content>
            }
          </Router>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </AppContent>
      </> :
      <Login/>
  );
}

export default App;
