import styled from 'styled-components';

export const AddForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const AddFormWrapper = styled.div`
    display: flex;
    gap: 50px;
`;

export const AddBtn = styled.button`
    width: 100%;
    background-color: #3E3E3E;
    padding: 10px 0;
    color: #ffffff;
    border: 0;
    border-radius: 5px;
`;

export const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
`;

export const StyledH1 = styled.h1`
    margin: 0;
    font-size: 26px;
`;

export const StyledH2 = styled.h2`
    margin: 0;
    font-size: 24px;
`;

export const InputLabel = styled.label`
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTextArea = styled.textarea`
    font-size: 16px;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #9C9C9C;
    border-radius: 5px;
`;

export const StyledInput = styled.input`
    font-size: 16px;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #9C9C9C;
    border-radius: 5px;
`;

export const StyledSelect = styled.select`
    font-size: 16px;
    padding: 15px;
    max-height: 150px;
    background-color: #ffffff;
    border: 1px solid #9C9C9C;
    border-radius: 5px;
    width: 100%;
`;

export const EditPartnerWrapper = styled.div`
    width:100%;
`