import styled from "styled-components";

export const DownloadButton = styled.a`
  background-color: transparent;
  border: 0;
  color: #3e3e3e;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: max-content;
  align-items: center;
  margin: 16px auto 64px;
  padding: 0;
  text-decoration: none !important;

  &:hover {
    & div {
      color: #D7D7D7;
    }

    & span {
      color: #3e3e3e;
    }
  }

`;

export const DownloadButtonIcon = styled.div`
  width: 128px;
  height: 128px;

  color: #3e3e3e;
`;

export const DownloadButtonSpan = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
`;