import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {LS_EXTEND_CAMPAIGN_COUNTRIES, LS_EXTEND_CAMPAIGN_ID} from "../constants";
import API from "../../../Api/API";
import {getDateListFromCampaignKeywords, getDatesBetween, ParseExtendKeywords} from "../../utils";
import {Loader} from "rsuite";
import NewCampaignForm from "../Modules/NewCampaignForm";
import NewCampaignCountriesList from "../Modules/NewCampaignCountriesList";
import NewCampaignTotalBlock from "../Modules/NewCampaignTotalBlock";

const ExtendCampaign = forwardRef(({
                                     allSuppliersList,
                                     allClientsList,
                                     allApplicationsList,
                                     campaignId,
                                     selectedSupplier,
                                     selectedType,
                                     selectedClient,
                                     selectedApplication,
                                     countriesList,
                                     currentClientCost,
                                     selectedApplicationInfo,
                                     handleSetCurrentClientCost,
                                     handleSetCountriesList,
                                     selectedDateStop,
                                     selectedDateStart,
                                     handleSetApplication,
                                     selectedClientInfo,
                                     handleSetClient,
                                     handleSetDateStart,
                                     handleSetDateStop,
                                     handleSetSupplier,
                                     handleSetType,
                                     handleSendNewCampaign,
                                     checkboxesValue,
                                     handleSetCheckboxesValue,
                                     selectedDelayStart,
                                     handleSetSelectedDelayStart
                                   }, ref) => {
  const [campaignInfo, setCampaignInfo] = useState(null);
  const currentTodayDate = new Date(new Date().setHours(0, 0, 0, 0));

  const handleSelectCountriesList = (countriesList) => {
    localStorage.setItem(LS_EXTEND_CAMPAIGN_COUNTRIES, JSON.stringify(countriesList))
    handleSetCountriesList(countriesList)
  }

  const getNextDay = (date) => {
    const nextDate = new Date(new Date(date).setHours(0, 0, 0, 0));
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
  }

  const getNewDateArray = (firstDay, startDate, endDate) => {
    const dateArray = [];
    const currentDate = new Date(firstDay);
    const numberOfDays = getDatesBetween((new Date(startDate)), (new Date(endDate))).length;

    for (let i = 0; i < numberOfDays; i++) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      dateArray.push(`${year}-${month}-${day}`);

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray
  }

  const campaignTypes = {
    'install': 1,
    'rate': 2,
    'install_bp': 3
  }

  useEffect(() => {
    API.getInfoCampaign(campaignId).then((result) => {
      setCampaignInfo(result.data);
      handleSetSupplier(result.data.supplier);
      handleSetClient(result.data.client_id);
      handleSetType(campaignTypes[result.data.type]);
      handleSetApplication(result.data.app);
      handleSetCheckboxesValue(result.data.settings_message ? result.data.settings_message : 0);
      handleSetSelectedDelayStart(result.data.delayed_start);

      const oldDateList = getDateListFromCampaignKeywords(result.data.keywords);
      const currentEndDate = new Date(oldDateList[oldDateList.length - 1]);
      let newStartDate;
      let newDayArray;

      if (currentTodayDate < currentEndDate) {
        newStartDate = getNextDay(currentEndDate);
        newDayArray = getNewDateArray(newStartDate, oldDateList[0], oldDateList[oldDateList.length - 1])
      } else {
        newStartDate = new Date();
        newDayArray = getNewDateArray(newStartDate, oldDateList[0], oldDateList[oldDateList.length - 1])
      }

      handleSetDateStart(newStartDate);
      handleSetDateStop(new Date(newDayArray[newDayArray.length - 1]));

      const storageCampaignId = Number(localStorage.getItem(LS_EXTEND_CAMPAIGN_ID));

      if (storageCampaignId === Number(campaignId)) {
        const storageCountries = JSON.parse(localStorage.getItem(LS_EXTEND_CAMPAIGN_COUNTRIES));
        handleSetCountriesList(!!storageCountries?.length ? storageCountries : []);
      } else {
        localStorage.setItem(LS_EXTEND_CAMPAIGN_ID, campaignId);

        handleSelectCountriesList(ParseExtendKeywords(result.data, newStartDate, new Date(newDayArray[newDayArray.length - 1])));
      }
    })

  }, []);

  const handleResetForm = () => {
    if (campaignInfo) {
      const oldDateList = getDateListFromCampaignKeywords(campaignInfo.keywords);
      const currentEndDate = new Date(oldDateList[oldDateList.length - 1]);
      let newStartDate;
      let newDayArray;

      if (currentTodayDate < currentEndDate) {
        newStartDate = getNextDay(currentEndDate);
        newDayArray = getNewDateArray(newStartDate, oldDateList[0], oldDateList[oldDateList.length - 1])
      } else {
        newStartDate = new Date();
        newDayArray = getNewDateArray(newStartDate, oldDateList[0], oldDateList[oldDateList.length - 1])
      }

      // console.log(campaignInfo, newStartDate, new Date(newDayArray.at(-1)))
      handleSelectCountriesList(ParseExtendKeywords(campaignInfo, newStartDate, new Date(newDayArray[newDayArray.length - 1])));
    }
  }

  useImperativeHandle(ref, () => ({
    handleResetForm: handleResetForm
  }))

  return (
    (!!allApplicationsList.length && !!allClientsList.length && !!allSuppliersList.length && !!campaignInfo) ?
      <>
        <NewCampaignForm
          scenario={'Extend'}
          allApplicationsList={allApplicationsList}
          allClientsList={allClientsList}
          allSuppliersList={allSuppliersList}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedType={selectedType}
          selectedApplication={selectedApplication}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          handleSelectApplication={handleSetApplication}
          handleSelectClient={handleSetClient}
          currentClientCost={currentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          countriesList={countriesList}
          selectedDateStop={selectedDateStop}
          selectedDateStart={selectedDateStart}
          checkboxesValue={checkboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSelectDelayStartTime={handleSetSelectedDelayStart}
        />
        <NewCampaignCountriesList
          selectedType={selectedType}
          countriesList={countriesList}
          dateStart={selectedDateStart}
          dateStop={selectedDateStop}
          handleSetCountriesList={handleSelectCountriesList}
        />
        <NewCampaignTotalBlock
          handleSendNewCampaign={handleSendNewCampaign}
          countriesList={countriesList}
          selectedType={selectedType}
          currentClientCost={currentClientCost}
          handleResetForm={handleResetForm}
        />
      </> :
      <Loader backdrop size="lg" content="loading..." vertical/>
  );
});

export default ExtendCampaign;