import styled from "styled-components";

export const AppContent = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  height: 100%;
  position: relative;
`;

export const Content = styled.main`
  box-sizing: border-box;
  position: relative;
  margin: 10px 10px 0 10px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);
  grid-column: 2/3;
  overflow: hidden;
`;