import React, {useEffect, useRef, useState} from 'react';
import {Header, HeaderLogoBlock, HeaderUserBlock, HeaderUserButton, LogOutButton, StyledPopup} from "./styled";

const PageHeader = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const popupRef = useRef(null);

  const togglePopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogOut = () => {
    localStorage.clear();
    window.location.reload()
  }

  return (
    <Header>
      <HeaderLogoBlock/>
      <HeaderUserBlock>
        <HeaderUserButton onClick={togglePopup}/>
        {openPopup &&
          <StyledPopup ref={popupRef}>
            <LogOutButton onClick={handleLogOut}>Log out</LogOutButton>
          </StyledPopup>
        }
      </HeaderUserBlock>
    </Header>
  );
};

export default PageHeader;