import React, {useState} from 'react';
import API from "../../../Api/API";
import {InputLabel, ModalInput, ModalTextArea, ModalWrapper, SubmitButton} from "./styled";

const AddBalanceModal = ({id, type, close, reload}) => {
  const [value, setValue] = useState(0)
  const [comment, setComment] = useState(null)

  const [disableBtn, setDisableBtn] = useState(false)

  const handleSubmit = () => {
    if (!value) {
      alert('fill value field')
    } else if (type === 'client') {
      setDisableBtn(true)
      API.addClientBalance({balance: parseFloat(value), client_id: id, comment: comment ? comment : null}).then(r => {
        setDisableBtn(false)
        reload();
        close();
      })
    } else if (type === 'supplier') {
      setDisableBtn(true)
      API.addSupplierBalance({balance: parseFloat(value), supplier_id: id, comment: comment ? comment : null}).then(r => {
        setDisableBtn(false)
        reload();
        close();
      })
    }
  }

  const handleChangeValue = (e) => {
    setValue(e.target.value)
  }

  const handleChangeComment = (e) => {
    setComment(e.target.value)
  }

  return (
    <ModalWrapper>
      <InputLabel>
        Value
        <ModalInput type={'number'} onChange={handleChangeValue}/>
      </InputLabel>
      <InputLabel>
        Comment
        <ModalTextArea rows={4} onChange={handleChangeComment}/>
      </InputLabel>
      <SubmitButton onClick={handleSubmit} disabled={disableBtn}>Submit</SubmitButton>
    </ModalWrapper>
  );
};

export default AddBalanceModal;