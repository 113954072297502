import React, {useEffect, useRef, useState} from 'react';
import {
  ImportHeading,
  ImportModal, ImportModalButtonsBlock, ImportModalInput, ImportModalInputLabel,
  ImportRulesItem,
  ImportRulesList,
  ImportRulesListSpan
} from "./styled";
import {getKeysFromCountry, getStoreLinkId, throwToastError} from "../../../utils";
import {read, utils} from "xlsx";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import NewAppModal from "../../../Apps/new-app-form/new-app-modal";
import moment from "moment/moment";
import {dateFormats} from "../../constants";
import CountriesList from "../../../../Api/Countries";
import DownloadTemplateButton from "./Components/DownloadTemplateButton";

const ImportFileModule = React.forwardRef((
  {
    allApplicationsList,
    getNewAllAppsList,
    handleSelectDates,
    handleSelectApplication,
    handleSelectCountriesList,
    handleToggleImportModal,
    handleSetIsImported,
    handleResetForm
  }, ref) => {
  const [openNewAppModal, setOpenNewAppModal] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadData, setFileUploadData] = useState(null);
  const [importedAppLink, setImportedAppLink] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (fileUpload && fileUploadData) {
      handleFileUpload(null, fileUploadData, fileUpload);
    }
  }, [allApplicationsList]);

  const getImportedDateList = (worksheet) => {
    return Object.keys(worksheet).filter(cell => cell.match(/[a-zA-Z]+1$/) && cell !== 'A1')
      .map(dateCell => {
        const startDate = new Date('01/01/1900');

        if (worksheet[dateCell].hasOwnProperty('v') && typeof worksheet[dateCell].v === 'number') {
          return moment(new Date(startDate.setDate(worksheet[dateCell].v - 1))).format('YYYY-MM-DD')
        } else if (worksheet[dateCell].hasOwnProperty('w') && moment(worksheet[dateCell].w, dateFormats).isValid()) {
          return moment(worksheet[dateCell].w, dateFormats).format('YYYY-MM-DD')
        } else {
          throwToastError('Change the date format or contact the administrator')
          return null
        }
      })
  }

  const getCountryByAssociation = (value) => {
    try {
      let allegedCountry;

      if (value?.includes(',')) {
        allegedCountry = value.split(', ')[1];
      } else {
        allegedCountry = value
      }

      const countryItem = CountriesList.find(
        countryItem =>
          countryItem.ISO.toLowerCase() === allegedCountry.toLowerCase() ||
          countryItem.Country.toLowerCase() === allegedCountry.toLowerCase() ||
          countryItem.Alias.some(alias => alias.toLowerCase() === allegedCountry.toLowerCase())
      );

      return countryItem ? countryItem.ISO : null;
    } catch {
      return null;
    }
  };

  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2); // Извлекаем расширение файла из его имени
  }


  const handleFileUpload = (event, storageData, storageFile) => {
    let file;
    if (storageFile) {
      file = storageFile
    } else {
      file = event.target.files[0];
      handleResetForm()

      if (getFileExtension(file.name) !== 'xlsx') {
        throwToastError('Wrong filetype')
        return
      }
    }

    const reader = new FileReader();
    const currentDate = new Date(new Date().toISOString().slice(0, 10));

    reader.onload = (e) => {
      try {
        let data
        if (storageData) {
          data = storageData;
        } else {
          data = new Uint8Array(e.target.result);
        }

        const workbook = read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = utils.sheet_to_json(worksheet, {header: 1});

        const importedApp = allApplicationsList.find(app => app.appid === getStoreLinkId(sheetData[1][0]));

        //если импортируемое приложение не заведено в системе
        if (!importedApp) {
          setFileUpload(file);
          setFileUploadData(data);
          setImportedAppLink(sheetData[1][0]);
          setOpenNewAppModal(true);
          throwToastError('This application is not regulated');
          return
        }

        //список дат из файла
        const dateList = getImportedDateList(worksheet);

        if (!dateList) {
          return
        }

        if (new Date(dateList[dateList.length - 1]) < currentDate) {
          throwToastError(`Campaign expired`);
          return;
        }

        //список дат, которые еще не прошли
        const actuallyDateList = dateList.filter((date) => {
          return new Date(date) >= new Date(currentDate);
        })

        //кол-во удаленных дат
        const quantityDeletedDate = dateList.length - actuallyDateList.length;

        let result = {
          date_start: actuallyDateList[0],
          date_stop: actuallyDateList[actuallyDateList.length - 1],
          keywords: []
        };

        let country = '';

        let tempGeoKeywords = {geo: null, keywords: {}};

        let countryKeys = [];

        for (let i = 1; sheetData.length; i++) {
          const row = sheetData[i];

          if (!row) {
            break
          }

          if (!row.length) {
            continue
          }

          if (row.length && !row[0]) {
            throwToastError(`Check the filling of the key on the line ${i + 1}`)
            return
          }

          //удаляет ключи уже прошедших дат
          if (quantityDeletedDate > 0 && row.length > 1) {
            if (quantityDeletedDate >= row.length - 1) {
              sheetData.splice(i, 1);
              continue
            } else {
              row.splice(1, quantityDeletedDate);
            }
          }

          //замена 'empty' на ноль
          for (let j = 0; j < row.length; j++) {
            if (row.length > 1 && !row[j]) {
              row[j] = Number(0)
            }
          }

          //добавляет нули до конечной даты для каждого ключа
          if ((row.length - 1 < actuallyDateList.length) && row.length > 1) {
            while (row.length - 1 < actuallyDateList.length) {
              row.push(0);
            }
          }

          //если ключей больше чем проставленных дат, то возвращается ошибка
          if (row.length > 1 && row.length - 1 > actuallyDateList.length) {
            throwToastError('The list of keys is longer than the list of dates. Check if the table is filled in correctly')
            return;
          }

          //переключение на заполнение новой страны
          if (!row[0].includes('.') && row.length === 1) {
            if (tempGeoKeywords.geo !== null) {
              result.keywords.push(tempGeoKeywords);
            }
            const countryCellValue = row[0];
            const currentCountryISO = getCountryByAssociation(countryCellValue);

            countryKeys = []

            if (currentCountryISO) {
              country = currentCountryISO
              tempGeoKeywords = {geo: country, keywords: {}};
            } else {
              country = countryCellValue
              tempGeoKeywords = {geo: country, keywords: {}};
            }

          } else {
            let keyword = '';
            for (const cell in row) {
              if (row.length > 1) {
                if (Number(cell) !== 0) {
                  if (!tempGeoKeywords.keywords[actuallyDateList[cell - 1]]) {
                    tempGeoKeywords.keywords[actuallyDateList[cell - 1]] = {}
                  }
                  if (row[cell]) {
                    tempGeoKeywords.keywords[actuallyDateList[cell - 1]][keyword] = row[cell];
                  } else {
                    tempGeoKeywords.keywords[actuallyDateList[cell - 1]][keyword] = 0;
                  }
                } else {
                  if (countryKeys.includes(row[cell])) {
                    throwToastError(`Attention, duplicate key on line ${i + 1}`);
                    return;
                  } else {
                    keyword = row[cell]
                    countryKeys.push(row[cell])
                  }
                }
              }
            }
          }
        }
        result.keywords.push(tempGeoKeywords);

        setFileUploadData(null);
        setFileUpload(null);

        handleSelectDates([new Date(result.date_start), new Date(result.date_stop)]);
        handleSelectApplication(importedApp.id);
        handleSelectCountriesList(result.keywords);
        handleSetIsImported();
        handleToggleImportModal();
      } catch (error) {
        console.error(error)
        throwToastError(error.message)
      }
    }
    reader.readAsArrayBuffer(file);
  }

  const handleRegisterApp = () => {
    getNewAllAppsList().then(() => {
      setImportedAppLink(null)
      setOpenNewAppModal(false);
    })
  }

  return (
    <>
      <ImportModal ref={ref} tabIndex={'-1'}>
        <ImportHeading>Import file</ImportHeading>
        <ImportRulesList>
          <ImportRulesItem>Download the ready-made template.</ImportRulesItem>
          <ImportRulesItem>Enter your keywords.</ImportRulesItem>
          <ImportRulesItem>Set the required dates according to the format provided in the template.</ImportRulesItem>
          <ImportRulesItem>Enter the number of installations for each keyword.</ImportRulesItem>
          <ImportRulesItem>Save and upload the finished order file.</ImportRulesItem>
        </ImportRulesList>
        <ImportRulesListSpan>Prohibited data:</ImportRulesListSpan>
        <ImportRulesList>
          <ImportRulesItem>Specifying the number of installations if the keyword string is empty.</ImportRulesItem>
          <ImportRulesItem>Specifying the number of installations in the line with the country name.</ImportRulesItem>
          <ImportRulesItem>Empty spaces in the row with dates, repetition of dates.</ImportRulesItem>
        </ImportRulesList>
        <DownloadTemplateButton/>
        <ImportModalButtonsBlock>
          <ImportModalInputLabel>
            Import file
            <ImportModalInput type={'file'} onChange={handleFileUpload} ref={fileInputRef}/>
          </ImportModalInputLabel>
        </ImportModalButtonsBlock>
      </ImportModal>
      <Dialog
        onClose={() => setOpenNewAppModal(false)}
        aria-labelledby="customized-dialog-title"
        scroll='paper'
        PaperProps={{style: {overflow: "visible"}}}
        open={openNewAppModal}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setOpenNewAppModal(false)}>
          Application registration
        </DialogTitle>
        <DialogContent style={{overflow: "visible"}} dividers>
          <NewAppModal handleAddNewApp={handleRegisterApp} link={importedAppLink} readOnly={true}/>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default ImportFileModule;