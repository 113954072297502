import {useState} from 'react';
import {
  CancelButton,
  CardAppName,
  CardBody,
  CardButton,
  CardImg,
  CardPlatform,
  CardWrapper,
  DeleteButton,
  DeleteModal,
  ModalButtonBlock,
  ModalContent,
  ModalHeading,
  ModalText,
  RemoveButton,
  StatisticButton,
  StatusButton,
  StyledCard
} from './styled';
import GooglePlayImg from '../../../Images/googlePlay.png';
import AppStoreImg from '../../../Images/AppStore.png';
import {Link} from "react-router-dom";
import {Dialog, DialogContent} from '@mui/material';
import StatisticModal from "../StatisticModal";

const AppCard = ({card, handle}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openStatisticModal, setOpenStatisticModal] = useState(false)

  const handleClose = () => {
    setOpenDelete(false)
    setOpenStatisticModal(false)
  }

  const handleOpenStatistic = () => {
    setOpenStatisticModal(true)
  }

  return (
    <>
      <StyledCard>
        <CardWrapper>
          <CardImg src={card.icon} alt="card-icon"/>
          <CardBody>
            <CardAppName>{card.name}</CardAppName>
            <a
              href={card?.appid?.includes('.') ? `https://play.google.com/store/apps/details?id=${card.appid}` : `https://apps.apple.com/us/app/id${card.appid}`}
              target={"_blank"} rel="noreferrer"
            >
              <CardPlatform src={card.appid.includes('.') ? GooglePlayImg : AppStoreImg}></CardPlatform>
            </a>
            <Link to={`/NewCampaign/promote/${card.id}`}>
              <CardButton disabled={card.name.includes('banned')}>Promote</CardButton>
            </Link>
          </CardBody>
          <StatusButton status={card.status === 2}/>
          <StatisticButton onClick={handleOpenStatistic}/>
          <DeleteButton onClick={() => {
            setOpenDelete(true)
          }}></DeleteButton>
        </CardWrapper>
      </StyledCard>
      <Dialog
        open={openStatisticModal}
        onClose={handleClose}
        maxWidth='1200'
      >
        <DialogContent>
          <StatisticModal app={card}/>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDelete}
        maxWidth='700'
      >
        <DialogContent dividers>
          <DeleteModal>
            <ModalContent>
              <ModalHeading>Attention!</ModalHeading>
              <ModalText>Do you really want to remove <b>{card.name}</b>?</ModalText>
              <ModalButtonBlock>
                <RemoveButton onClick={() => {
                  handle(card.id);
                  setOpenDelete(false)
                }}>Remove</RemoveButton>
                <CancelButton
                  onClick={() => {
                    setOpenDelete(false)
                  }
                  }>Cancel</CancelButton>
              </ModalButtonBlock>
            </ModalContent>
          </DeleteModal>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AppCard;
