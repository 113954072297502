import {useState} from "react";
import * as React from "react";
import API from "../../../Api/API";
import {AddBtn, AddForm, AddFormWrapper, StyledCol, StyledInput, StyledTextArea, InputLabel, StyledH1, StyledH2} from './styled';


const AddSupplier = ({reload, close}) => {
    const [data, setData] = useState({
        name: null,
        install_cost: null,
        rate_cost: null,
        a_install_cost: null,
        a_rate_cost: null,
    })
    const add = () => {
        if (data.name !== null && data.install_cost !== null && data.rate_cost !== null && data.a_install_cost !== null && data.a_rate_cost !== null) {
            API.NewSupplier(data).then(r => {
                reload(true);
                close();
            })
        } else {
            alert('Fill in required fields')
        }
    }

    return (<>
        <AddForm>
            <StyledH1>Add new Supplier</StyledH1>
            <AddFormWrapper>
                <StyledCol>
                    <StyledH2>Supplier info</StyledH2>
                    <StyledInput onChange={(e) => {
                        let eData = data;
                        eData['name'] = e.target.value
                        setData(eData);
                    }} placeholder={'Name'} style={{marginTop: "31px"}} />
                </StyledCol>
                <StyledCol>
                    <StyledH2>Cost for new Supplier</StyledH2>
                    <b style={{color: 'rgba(0, 149, 42, 1)'}}>Google play</b>
                    <InputLabel>
                        Install
                        <StyledInput type='number' onChange={(e) => {
                            let eData = data;
                            eData['install_cost'] = parseFloat(e.target.value)
                            setData(eData);
                        }} />
                    </InputLabel>
                    <InputLabel>
                        Rate
                        <StyledInput type='number' onChange={(e) => {
                            let eData = data;
                            eData['rate_cost'] = parseFloat(e.target.value)
                            setData(eData);
                        }} />
                    </InputLabel>
                    <b style={{color: 'rgba(0, 87, 168, 1)'}}>App Store</b>
                    <InputLabel>
                        Install
                        <StyledInput type='number' onChange={(e) => {
                            let eData = data;
                            eData['a_install_cost'] = parseFloat(e.target.value)
                            setData(eData);
                        }} />
                    </InputLabel>
                    <InputLabel>
                        Rate
                        <StyledInput type='number' onChange={(e) => {
                            let eData = data;
                            eData['a_rate_cost'] = parseFloat(e.target.value)
                            setData(eData);
                        }} />
                    </InputLabel>
                </StyledCol>
            </AddFormWrapper>
            <AddBtn onClick={add}>Add</AddBtn>
        </AddForm>
    </>)
}

export default AddSupplier;