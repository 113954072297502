import React, {useEffect, useState} from 'react';
import {
  CampaignPriceBlock,
  CampaignPriceSpan,
  CampaignPriceValue,
  ClearBtn,
  StartButtonsBlock,
  StartGreenBtn,
  StartPromotionBlock
} from "./styled";
import {getTotalKeywordsCountFromCountries} from "../../../utils";

const NewCampaignTotalBlock = React.memo(({
                                            handleSendNewCampaign,
                                            handleResetForm,
                                            currentClientCost,
                                            countriesList,
                                            selectedType
                                          }) => {
  const [keywordCount, setKeywordCount] = useState(0);
  const [campaignCost, setCampaignCost] = useState(0);

  useEffect(() => {
    setKeywordCount(getTotalKeywordsCountFromCountries(countriesList))
  }, [countriesList]);

  useEffect(() => {
    setCampaignCost(keywordCount * currentClientCost)
  }, [currentClientCost, keywordCount]);

  const currentTypes = {
    '1': 'installs',
    '2': 'rates',
    '3': 'installs'
  }

  return (
    <StartPromotionBlock>
      {!!campaignCost &&
        <CampaignPriceBlock visiable={true}>
          <CampaignPriceSpan>Campaign price:</CampaignPriceSpan>
          <CampaignPriceValue>$ {campaignCost?.toFixed(2)}</CampaignPriceValue>
        </CampaignPriceBlock>
      }
      {!!selectedType && !!countriesList.length &&
        <CampaignPriceBlock visiable={true}>
          <CampaignPriceSpan>Total {currentTypes[selectedType]}:</CampaignPriceSpan>
          <CampaignPriceValue>{getTotalKeywordsCountFromCountries(countriesList)}</CampaignPriceValue>
        </CampaignPriceBlock>
      }
      <StartButtonsBlock>
        <StartGreenBtn onClick={handleSendNewCampaign}>Start promotion</StartGreenBtn>
        <ClearBtn onClick={handleResetForm}>Reset</ClearBtn>
      </StartButtonsBlock>
    </StartPromotionBlock>
  );
});

export default NewCampaignTotalBlock;