import styled from 'styled-components';
import searchIcon from '../../Images/icons/search-icon.svg';
import userIcon from '../../Images/icons/user-icon.svg';

export const MyApps = styled.div`
    padding: 35px 25px;
`;

export const MyAppsHeading = styled.h1`
    font-family: 'Urbanist', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    color: #3E3E3E;
    margin: 0;
    margin-bottom: 42px;
`;

export const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-bottom: 39px;
`;

export const SearchInputLabel = styled.label`
    display: flex;
    align-items: center;

    &::before {
    content: '';
    background-image: url(${searchIcon});
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    }
`;

export const SearchInput = styled.input`
background-color: inherit;
color: #3E3E3E;
font-family: 'Urbanist', sans-serif;
font-weight: 600;
font-size: 14px;
width: 465px;
min-height: 30px;
padding: 8px 15px;
box-sizing: border-box;
border: 1px solid rgba(62, 62, 62, 0.5);
border-radius: 5px;
margin-left: 30px;
`;

export const SelectInputLabel = styled.label`
    display: flex;
    align-items: center;

    &::before {
    content: '';
    background-image: url(${userIcon});
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
}
`;

export const SelectInput = styled.select`
background-color: inherit;
color: #3E3E3E;
font-family: 'Urbanist', sans-serif;
font-weight: 600;
font-size: 14px;
width: 465px;
min-height: 30px;
padding: 8px 15px;
box-sizing: border-box;
border: 1px solid rgba(62, 62, 62, 0.5);
border-radius: 5px;
margin-left: 30px;
`;

export const StyledAppList = styled.ul`
display: flex;
gap: 26px;
flex-wrap: wrap;
padding: 0;
list-style-type: none;
margin: 0;
`;

export const LoadingContent = styled.div`
    width: 100%;
    height: 100%;
`;