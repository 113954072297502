import styled from 'styled-components';

export const CountrySearchBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
`;

export const SearchInput = styled.input`
  padding: 15px 10px 15px 15px;
  border: 1px solid ${props => props.correct ? '#3E3E3E' : '#AF3A3A'};
  border-radius: 5px;
  background-color: ${props => props.correct ? 'transparent' : '#af3a3a33'};
  width: ${props => props.size === 'lg' ? '100%' : '245px'};
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  cursor: pointer;

  &[data-on-error='true'] {
    border: 1px solid #D27373;
    background-color: #ffdede;
  }
`;

export const CountrySearchErrorText = styled.span`
  color: #D27373;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 14px;

  position: absolute;
  display: block;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const CountryList = styled.div`
  display: ${(props) => props.open ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 57px;
  width: 100%;
  list-style-type: none;
  padding: 0;
  max-height: 400px;
  overflow: auto;
`;

export const CountryItem = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: ${props => props.active ? '#d7d7d7' : '#ffffff'};

  &:hover {
    background: #d7d7d7;
  }
`;

export const CountrySpan = styled.span``;

export const AppIcon = styled.img`
  max-width: 20px;
  object-fit: contain;
`;

export const ClearBtn = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  box-sizing: content-box;
  line-height: 100%;
`;