import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";

import appsImg from '../../Images/apps.svg'
import clientsImg from '../../Images/man.svg'
import suppliersImg from '../../Images/supply.svg'
import tariffsImg from '../../Images/tariffs.svg'
import campaignsImg from '../../Images/tasklist.svg'
import adminsImg from '../../Images/admins-icon.svg'

import {HiddenButton, NavItem, NavList, NavText, StyledAside} from "./styled";

const NavAside = () => {
  let location = useLocation();
  const group = localStorage.getItem('group') ?? 'admin';

  const [open, setOpen] = useState(Number(localStorage.getItem('navOpen')));

  const handleToggleMenu = () => {
    open ? setOpen(0) : setOpen(1);
    open ? localStorage.setItem('navOpen', 0) : localStorage.setItem('navOpen', 1);
  }

  const Routes = [
    {
      title: "My apps",
      path: "/",
      icon: appsImg,
      groups: ['admin', 'partner', 'superadmin']
    },
    {
      title: "Campaigns",
      path: "/Campaigns",
      icon: campaignsImg,
      groups: ['admin', 'partner', 'superadmin']
    },
    {
      title: "Clients",
      path: "/Clients",
      icon: clientsImg,
      groups: ['admin', 'partner', 'superadmin']
    },
    {
      title: "Admins",
      path: "/Admins",
      icon: adminsImg,
      groups: ['superadmin']
    },
    {
      title: "Suppliers",
      path: "/Suppliers",
      icon: suppliersImg,
      groups: ['admin', 'partner', 'superadmin']

    },
    {
      title: "Tariffs",
      path: "/Tariffs",
      icon: tariffsImg,
      groups: ['superadmin']
    }
  ];

  return (
    <StyledAside open={open}>
      <NavList>
        {Routes.map((route) => {
            if (route.groups.includes(group)) {
              return (<Link to={route.path} key={route.path}>
                <NavItem active={location.pathname === route.path}>
                  <img src={route.icon} alt="item-pic"/>
                  {open ? <NavText>{route.title}</NavText> : ''}
                </NavItem>
              </Link>)
            }
          }
        )}
      </NavList>
      <HiddenButton onClick={handleToggleMenu} open={open}/>
    </StyledAside>
  );
};

export default NavAside;