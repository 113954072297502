import moment from 'moment';
import {toast} from "react-toastify";
import {ERRORS_BITES} from "../NewCampaignModule/constants";
import CountriesList from "../../Api/Countries";

const DATE_FORMAT = 'YYYY-MM-DD';

export const getFormattedDate = (date) => {
  return moment(date).format(DATE_FORMAT);
};

export const getOneDayAgo = (dateString) => {
  let dateParts = dateString.split('-');
  let year = parseInt(dateParts[0]);
  let month = parseInt(dateParts[1]) - 1;
  let day = parseInt(dateParts[2]);

  let date = new Date(year, month, day);
  date.setDate(date.getDate() - 1);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};
//
// export const getOneDayAhead = (dateString) => {
//   let dateParts = dateString.split('-');
//   let year = parseInt(dateParts[0]);
//   let month = parseInt(dateParts[1]) - 1;
//   let day = parseInt(dateParts[2]);
//
//   let date = new Date(year, month, day);
//   date.setDate(date.getDate() + 1);
//
//   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
// };
//
// export const getOneWeekAgo = (date) => {
//   const previousDate = new Date(date);
//   previousDate.setDate(previousDate.getDate() - 7);
//   return moment(previousDate).format('YYYY-MM-DD');
// };
//
export const getDatesBetween = (startDate, stopDate) => {
  let dates = [];
  const currentStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  const currentStopDate = new Date(new Date(stopDate).setHours(0, 0, 0, 0));

  while (currentStartDate <= currentStopDate) {
    dates = [...dates, moment(currentStartDate).format(DATE_FORMAT)];
    currentStartDate.setDate(currentStartDate.getDate() + 1);
  }

  return dates;
};

export const getStoreLinkId = (link) => {
  try {
    if (link.includes('apple')) {
      const start = link.lastIndexOf('id') + 2;
      const end = link.indexOf('?', start) !== -1 ? link.indexOf('?', start) : link.length;
      return link.substring(start, end).trim();
    }
    if (link.includes('google')) {
      const start = link.indexOf('id=') + 3;
      const end = link.indexOf('&', start) !== -1 ? link.indexOf('&', start) : link.length;
      return link.substring(start, end).trim();
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};


export const getKeywordsFromTextarea = (textareaValue) => {
  const lines = textareaValue.split('\n');
  const nonEmptyLines = [];

  lines.forEach(line => {
    const trimmedLine = line.trim().toLowerCase();

    if (trimmedLine !== '') {
      nonEmptyLines.push(trimmedLine);
    }
  });
  return nonEmptyLines;
}

export const getDatesListFromCountry = (country) => Object.keys(country?.keywords)
//возвращает массив дат из объекта страны

export const getKeysFromCountry = (country) => Object.keys(country.keywords[Object.keys(country.keywords)[0]])
//возвращает массив ключей из первого дня объекта keywords у страны

export const getTotalKeywordsCountFromCountries = (countriesList) => {
  //получение кол-ва ключей в странах
  let keywordCountTemp = 0;
  if (countriesList.length > 0) {
    for (const country of countriesList) {
      const keywords = Object.values(country.keywords);

      for (const keywordObj of keywords) {
        const values = Object.values(keywordObj);
        keywordCountTemp += values.reduce((acc, value) => acc + parseInt(value), 0);
      }
    }
    return keywordCountTemp
  } else {
    return 0
  }
}

export const getDataFromLS = (key) => {
  const item = localStorage.getItem(key);

  if (!item) {
    return null
  }

  return JSON.parse(item)
}

export const setDataToLS = (key, value) => {
  if (!key) {
    return
  }

  const item = JSON.stringify(value);
  localStorage.setItem(key, item);
}

export const throwToastError = (errorText) => {
  toast.error(errorText, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const throwToastSuccess = (successText) => {
  toast.success(successText, {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
}

function CountriesOfKeywords(keywords) {
  let Countries = [];
  for (const keyword of keywords) {
    Countries.push(keyword.country)
  }
  return [...new Set(Countries)];
}

export function ParseDuplicateKeywords(PrepareCountries, dateStart, dateStop) {
  let NewCountries = [];
  for (const country of CountriesOfKeywords(PrepareCountries.keywords)) {
    let NewCountry = {
      geo: country,
      keys: [],
      keywords: {}
    };
    for (const newDate of getDatesBetween(dateStart, dateStop)) {
      NewCountry.keywords[newDate] = {};
      const keywords = PrepareCountries.keywords.filter(key => {
        return key.country === country && key.date === PrepareCountries.date_stop
      })
      for (const key of keywords) {
        if (newDate === moment(dateStart).format('YYYY-MM-DD')) {
          NewCountry.keys.push(key.keyword)
          NewCountry.keywords[newDate][key.keyword] = key.value;
        } else {
          NewCountry.keywords[newDate][key.keyword] = 0;
        }
      }
    }
    NewCountries.push(NewCountry);
  }
  return NewCountries
}

function getUniqueKey(data) {
  const values = [];
  for (let i = 0; i < data.length; i++) {
    const value = data[i].keyword;
    if (!values.includes(value)) {
      values.push(value);
    }
  }
  return values;
}

export const getDateListFromCampaignKeywords = (keywords) => {
  //ТОЛЬКО ДЛЯ ПАРСИНГА С СЕРВЕРА
  const newDateArray = [];
  keywords.forEach((keyword) => {
    if (!newDateArray.find((date) => date === keyword.date)) {
      newDateArray.push(keyword.date)
    }
  })
  return newDateArray
}

export function ParseExtendKeywords(PrepareCountries, dateStart, dateStop) {
  let NewCountries = [];
  const NewDateArray = getDatesBetween(dateStart, dateStop);
  const OldDateArray = getDateListFromCampaignKeywords(PrepareCountries.keywords);

  for (const country of CountriesOfKeywords(PrepareCountries.keywords)) {
    let NewCountry = {
      geo: country,
      keys: [],
      keywords: {}
    };
    for (let i = 0; i < NewDateArray.length; i++) {
      if (Object.keys(NewCountry.keywords).length <= (PrepareCountries.keywords.filter(keyword => keyword.country === country)).length) {
        NewCountry.keywords[NewDateArray[i]] = {};
        const keywords = PrepareCountries.keywords.filter(key => {
          return key.country === country
        })
        for (const key of getUniqueKey(keywords)) {
          if (NewCountry.keys.length < getUniqueKey(keywords).length) {
            NewCountry.keys.push(key)
          }
          NewCountry.keywords[NewDateArray[i]][key] = (keywords.filter(keyword => keyword.date === OldDateArray[i]).find(item => item.keyword === key).value);
        }
      }
    }
    NewCountries.push(NewCountry);
  }
  return NewCountries
}

export const validationAvailableBalance = (selectedClientInfo, countriesList, currentClientCost) => {
  if (selectedClientInfo && countriesList && currentClientCost) {
    return selectedClientInfo.balance - selectedClientInfo.limit - (getTotalKeywordsCountFromCountries(countriesList) * currentClientCost) >= 0
  }
}

export const validationFinalCampaign = (campaignObj) => {
  let errorsBites = 0;

  if (!campaignObj.app_id) {
    errorsBites += ERRORS_BITES.lack_app
  }

  if (!campaignObj.supplier_id) {
    errorsBites += ERRORS_BITES.lack_supplier
  }

  if (!campaignObj.type) {
    errorsBites += ERRORS_BITES.lack_type
  }

  if (!campaignObj.client) {
    errorsBites += ERRORS_BITES.lack_client
  }

  if (!campaignObj.date_start || campaignObj.date_start === 'Invalid date') {
    errorsBites += ERRORS_BITES.lack_start_date
  }

  if (!campaignObj.date_stop || campaignObj.date_stop === 'Invalid date') {
    errorsBites += ERRORS_BITES.lack_stop_date
  }

  if (!campaignObj.keywords.length) {
    errorsBites += ERRORS_BITES.lack_countries
  }

  if (!campaignObj.keywords.every(country => country.geo !== '' && !!CountriesList.find((c) => country.geo === c.ISO))) {
    errorsBites += ERRORS_BITES.unselect_country
  }

  //TODO ПРОВЕРИТЬ
  if (!campaignObj.keywords.every(country => Object.keys(country.keywords).some(date =>
    Object.keys(country.keywords[date]).some(key => {
      return country.keywords[date][key] !== 0
    })
  ))) {
    errorsBites += ERRORS_BITES.no_values_keys
  }

  if (campaignObj.delayed_start && campaignObj.keywords.length > 1) {
    errorsBites += ERRORS_BITES.too_mach_countries
  }

  const errorsList = [];

  for (const error in ERRORS_BITES) {
    if ((errorsBites & ERRORS_BITES[error]) !== 0) {
      errorsList.push(error)
    }
  }

  return errorsList;
}
