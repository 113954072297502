import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import API from "../../../Api/API";
import NewCampaignForm from "../Modules/NewCampaignForm";
import {getDataFromLS, ParseDuplicateKeywords, setDataToLS} from "../../utils";
import NewCampaignCountriesList from "../Modules/NewCampaignCountriesList";
import {
  LS_DUPLICATE_CAMPAIGN_CHECKBOXES_VALUE,
  LS_DUPLICATE_CAMPAIGN_COUNTRIES,
  LS_DUPLICATE_CAMPAIGN_DATE_START,
  LS_DUPLICATE_CAMPAIGN_DATE_STOP, LS_DUPLICATE_CAMPAIGN_DELAY_TIME,
  LS_DUPLICATE_CAMPAIGN_ID
} from "../constants";
import {Loader} from "rsuite";
import NewCampaignTotalBlock from "../Modules/NewCampaignTotalBlock";

const RecreateCampaign = forwardRef(({
                                       allSuppliersList,
                                       allClientsList,
                                       allApplicationsList,
                                       campaignId,
                                       selectedSupplier,
                                       selectedType,
                                       selectedClient,
                                       selectedApplication,
                                       countriesList,
                                       currentClientCost,
                                       selectedApplicationInfo,
                                       handleSetCurrentClientCost,
                                       handleSetCountriesList,
                                       selectedDateStop,
                                       selectedDateStart,
                                       handleSetApplication,
                                       selectedClientInfo,
                                       handleSetClient,
                                       handleSetDateStart,
                                       handleSetDateStop,
                                       handleSetSupplier,
                                       handleSetType,
                                       handleSendNewCampaign,
                                       checkboxesValue,
                                       handleSetCheckboxesValue,
                                       selectedDelayStart,
                                       handleSetSelectedDelayStart
                                     }, ref) => {
  const [campaignInfo, setCampaignInfo] = useState(null);

  const handleSelectCountriesList = (countriesList) => {
    setDataToLS(LS_DUPLICATE_CAMPAIGN_COUNTRIES, countriesList)
    handleSetCountriesList(countriesList)
  }

  //функция отрабатывающая при рендере:
  //незаполняемые поля заполняются из объекта кампании с сервера
  //если эта кампания есть в локальном хранилище, то автоматически устанавливаются даты из локали
  //если нет - перезаписывается айди кампании и стираются все старые данные о кампании
  const campaignTypes = {
    'install': 1,
    'rate': 2,
    'install_bp': 3
  }

  useEffect(() => {
    API.getInfoCampaign(campaignId).then((result) => {
      setCampaignInfo(result.data);
      handleSetSupplier(result.data.supplier);
      handleSetClient(result.data.client_id);
      handleSetType(campaignTypes[result.data.type]);
      handleSetApplication(result.data.app);

      if (Number(getDataFromLS(LS_DUPLICATE_CAMPAIGN_ID)) === Number(campaignId)) {
        const currentDateStart = getDataFromLS(LS_DUPLICATE_CAMPAIGN_DATE_START);
        const currentDateStop = getDataFromLS(LS_DUPLICATE_CAMPAIGN_DATE_STOP);

        handleSetDateStart(currentDateStart ? new Date(currentDateStart) : new Date());
        handleSetDateStop(currentDateStop ? new Date(currentDateStop) : null);
        handleSetCheckboxesValue(Number(getDataFromLS(LS_DUPLICATE_CAMPAIGN_CHECKBOXES_VALUE)));
        handleSetSelectedDelayStart(getDataFromLS(LS_DUPLICATE_CAMPAIGN_DELAY_TIME));
      } else {
        setDataToLS(LS_DUPLICATE_CAMPAIGN_ID, campaignId);
        handleSelectCheckboxesValue(result.data.settings_message ? result.data.settings_message : 0);
        handleSelectDelayStartTime(result.data.delayed_start ? result.data.delayed_start : null);
        localStorage.removeItem(LS_DUPLICATE_CAMPAIGN_COUNTRIES);
        localStorage.removeItem(LS_DUPLICATE_CAMPAIGN_DATE_START);
        localStorage.removeItem(LS_DUPLICATE_CAMPAIGN_DATE_STOP);
      }
    })
  }, []);

  //обработчик выбора даты
  //если дата из локальной памяти соответствует выбранной, то список стран берется из локали
  //если нет - идет парсинг данных с сервера и подставляется в выбранный интервал дат
  useEffect(() => {
    const storageDateStart = new Date(getDataFromLS(LS_DUPLICATE_CAMPAIGN_DATE_START));
    const storageDateStop = new Date(getDataFromLS(LS_DUPLICATE_CAMPAIGN_DATE_STOP));
    const storageCountries = getDataFromLS(LS_DUPLICATE_CAMPAIGN_COUNTRIES);

    if (storageDateStart.getDate() === selectedDateStart.getDate() && storageDateStop?.getDate() === selectedDateStop?.getDate()) {
      handleSetCountriesList(!!storageCountries?.length ? storageCountries : []);
    } else if (selectedDateStart && selectedDateStop && campaignInfo) {
      handleSelectCountriesList(ParseDuplicateKeywords(campaignInfo, selectedDateStart, selectedDateStop));
      setDataToLS(LS_DUPLICATE_CAMPAIGN_DATE_START, selectedDateStart);
      setDataToLS(LS_DUPLICATE_CAMPAIGN_DATE_STOP, selectedDateStop);
    }
  }, [campaignInfo, selectedDateStart, selectedDateStop]);

  const handleSelectDates = (dates) => {
    const [dateStart, dateStop] = dates;
    handleSetDateStart(dateStart);
    handleSetDateStop(dateStop);
  }

  const handleSelectCheckboxesValue = (value) => {
    handleSetCheckboxesValue(value);
    setDataToLS(LS_DUPLICATE_CAMPAIGN_CHECKBOXES_VALUE, value);
  }

  const handleSelectDelayStartTime = (timeString) => {
    handleSetSelectedDelayStart(timeString);
    setDataToLS(LS_DUPLICATE_CAMPAIGN_DELAY_TIME, timeString);
  }

  const handleResetForm = () => {
    handleSetDateStart(new Date());
    handleSetDateStop(null);
    handleSetCountriesList([]);

    localStorage.removeItem(LS_DUPLICATE_CAMPAIGN_COUNTRIES);
    setDataToLS(LS_DUPLICATE_CAMPAIGN_DATE_START, new Date());
    setDataToLS(LS_DUPLICATE_CAMPAIGN_DATE_STOP, null);
    handleSelectCheckboxesValue(0);
    handleSelectDelayStartTime(campaignInfo.delayed_start);
  }

  useImperativeHandle(ref, () => ({
    handleResetForm: handleResetForm
  }))

  return (
    (!!allApplicationsList.length && !!allClientsList.length && !!allSuppliersList.length && !!campaignInfo) ?
      <>
        <NewCampaignForm
          scenario={'Duplicate'}
          allApplicationsList={allApplicationsList}
          allClientsList={allClientsList}
          allSuppliersList={allSuppliersList}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedType={selectedType}
          selectedApplication={selectedApplication}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          handleSelectApplication={handleSetApplication}
          handleSelectClient={handleSetClient}
          currentClientCost={currentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          countriesList={countriesList}
          handleSelectDates={handleSelectDates}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          checkboxesValue={checkboxesValue}
          handleSelectCheckboxesValue={handleSelectCheckboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSelectDelayStartTime={handleSelectDelayStartTime}
        />
        <NewCampaignCountriesList
          countriesList={countriesList}
          dateStart={selectedDateStart}
          dateStop={selectedDateStop}
          handleSetCountriesList={handleSelectCountriesList}
          selectedType={selectedType}
        />
        <NewCampaignTotalBlock
          handleSendNewCampaign={handleSendNewCampaign}
          countriesList={countriesList}
          selectedType={selectedType}
          currentClientCost={currentClientCost}
          handleResetForm={handleResetForm}
        />
      </> :
      <Loader backdrop size="lg" content="loading..." vertical/>
  );
});

export default RecreateCampaign;