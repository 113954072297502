import {forwardRef, React, useEffect, useState} from 'react';
import {
  CloseButton,
  DateFilterBlock,
  DateFilterLabel,
  DateInput,
  FilterBlock,
  LogsBlock,
  StyledDataPicker,
  StyledHeadTr,
  StyledTable,
  StyledTbody,
  StyledTh,
  StyledThead,
  StyledTr,
  TableBlock
} from './styled';
import DatePicker from "react-datepicker"
import API from '../../../Api/API';
import moment from 'moment/moment';
import {ClearBtn} from "../../Campaigns/styled";

const ChangesLogs = ({type, logId, close, typeLog}) => {
  const [logsList, setLogsList] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateStop, setDateStop] = useState(null);

  useEffect(() => {
    API.GetLogs({
      "typeLog": typeLog,
      "type": type,
      "id": logId,
      "date_start": dateStart,
      "date_stop": dateStop
    }).then(data => {
      setLogsList(data.data)
    })
  }, [])

  useEffect(() => {
    const params = {
      "typeLog": typeLog,
      "type": type,
      "id": logId,
    }

    if (dateStart) {
      params.date_start = moment(dateStart).format('YYYY-MM-DD');
    }

    if (dateStop) {
      params.date_stop = moment(dateStop).format('YYYY-MM-DD');
    }

    API.GetLogs(params).then(data => {
      setLogsList(data.data)
    })

  }, [dateStart, dateStop])

  const ExampleCustomInput = forwardRef(({value, onClick, placeholder}, ref) => (
    <StyledDataPicker onClick={onClick} ref={ref} value={value} placeholder={placeholder} readOnly/>
  ));

  const getNextDay = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
  }

  const handleClearStartDate = () => {
    setDateStart(0)
  }

  const handleClearEndDate = () => {
    setDateStop(0)
  }

  return (
    <LogsBlock>
      <FilterBlock>
        <DateFilterLabel>
          <DateFilterBlock>
            <DateInput>
              <DatePicker
                onChange={(date) => {
                  setDateStart(date);
                }}
                selected={dateStart}
                customInput={<ExampleCustomInput/>}
                placeholderText={'Start date'}
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
              />
              {dateStart ? <ClearBtn onClick={handleClearStartDate}>✖</ClearBtn> : ''}
            </DateInput>
            <DateInput>
              <DatePicker
                onChange={(date) => {
                  setDateStop(date);
                }}
                selected={dateStop}
                customInput={<ExampleCustomInput/>}
                placeholderText={'Stop date'}
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                maxDate={getNextDay(new Date())}
              />
              {dateStop ? <ClearBtn onClick={handleClearEndDate}>✖</ClearBtn> : ''}
            </DateInput>
          </DateFilterBlock>
        </DateFilterLabel>
      </FilterBlock>
      <TableBlock>
        <StyledTable>
          <StyledThead>
            <StyledHeadTr>
              <StyledTh>Date</StyledTh>
              <StyledTh>Type</StyledTh>
              <StyledTh>Value</StyledTh>
              <StyledTh>Before</StyledTh>
              <StyledTh>After</StyledTh>
              <StyledTh>User id</StyledTh>
            </StyledHeadTr>
          </StyledThead>
          <StyledTbody>
            {logsList.length !== 0 &&
              logsList.map(log =>
                <StyledTr key={log.id}>
                  <StyledTh>{moment(new Date(log.createdAt)).format('YYYY-MM-DD, HH:mm')}</StyledTh>
                  <StyledTh>{log.type}</StyledTh>
                  <StyledTh>{log.value}</StyledTh>
                  <StyledTh>{log.before}</StyledTh>
                  <StyledTh>{log.after}</StyledTh>
                  <StyledTh>{log.auth_user_id}</StyledTh>
                </StyledTr>
              )}

          </StyledTbody>
        </StyledTable>
      </TableBlock>
      <CloseButton onClick={() => close()}>Close</CloseButton>
    </LogsBlock>
  );
}

export default ChangesLogs;
