import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  width: 500px;
  overflow: visible;
  position: relative;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  position: relative;
`;

export const SubmitButton = styled.button`
  display: block;
  width: 100%;
  background-color: #3E3E3E;
  border-radius: 5px;
  text-align: center;
  padding: 12px 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  font-family: 'Urbanist', sans-serif;

  &:hover {
    background-color: #EAEAEA;
    color: #3e3e3e;
  }
`;

export const LogoForSelect = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 18px;
`;

export const TextForSelect = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  padding: 5px 0;
`;