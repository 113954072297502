import {KeywordsAddition, KeywordsAdditionSpan, KeywordsAdditionTextArea, KeywordsAdditionButton} from '../styled';

const KeywordsAdditionBlock = ({
                                 handleAddNewKeysFromTextarea,
                                 textAreaRef,
                                 handleAddNewKeywords,
                                 handleRemoveCountry,
                                 countryIndex
                               }) => {

  return (
    <KeywordsAddition>
      <KeywordsAdditionSpan>Keywords*</KeywordsAdditionSpan>
      <KeywordsAdditionTextArea
        rows={4}
        onChange={handleAddNewKeysFromTextarea}
        ref={textAreaRef}
      />
      <KeywordsAdditionButton onClick={handleAddNewKeywords}>Add keywords</KeywordsAdditionButton>
      <KeywordsAdditionButton onClick={() => handleRemoveCountry(countryIndex)}>Remove
        country</KeywordsAdditionButton>
    </KeywordsAddition>
  );
};

export default KeywordsAdditionBlock;