import React from 'react';
import {FilterButton, FilterButtons} from "../../styled";
import {ORDERS_STATUSES} from "../../Constants/CampaignsStatuses";

const OrdersStatusModule = ({handleSetStatus, selectedStatus}) => {


  return (
    <FilterButtons>
      {ORDERS_STATUSES.filter(status => status.number !== 7).map((order) =>
        <FilterButton
          key={order.status}
          onClick={() => {
            handleSetStatus(order.number);
          }}
          active={selectedStatus === order.number}
        >{order.title}</FilterButton>
      )}
    </FilterButtons>
  );
};

export default OrdersStatusModule;