import styled from 'styled-components';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    padding: 31px 50px;
    gap: 25px;
`;

export const RadioLabel = styled.label`
    display: flex;
    gap: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
`;

export const StyledRadioBtn = styled.input``;

export const TextLabel = styled.label`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
`;

export const StyledInput = styled.input`
    font-size: 16px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #9C9C9C;
    border-radius: 5px;
    max-width: 160px;
`;

export const ApplyBtn = styled.input`
    width: 100%;
    background-color: #3E3E3E;
    padding: 10px 0;
    color: #ffffff;
    border: 0;
    border-radius: 5px;
`;