import React from 'react';
import {
  KeywordsAddition,
  KeywordsAdditionButton,
  KeywordsAdditionSpan, RatesCheckbox,
  RatesCheckboxesBlock,
  RatesCheckboxItem, RatesCheckboxTitle
} from "../styled";

const RatesAdditionBlock = ({handleRemoveCountry, countryIndex, handleChooseRateValue, getSelectedRates}) => {

  return (
    <KeywordsAddition>
      <KeywordsAdditionSpan>Rates*</KeywordsAdditionSpan>
      <RatesCheckboxesBlock>
        <RatesCheckboxItem>
          <RatesCheckboxTitle>4-star</RatesCheckboxTitle>
          <RatesCheckbox
            type="checkbox"
            id={'rate4star' + countryIndex}
            value={'4 star rates'}
            onChange={handleChooseRateValue}
            checked={getSelectedRates('4 star rates')}
          />
        </RatesCheckboxItem>
        <RatesCheckboxItem>
          <RatesCheckboxTitle>5-star</RatesCheckboxTitle>
          <RatesCheckbox
            type="checkbox"
            id={'rate5star' + countryIndex}
            value={'5 star rates'}
            onChange={handleChooseRateValue}
            checked={getSelectedRates('5 star rates')}
          />
        </RatesCheckboxItem>
      </RatesCheckboxesBlock>
      <KeywordsAdditionButton onClick={() => handleRemoveCountry(countryIndex)}>
        Remove country</KeywordsAdditionButton>
    </KeywordsAddition>
  );
};

export default RatesAdditionBlock;