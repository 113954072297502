import styled from 'styled-components';
import clockImg from "../../Images/icons/clock-icon.svg";
import searchImg from '../../Images/icons/search-icon.svg';
import userImg from '../../Images/icons/user-icon.svg';

export const PageContainer = styled.section`
  position: relative;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 60px;
`;

export const PageHeading = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #3E3E3E;
  margin: 0 0 42px;`;

export const ControlBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 42px;
  row-gap: 20px;
`;

export const ControlButtons = styled.div`
  display: flex;
  gap: 24px;
  width: max-content;
`;

export const ChooseBlock = styled.div`
  display: flex;
  width: 52%;
  justify-content: space-between;
`;

export const ControlButton = styled.button`
  background-color: #3e3e3e;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  width: 200px;
`;

export const TableBlock = styled.div`
  
`;

export const StyledTable = styled.table`
    width: 100%;
`;

export const StyledThead = styled.thead`
    background: rgba(222, 222, 222, 0.82);
    border-top: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledHeadTr = styled.tr`
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
`;

export const StyledTbody = styled.tbody``;

export const StyledTr = styled.tr`
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #3E3E3E;
    border-bottom: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledTotalTr = styled.tr`
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #3E3E3E;
    border-bottom: 1px solid rgba(115, 127, 210, 0.25);
    position: sticky;
    bottom: 0;
    background: rgba(222, 222, 222);
`;

export const StyledTh = styled.th`
    padding: 12px;
`;

export const SelectLabel = styled.label`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const SelectInput = styled.select`
background-color: inherit;
color: #3E3E3E;
font-family: 'Urbanist', sans-serif;
font-weight: 600;
font-size: 14px;
width: 150px;
min-height: 30px;
padding: 8px 15px;
box-sizing: border-box;
border: 1px solid rgba(62, 62, 62, 0.5);
border-radius: 5px;
`;

export const StyledDataPicker = styled.input`
    background-color: inherit;
    color: #3E3E3E;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    font-size: 14px;
    width: 465px;
    min-height: 30px;
    padding: 8px 15px;
    box-sizing: border-box;
    border: 1px solid rgba(62, 62, 62, 0.5);
    border-radius: 5px;
    max-width: 120px;
    cursor: pointer;
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &::before {
    content: '';
    display: block;
    background-image: url(${(props) => props.icon === 'search' ? searchImg : props.icon === 'user' ? userImg : clockImg});
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
  }
`;

export const TimeOption = styled.div`
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #3E3E3E;
    max-width: 150px;
`;

export const TotalBlock = styled.aside`
  position: sticky;
  width: 100%;
  padding: 0 60px;
  bottom: 0;
  box-sizing: border-box;
`;