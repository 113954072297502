import styled from "styled-components";

export const ModalContent = styled.div``;

export const ColList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ColLabel = styled.label`
  display: flex;
  gap: 5px;

  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

export const ColCheckBox = styled.input``;

export const SaveButton = styled.button`
  width: 100%;
  background-color: #3e3e3e;
  color: #ffffff;
  border-radius: 5px;

  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  padding: 10px 0;
`;