import React, {forwardRef} from 'react';
import {
  ConfirmCancelButton,
  ConfirmModalButtonBlock,
  ConfirmModalContent,
  ConfirmModalHeading,
  ConfirmModalText,
  ConfirmModalWrapper, ConfirmApplyButton
} from "./styled";
import {Loader} from "rsuite";

const ConfirmModal = forwardRef(({onSubmit, onClose, actionText, actionTitle, confirmLoading, campaignId}, ref) => {
  const handleSubmit = () => {
    if (campaignId) {
      onSubmit(campaignId)
    }
  }

  return (
    <ConfirmModalWrapper ref={ref}>
      <ConfirmModalContent>
        <ConfirmModalHeading>{actionTitle}</ConfirmModalHeading>
        <ConfirmModalText>{actionText}</ConfirmModalText>
        <ConfirmModalButtonBlock>
          <ConfirmCancelButton onClick={onClose}>Close</ConfirmCancelButton>
          <ConfirmApplyButton onClick={handleSubmit}>Submit</ConfirmApplyButton>
        </ConfirmModalButtonBlock>
        {confirmLoading && <Loader backdrop size="lg" content="loading..." vertical/>}
      </ConfirmModalContent>
    </ConfirmModalWrapper>
  );
});

export default ConfirmModal;