import React from 'react';
import {AddCardIcon, AddCardText, AddCardWrapper, StyledAddCard} from './styled';


const AddNewAppBtn = ({...props}) => {
    return (
        <StyledAddCard {...props}>
            <AddCardWrapper>
                <AddCardIcon />
                <AddCardText>Add new app</AddCardText>
            </AddCardWrapper>
        </StyledAddCard>
    );
}

export default AddNewAppBtn;