import React, {useEffect, useState} from 'react';
import {CountriesAddButton, CountriesBlock, CountriesList} from "./styled";
import NewCampaignCountryItem from "../NewCampaignCountryItem";
import {getDatesBetween, getDatesListFromCountry, getKeysFromCountry, throwToastError} from "../../../utils";

const NewCampaignCountriesList = React.memo(({
                                               countriesList,
                                               handleSetCountriesList,
                                               dateStart,
                                               dateStop,
                                               selectedType
                                             }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);

  useEffect(() => {
    const allCountries = countriesList.map((country) => {
      if (country.geo.length) {
        return country.geo
      }
    })

    setSelectedCountries(allCountries)
  }, [countriesList]);

  const putEditedCountryInList = (country, index) => {
    //функция, добавляющая отредактированную страну в список
    const newCountriesList = [...countriesList];
    newCountriesList[index] = country;
    handleSetCountriesList(newCountriesList);
  }

  const handleAddNewCountry = () => {
    if (!selectedType) {
      throwToastError('Please select campaign type')
      return
    }

    if (String(selectedType) === '3') {
      if (!dateStart || !dateStop) {
        throwToastError('Please select start and end date')
        return;
      }

      const dateArray = getDatesBetween(dateStart, dateStop);

      let newCountry = {geo: '', keywords: {}};

      dateArray.forEach((date) => {
        newCountry.keywords[date] = {'#install_by_package#': 0};
      });

      let countries = [...countriesList];
      countries.push(newCountry);
      handleSetCountriesList(countries)
      return;
    }

    //функция добавляет новую страну
    let countries = [...countriesList];
    countries.push({geo: '', keywords: {}});
    handleSetCountriesList(countries)
  }

  const handleCloneCountry = (countryIndex) => {
    const newCountry = JSON.parse(JSON.stringify(countriesList[countryIndex])); //глубокое копирование для избежания мутаций базового объекта
    newCountry.geo = '';

    const newCountriesList = [...countriesList];

    newCountriesList.splice(countryIndex + 1, 0, newCountry);
    handleSetCountriesList(newCountriesList);
  }

  const handleRemoveCountry = (countryIndex) => {
    const newCountriesList = [...countriesList];
    newCountriesList.splice(countryIndex, 1);
    handleSetCountriesList(newCountriesList)
  }

  useEffect(() => {
    if (dateStart && dateStop && countriesList.length) {
      let newCountriesList = [];
      countriesList.forEach(country => {
        const prevKeywordsObj = country.keywords;
        let newKeywordsObj = {};

        const prevDateArray = getDatesListFromCountry(country);
        const newDateArray = getDatesBetween(dateStart, dateStop);

        let countryKeys = []
        if (Object.values(country.keywords).length) {
          countryKeys = getKeysFromCountry(country);
        }

        if (countryKeys.length) {
          newDateArray.forEach((newDate) => {
            if (prevDateArray.find(prevDate => prevDate === newDate)) {
              newKeywordsObj[newDate] = prevKeywordsObj[newDate]
            } else {
              newKeywordsObj[newDate] = {};
              countryKeys.forEach((key) => newKeywordsObj[newDate][key] = 0)
            }
          });
        }

        const newCountry = JSON.parse(JSON.stringify(country));
        newCountry.keywords = newKeywordsObj;
        newCountriesList.push(newCountry);
      })

      handleSetCountriesList(newCountriesList)
    }
  }, [dateStart, dateStop]);

  return (
    <CountriesBlock>
      <CountriesList>
        {!!countriesList?.length && countriesList?.map((country, index) =>
          <NewCampaignCountryItem
            key={index}
            countryIndex={index}
            country={country}
            putEditedCountryInList={putEditedCountryInList}
            handleRemoveCountry={handleRemoveCountry}
            handleCloneCountry={handleCloneCountry}
            dateStart={dateStart}
            dateStop={dateStop}
            selectedType={selectedType}
            selectedCountries={selectedCountries}
          />
        )}
      </CountriesList>
      <CountriesAddButton onClick={handleAddNewCountry}>Add country</CountriesAddButton>
    </CountriesBlock>
  );
});

export default NewCampaignCountriesList;