import styled from "styled-components";

export const ConfirmModalWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 67px 40px;

  height: 350px;
  position: relative;
`;

export const ConfirmModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  gap: 70px;
`;

export const ConfirmModalHeading = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 100%;
  color: #3E3E3E;
  text-align: center;
`;

export const ConfirmModalText = styled.p`
  font-family: 'Urbanist', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  text-align: center;
`;

export const ConfirmModalButtonBlock = styled.div`
  display: flex;
  gap: 90px;
`;

export const ConfirmApplyButton = styled.button`
  background: transparent;
  max-width: 100px;
  padding: 5px 24px;
  border: 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #000000;

  &:hover {
    color: #3e3e3e;
  }
`;

export const ConfirmCancelButton = styled.button`
  background-color: #D27373;
  border: 0;
  max-width: 100px;
  padding: 5px 24px;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  border-radius: 5px;
`;