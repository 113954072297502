import {useEffect, useState} from 'react';
import {
  ClearBtn,
  CountryItem,
  CountryList,
  CountrySearchBlock,
  CountrySearchErrorText,
  CountrySpan,
  SearchInput
} from './styled';
import CountriesList from '../../../Api/Countries';

const CountrySearchSelect = ({selectCountry, defaultVal, size, onError, errorText}) => {
  const correctCountry = !!CountriesList.find((c) => c.ISO === defaultVal);

  const [openList, setOpenList] = useState(false);
  const [searchCountry, setSearchCountry] = useState(correctCountry ? defaultVal : null);
  const [searchInput, setSearchInput] = useState(correctCountry ? CountriesList.find((c) => c.ISO === defaultVal).Country : defaultVal);
  const [searchList, setSearchList] = useState(CountriesList);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setSearchCountry(defaultVal);
    setSearchInput(correctCountry ? CountriesList.find((c) => c.ISO === defaultVal).Country : defaultVal)
  }, [correctCountry, defaultVal]);

  useEffect(() => {
    setActiveIndex(0)
    if (searchInput !== undefined) {
      const result = CountriesList.filter((country) => country.Alias.some((alias) => alias.toLowerCase().includes(searchInput.toLowerCase())))
      setSearchList(result)
    }
    if (searchInput === '') {
      selectCountry('')
    }
  }, [searchInput])

  const handleOpenAppsList = () => {
    setOpenList(true);
  }

  const handleCloseAppsList = () => {
    setOpenList(false);
  }

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  }

  const handleClearInput = () => {
    setSearchInput('');
    setSearchCountry(null)
  }

  const handleKeyDownArrow = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) => prevIndex - 1); // Перемещение к предыдущему пункту
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) => prevIndex + 1); // Перемещение к следующему пункту
    } else if (e.key === 'Enter') {
      e.preventDefault();
      setSearchCountry(searchList[activeIndex].ISO);
      setSearchInput(searchList[activeIndex].Country);
      selectCountry(searchList[activeIndex].ISO);
      setOpenList(false)
    }
  }


  return (
    <CountrySearchBlock>
      <SearchInput type='text'
                   onKeyDown={handleKeyDownArrow}
                   onFocus={handleOpenAppsList}
                   onBlur={handleCloseAppsList}
                   onChange={handleSearchInputChange}
                   value={searchInput}
                   placeholder='Choose country'
                   correct={searchInput ? (correctCountry) : true}
                   size={size}
                   data-on-error={onError}
      />
      {onError && errorText && <CountrySearchErrorText>{errorText}</CountrySearchErrorText>}
      {searchCountry && <ClearBtn onClick={handleClearInput}>✖</ClearBtn>}
      <CountryList open={openList}>
        {searchList.map((obj, id) => (
          <CountryItem
            key={obj.ISO}
            active={id === activeIndex}
            onMouseDown={() => {
              setSearchCountry(obj.ISO);
              setSearchInput(obj.Country);
              selectCountry(obj.ISO);
            }}
          >
            <CountrySpan>{obj.Country}</CountrySpan>
          </CountryItem>
        ))}
      </CountryList>
    </CountrySearchBlock>
  );
}

export default CountrySearchSelect;

