import styled from "styled-components";

export const ModalWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const InputLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const ModalInput = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #9C9C9C;
  border-radius: 5px;`;

export const ModalTextArea = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #9C9C9C;
  border-radius: 5px;
`;

export const SubmitButton = styled.button`
  width: 100%;
  background-color: #3E3E3E;
  padding: 10px 0;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
`;