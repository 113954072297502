import styled from "styled-components";

export const BasicInputBlock = styled.div`
  position: relative;

  &[data-size='lg'] {
    width: 100%;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  width: 100%;
  padding: 15px 38px 15px 15px;
  font-size: 18px;
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid #3E3E3E;
  border-radius: 5px;

  &:disabled {
    color: #D7D7D7;
  }

  &[data-on-error='true'] {
    border: 1px solid #D27373;
    background-color: #ffdede;
  }
`;

export const InputErrorText = styled.span`
  color: #D27373;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 14px;

  position: absolute;
  display: block;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const InputClearButton = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 50%;
  padding: 12px;
  box-sizing: content-box;
  line-height: 100%;

  transform: translate(0, -50%);
`;