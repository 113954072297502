import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {getDataFromLS, setDataToLS} from "../../utils";
import {
  LS_PROMOTE_CAMPAIGN_APP,
  LS_PROMOTE_CAMPAIGN_CHECKBOXES_VALUE,
  LS_PROMOTE_CAMPAIGN_CLIENT,
  LS_PROMOTE_CAMPAIGN_COUNTRIES,
  LS_PROMOTE_CAMPAIGN_DATE_START,
  LS_PROMOTE_CAMPAIGN_DATE_STOP,
  LS_PROMOTE_CAMPAIGN_DELAY_TIME,
  LS_PROMOTE_CAMPAIGN_SUPPLIER,
  LS_PROMOTE_CAMPAIGN_TYPE, LS_PROMOTE_SHADOW_INSTALLS_BP_COUNTRIES,
  LS_PROMOTE_SHADOW_INSTALLS_COUNTRIES,
  LS_PROMOTE_SHADOW_RATES_COUNTRIES
} from "../constants";
import {Loader} from "rsuite";
import NewCampaignForm from "../Modules/NewCampaignForm";
import NewCampaignCountriesList from "../Modules/NewCampaignCountriesList";
import NewCampaignTotalBlock from "../Modules/NewCampaignTotalBlock";

const PromoteCampaign = forwardRef(({
                                      allSuppliersList,
                                      allApplicationsList,
                                      allClientsList,
                                      appId,
                                      selectedSupplier,
                                      selectedType,
                                      selectedClient,
                                      selectedApplication,
                                      countriesList,
                                      currentClientCost,
                                      selectedApplicationInfo,
                                      handleSetCurrentClientCost,
                                      handleSetCountriesList,
                                      selectedDateStop,
                                      selectedDateStart,
                                      handleSetApplication,
                                      selectedClientInfo,
                                      handleSetClient,
                                      handleSetDateStart,
                                      handleSetDateStop,
                                      handleSetSupplier,
                                      handleSetType,
                                      handleSendNewCampaign,
                                      checkboxesValue,
                                      handleSetCheckboxesValue,
                                      selectedDelayStart,
                                      handleSetSelectedDelayStart
                                    }, ref) => {
  const [storageIsLoading, setStorageIsLoading] = useState(true);
  const fetchDataFromStorage = async () => {
    return new Promise((resolve) => {
      const supplier = Number(getDataFromLS(LS_PROMOTE_CAMPAIGN_SUPPLIER));
      !!supplier && handleSetSupplier(supplier);
      const type = Number(getDataFromLS(LS_PROMOTE_CAMPAIGN_TYPE));
      !!type && handleSetType(type);
      const client = Number(getDataFromLS(LS_PROMOTE_CAMPAIGN_CLIENT));
      !!client && handleSetClient(client);
      const dateStart = getDataFromLS(LS_PROMOTE_CAMPAIGN_DATE_START);
      !!dateStart ? handleSetDateStart(new Date(dateStart)) : handleSetDateStart(new Date());
      const dateStop = getDataFromLS(LS_PROMOTE_CAMPAIGN_DATE_STOP);
      !!dateStop ? handleSetDateStop(new Date(dateStop)) : handleSetDateStop(null);
      const checkboxes = getDataFromLS(LS_PROMOTE_CAMPAIGN_CHECKBOXES_VALUE);
      !!checkboxes && handleSetCheckboxesValue(checkboxes);
      const delayTime = getDataFromLS(LS_PROMOTE_CAMPAIGN_DELAY_TIME);
      !!delayTime && handleSetSelectedDelayStart(delayTime);
      const countries = getDataFromLS(LS_PROMOTE_CAMPAIGN_COUNTRIES);
      countries && !!countries.length && handleSetCountriesList(countries)

      resolve();
    })
  }

  const handleSelectApplication = (applicationId) => {
    handleSetApplication(applicationId);
    setDataToLS(LS_PROMOTE_CAMPAIGN_APP, applicationId)
  }

  useEffect(() => {
    const storageApp = getDataFromLS(LS_PROMOTE_CAMPAIGN_APP);

    if (allApplicationsList.length && storageApp) {
      const storageAppObj = allApplicationsList.find((app) => app.id === Number(storageApp))
      const allEqualityApps = allApplicationsList.filter((app) => app?.appid === storageAppObj?.appid);

      if (allEqualityApps && allEqualityApps.find(app => app.id === Number(appId))) {
        handleSetApplication(Number(storageApp))
        fetchDataFromStorage().then(() => {
          setStorageIsLoading(false)
        })
      } else {
        handleSelectApplication(appId)
        setStorageIsLoading(false);
      }
    } else {
      handleSelectApplication(appId)
      setStorageIsLoading(false);
    }
  }, []);

  const handleSelectSupplier = (supplierId) => {
    handleSetSupplier(supplierId);
    setDataToLS(LS_PROMOTE_CAMPAIGN_SUPPLIER, supplierId);
  }

  const handleSelectClient = (clientId) => {
    handleSetClient(clientId);
    setDataToLS(LS_PROMOTE_CAMPAIGN_CLIENT, clientId)
  }

  const handleSelectType = (newType) => {
    handleSetType(newType)

    const typeKeys = {
      '1': LS_PROMOTE_SHADOW_INSTALLS_COUNTRIES,
      '2': LS_PROMOTE_SHADOW_RATES_COUNTRIES,
      '3': LS_PROMOTE_SHADOW_INSTALLS_BP_COUNTRIES,
    };

    if (selectedType && countriesList.length > 0) {
      const key = typeKeys[selectedType];
      if (key) {
        setDataToLS(key, countriesList);
        handleSetCountriesList([]);
        localStorage.removeItem(LS_PROMOTE_CAMPAIGN_COUNTRIES);
      }
    }

    if (newType) {
      setDataToLS(LS_PROMOTE_CAMPAIGN_TYPE, newType);
      const key = typeKeys[newType];

      if (key) {
        const shadowCountries = localStorage.getItem(key);

        if (shadowCountries) {
          const parsedCountries = JSON.parse(shadowCountries);
          handleSetCountriesList(parsedCountries);
          setDataToLS(LS_PROMOTE_CAMPAIGN_COUNTRIES, parsedCountries);
        }
      }
    } else {
      Object.values(typeKeys).forEach(key => localStorage.removeItem(key));
      localStorage.removeItem(LS_PROMOTE_CAMPAIGN_TYPE);
    }
  }

  const handleSelectDates = (dates) => {
    const [startDate, stopDate] = dates;
    handleSetDateStart(startDate);
    handleSetDateStop(stopDate);
    setDataToLS(LS_PROMOTE_CAMPAIGN_DATE_START, startDate);
    setDataToLS(LS_PROMOTE_CAMPAIGN_DATE_STOP, stopDate);
  }

  const handleSelectCheckboxesValue = (value) => {
    handleSetCheckboxesValue(value);
    setDataToLS(LS_PROMOTE_CAMPAIGN_CHECKBOXES_VALUE, value);
  }

  const handleSelectDelayStartTime = (timeString) => {
    handleSetSelectedDelayStart(timeString);
    setDataToLS(LS_PROMOTE_CAMPAIGN_DELAY_TIME, timeString);
  }

  const handleSelectCountriesList = (newCountriesList) => {
    handleSetCountriesList(newCountriesList);
    setDataToLS(LS_PROMOTE_CAMPAIGN_COUNTRIES, newCountriesList);
  }

  const handleResetForm = () => {
    handleSelectSupplier(0);
    handleSelectType(0);
    handleSelectClient(0);
    handleSelectDates([new Date(), null]);
    handleSelectCheckboxesValue(0);
    handleSelectDelayStartTime(null);
    handleSelectCountriesList([])
    handleSetCurrentClientCost(0)
  }

  useImperativeHandle(ref, () => ({
    handleResetForm: handleResetForm
  }))

  return (
    (allClientsList.length > 0 && allApplicationsList.length > 0 && allSuppliersList.length > 0 && !storageIsLoading && appId) ?
      <>
        <NewCampaignForm
          scenario={'Promote'}
          allSuppliersList={allSuppliersList}
          allApplicationsList={allApplicationsList}
          allClientsList={allClientsList}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedApplication={selectedApplication}
          selectedType={selectedType}
          handleSelectApplication={handleSelectApplication}
          handleSelectSupplier={handleSelectSupplier}
          handleSelectClient={handleSelectClient}
          handleSelectType={handleSelectType}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          handleSelectDates={handleSelectDates}
          countriesList={countriesList}
          currentClientCost={currentClientCost}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          checkboxesValue={checkboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSelectCheckboxesValue={handleSelectCheckboxesValue}
          handleSelectDelayStartTime={handleSelectDelayStartTime}
        />
        <NewCampaignCountriesList
          countriesList={countriesList}
          handleSetCountriesList={handleSelectCountriesList}
          dateStart={selectedDateStart}
          dateStop={selectedDateStop}
          selectedType={selectedType}
        />
        <NewCampaignTotalBlock
          handleSendNewCampaign={handleSendNewCampaign}
          currentClientCost={currentClientCost}
          countriesList={countriesList}
          selectedType={selectedType}
          handleResetForm={handleResetForm}
        />
      </> :
      <Loader backdrop size="lg" content="loading..." vertical/>
  );
});

export default PromoteCampaign;