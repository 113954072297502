import styled from "styled-components";

export const BalanceBlock = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  right: 0;
  top: 0;
`;

export const BalanceText = styled.span`
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
`

export const BalanceValue = styled.span`
  color: ${props => props.sign ? '#00BA3F' : 'tomato'};
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 100%;
`;