import styled from 'styled-components';

export const ClientSearchBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ClientSearchErrorText = styled.span`
  color: #D27373;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 14px;

  position: absolute;
  display: block;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const SearchInput = styled.input`
  background-color: transparent;
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 250px;
  min-height: 30px;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid #3E3E3E;
  border-radius: 5px;
  cursor: pointer;

  &[data-size='lg'] {
    width: 100%;
    padding: 15px 10px 15px 15px;
    font-size: 18px;
  }

  &[data-on-error='true'] {
    border: 1px solid #D27373;
    background-color: #ffdede;
  }
`;

export const ClientsList = styled.div`
  display: ${(props) => props.open ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 100%;
  list-style-type: none;
  padding: 0;
  max-height: 600px;
  overflow: auto;
  z-index: 11;
  background-color: #fff;
`;

export const ClientItem = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;

  &:hover {
    background: #d7d7d7;
  }
`;

export const SelectItemSpan = styled.span``;

export const ClearBtn = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 50%;
  padding: 12px;
  box-sizing: content-box;
  line-height: 100%;

  transform: translate(0, -50%);
`;