import * as React from 'react';
import {DialogTitle, DialogContent, Dialog} from '@mui/material';
import {Loader} from 'rsuite';

import EditRowIcon from '../../Images/icons/edit-row-icon.svg';
import {useEffect, useState} from "react";

import API from "../../Api/API";
import EditAdmin from "./edit-admin/edit-admin";
import {
  ControlBlock,
  PageContainer,
  PageHeading,
  PageWrapper,
  SearchInput, StyledHeadTr, StyledTable, StyledTbody, StyledTh, StyledThead, StyledTr, TableBlock
} from './styled';
import useDebounce from '../utils/use-debounce';

const Admins = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const [curUser, setCurUser] = useState();
  const [loading, setLoading] = useState(true);
  const [adminsSearch, setAdminsSearch] = useState();
  const [tariffList, setTariffList] = useState(null);
  const [adminList, setAdminList] = useState(null);

  const debouncedSearch = useDebounce(adminsSearch, 500);

  const handleClose = () => {
    setOpenEdit(false);
  };
  useEffect(() => {
    const getClientAndTariff = async () => {
      await API.getAllTariffs(0, 10000, '').then((data) => {
        setTariffList(data.data);
      });
      await API.getAllUsers(0, 10000, '').then((data) => {
        setAdminList(data.data);
      });
      setLoading(false);
    }
    getClientAndTariff();
  }, [])

  useEffect(() => {
    if (debouncedSearch !== undefined) {
      API.getAllUsers(0, 10000, debouncedSearch).then((data) => {
        if (data) {
          setAdminList(data.data)
          setLoading(false)
        }
      })
    }
  }, [debouncedSearch]);

  const handleRestartTable = () => {
    setLoading(true)
    API.getAllUsers(0, 10000, '').then((data) => {
      if (data) {
        setAdminList(data.data)
        setLoading(false);
      }
    })
  }

  const handleSearch = (e) => {
    setAdminsSearch(e.target.value);
  }
  return (
    <>
      {loading ? <Loader backdrop size="lg" content="loading..." vertical/> :
        <>
          <PageContainer>
            <PageWrapper>
              <PageHeading>Admins</PageHeading>
              <ControlBlock>
                <SearchInput onChange={handleSearch} placeholder={'Search admin'}/>
              </ControlBlock>
              <TableBlock>
                <StyledTable>
                  <StyledThead>
                    <StyledHeadTr>
                      <StyledTh>ID</StyledTh>
                      <StyledTh>Name</StyledTh>
                      <StyledTh>Group</StyledTh>
                      <StyledTh>Tariff</StyledTh>
                      <StyledTh></StyledTh>
                    </StyledHeadTr>
                  </StyledThead>
                  <StyledTbody>
                    {adminList.length !== 0 &&
                      adminList.map(admin =>
                        <StyledTr key={admin.id}>
                          <StyledTh>{admin.id}</StyledTh>
                          <StyledTh>{admin.name}</StyledTh>
                          <StyledTh>{admin.group}</StyledTh>
                          <StyledTh>{tariffList.find((item) => item.id === admin.tariff)?.name}</StyledTh>
                          <StyledTh>
                            <img src={EditRowIcon}
                                 style={{cursor: "pointer"}}
                                 onClick={async () => {
                                   setCurUser(admin);
                                   setOpenEdit(true);
                                 }}
                                 alt=""
                            />
                          </StyledTh>
                        </StyledTr>
                      )
                    }
                  </StyledTbody>
                </StyledTable>
              </TableBlock>
            </PageWrapper>
          </PageContainer>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
            maxWidth={'800'}
          >
            <DialogContent dividers>
              <EditAdmin adminList={adminList} curUser={curUser} tariffs={tariffList} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>
        </>
      }
    </>
  )
}
export default Admins;