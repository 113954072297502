import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  CheckboxesBlockList,
  CheckboxItemInput,
  CheckboxItemRow,
  CheckboxItemTitle
} from "./styled";
import DelayedStart from "./modules/DelayedStart";
import DaysList from "./modules/DaysList";
import {checkboxesAttributes} from "./constants";
import {useParams} from "react-router-dom";

const NewCampaignCheckboxesBlock = ({
                                      selectedApplicationInfo,
                                      checkboxesValue,
                                      handleSelectCheckboxesValue,
                                      selectedDelayStart,
                                      handleSelectDelayStartTime,
                                      selectedDateStart
                                    }) => {
  const [isEnabledDelay, setIsEnabledDelay] = useState(false);
  const [selectedCheckboxesValues, setSelectedCheckboxesValues] = useState([]);

  const {scenario} = useParams();

  const daysArrayRef = useRef(null);
  const delayTimeRef = useRef(null);

  const handleToggleDaysRow = (e) => {
    handleSetSelectedCheckboxesValue(e)
    if (!e.target.checked) {
      let newArr = [...selectedCheckboxesValues];
      const filteredArr = newArr.filter((param) => typeof param === 'string' && !param.includes('day'))
      setCheckboxesValue(filteredArr);
    }
  }

  const handleToggleDelay = (e) => {
    setIsEnabledDelay(e.target.checked)
  }

  const getCheckboxesAttributesArray = useCallback(() => {
    //функция, которая парсит входящее битовое число в компонент в список параметров.
    const list = [];

    for (let attribute in checkboxesAttributes) {
      if ((checkboxesValue & checkboxesAttributes[attribute]) !== 0) {
        list.push(attribute)
      }
    }
    return list
  }, [checkboxesValue]);

  const setCheckboxesValue = useCallback((attributesList) => {
    //функция, переводящая список параметров в битовое значение
    const valuesArr = attributesList.map(attribute => checkboxesAttributes[attribute]);
    handleSelectCheckboxesValue(valuesArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
  }, [handleSelectCheckboxesValue])

  const handleSetSelectedCheckboxesValue = (e) => {
    //функция, управляющая списком выбранных чекбоксов и отправка их в родителя в виде числа
    const newArr = [...selectedCheckboxesValues];
    if (e.target.checked) {
      newArr.push(e.target.value);
    } else {
      newArr.splice(newArr.indexOf(e.target.value), 1);
    }
    setCheckboxesValue(newArr);
  };

  const handleDeleteOneValue = useCallback((value) => {
    //функция, удаляющая определенный элемент массива
    const newArr = [...selectedCheckboxesValues];
    if (newArr.find(param => param === value)) {
      newArr?.splice(newArr?.indexOf(value), 1);
      setCheckboxesValue(newArr)
    }
  }, [selectedCheckboxesValues, setCheckboxesValue])

  const isItGoogleApplication = useCallback(() => {
    //функция, проверяющая площадку приложения
    if (!!selectedApplicationInfo) {
      return selectedApplicationInfo.appid.includes('.');
    }
  }, [selectedApplicationInfo])

  useEffect(() => {
    if (selectedDelayStart) {
      setIsEnabledDelay(true);
      delayTimeRef.current.checked = true;
    }
  }, [selectedDelayStart])

  useEffect(() => {
    setSelectedCheckboxesValues(getCheckboxesAttributesArray())
  }, [checkboxesValue, getCheckboxesAttributesArray]);

  useEffect(() => {
    //функция, проверяющая площадку приложения и очистка чекбокса при его отключении
    if (!isItGoogleApplication()) {
      handleDeleteOneValue('consoleVisible')
    }
  }, [selectedApplicationInfo]);


  return (
    <CheckboxesBlockList>
      <CheckboxItemRow>
        <CheckboxItemTitle role={'title'} disabled={!isItGoogleApplication()}>
          Console visibility
        </CheckboxItemTitle>
        <CheckboxItemInput
          type='checkbox'
          value={'consoleVisible'}
          onChange={handleSetSelectedCheckboxesValue}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'consoleVisible')}
          disabled={!isItGoogleApplication() || scenario === 'extend'}
        />
      </CheckboxItemRow>
      <CheckboxItemRow role={'date'}>
        <CheckboxItemTitle role={'title'} disabled={scenario === 'extend'}>
          Launch app
        </CheckboxItemTitle>
        <CheckboxItemInput
          type='checkbox'
          value={'day'}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'day')}
          ref={daysArrayRef}
          onChange={handleToggleDaysRow}
          disabled={scenario === 'extend'}
        />
        <>
          <CheckboxItemTitle
            disabled={!selectedCheckboxesValues.find(attribute => attribute === 'day') || scenario === 'extend'}>
            Day
          </CheckboxItemTitle>
          <DaysList
            isDisabled={!selectedCheckboxesValues.find(attribute => attribute === 'day') || scenario === 'extend'}
            handleSetSelectedCheckboxesValues={handleSetSelectedCheckboxesValue}
            selectedCheckboxesValues={selectedCheckboxesValues}
          />
        </>
      </CheckboxItemRow>
      <CheckboxItemRow>
        <CheckboxItemTitle role={'title'} disabled={scenario === 'extend'}>
          Distribute
        </CheckboxItemTitle>
        <CheckboxItemInput
          type='checkbox'
          value={'distribute'}
          onChange={handleSetSelectedCheckboxesValue}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}
          disabled={scenario === 'extend'}
        />
        <CheckboxItemTitle
          disabled={scenario === 'extend' || !selectedCheckboxesValues.find(attribute => attribute === 'distribute')}>
          Throughout the day
        </CheckboxItemTitle>
      </CheckboxItemRow>
      <CheckboxItemRow>
        <CheckboxItemTitle role={'title'} disabled={scenario === 'extend'}>
          Delayed start
        </CheckboxItemTitle>
        <CheckboxItemInput
          type='checkbox'
          ref={delayTimeRef}
          disabled={scenario === 'extend'}
          onChange={handleToggleDelay}
        />
        <CheckboxItemTitle disabled={!isEnabledDelay || scenario === 'extend'}>
          Set time
        </CheckboxItemTitle>
        {delayTimeRef.current &&
          <DelayedStart
            selectedDelayStart={selectedDelayStart}
            handleSelectDelayStartTime={handleSelectDelayStartTime}
            scenario={scenario}
            isEnabled={isEnabledDelay && (scenario !== 'extend')}
            selectedDateStart={selectedDateStart}
          />
        }
      </CheckboxItemRow>
    </CheckboxesBlockList>
  );
};

export default NewCampaignCheckboxesBlock;