import styled from "styled-components";

export const CampaignForm = styled.div`
  padding: 35px 25px;
`;

export const ScenarioTitle = styled.h1`
  font-family: 'Urbanist', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #3E3E3E;
  margin: 0 0 42px;
`;

export const ParamsBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

export const TypeRow = styled.div`
  position: relative;
  max-width: 400px;
`;

export const PackageLabel = styled.label`
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  width: max-content;

  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PackageInput = styled.input`
  width: 20px;
  height: 20px;
`

export const ImportButton = styled.button`
  display: block;
  width: 245px;
  background-color: #3E3E3E;
  border-radius: 5px;
  text-align: center;
  padding: 5px 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  border: 0;
  margin-left: 155px;
  cursor: pointer;
  font-family: 'Urbanist', sans-serif;

  &:hover {
    background-color: #EAEAEA;
    color: #3e3e3e;
  }
`;

export const TimeOption = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  max-width: 400px;
`;

export const TimeOptionSpan = styled.span`
  text-align: right;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ExtendDateInput = styled.input`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #9C9C9C;
  border-radius: 5px;
  width: 245px;
`;

