import React, {useState} from 'react';
import {
  CountryTableBlock, CountryTasksBlock, CountryTasksCheckbox, CountryTasksCheckboxBlock,
  KeywordsTable,
  KeywordsTBody,
  KeywordsTd,
  KeywordsTdInput,
  KeywordsTh,
  KeywordsTHead,
  KeywordsTr,
  TableButton,
  TableButtonsBlock,
  TableButtonsRow
} from "./styled";
import {getDatesListFromCountry, getKeysFromCountry, getOneDayAgo, throwToastError} from "../../../utils";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import NewCampaignRatioModal from "../NewCampaignRatioModal/NewCampaignRatioModal";

const NewCampaignItemTableBlock = ({country, countryIndex, putEditedCountryInList}) => {
  const [ratioModalIsOpen, setRatioModalIsOpen] = useState(false);
  const [ratioKeyForModal, setRatioKeyForModal] = useState(null);

  const [rowAutofill, setRowAutofill] = useState(true);

  const checkLengthOfKeywords = () => !!Object.keys(country.keywords).length
  //проверяет существование массива ключей

  const getFirstCountryDate = (country) => getDatesListFromCountry(country)[0];
  //функция возвращает первую дату страны

  const getFirstCountryKeywordValue = (country, key) => parseInt(country.keywords[getFirstCountryDate(country)][key]);

  const handleChangeTableInput = (e, date, dateIndex, keyword) => {
    const regex = /^[0-9\b]+$/;
    const value = e.target.value;
    //обработчик ввода значений в поля в таблице
    if (regex.test(value) || value === '') {
      let newCountry = country;
      newCountry.keywords[date][keyword] = Number(e.target.value);
      if (dateIndex === 0 && rowAutofill) {
        Object.keys(country.keywords).map((date) =>
          newCountry.keywords[date][keyword] = Number(e.target.value)
        )
      }
      putEditedCountryInList(newCountry, countryIndex)
    }
  }

  const handleSetRatio = (type, valueRatio, ratioKey) => {
    //функция обработки управления строками и всей таблицей
    let newCountry = country;
    let newKeywords = {};

    if (type === 'deleteRow') {
      if (getKeysFromCountry(newCountry).length > 1) {
        getDatesListFromCountry(newCountry).forEach((date) => {
          delete newCountry.keywords[date][ratioKey];
        })
      } else {
        newCountry.keywords = {}
      }
    } else if (type === 'clearRow') {
      getDatesListFromCountry(newCountry).forEach((date) => {
        newCountry.keywords[date][ratioKey] = 0;
      })
    } else if (type === 'clearAll') {
      for (const date of Object.keys(newCountry.keywords)) {
        for (let key of Object.keys(newCountry.keywords[date])) {
          newCountry.keywords[date][key] = 0
        }
      }
    } else if (type === 'autofill') {
      if (valueRatio > 0) {
        for (const date of Object.keys(newCountry.keywords)) {
          for (let key of Object.keys(newCountry.keywords[date])) {
            newCountry.keywords[date][key] = valueRatio;
          }
        }
        setRatioModalIsOpen(false);
        setRatioKeyForModal(null);
      } else {
        throwToastError('Please enter a positive number');
      }
    } else if (type === 'percent' || type === 'amount' || type === 'deviation') {
      if (valueRatio) {
        for (const date of Object.keys(newCountry.keywords)) {
          let dateAgo = getOneDayAgo(date);
          newKeywords[date] = {};

          for (let keyword of Object.keys(newCountry.keywords[date])) {
            if (keyword === ratioKey || ratioKey === null) {
              if (newCountry.keywords[dateAgo] !== undefined) {
                if (type === 'percent') {
                  const newValue = (parseInt(newCountry.keywords[dateAgo][keyword]) * ((100 + parseInt(valueRatio)) / 100)).toFixed(0);

                  if (newValue > 0) {
                    newCountry.keywords[date][keyword] = newValue
                  } else {
                    newCountry.keywords[date][keyword] = 0
                  }
                } else if (type === 'amount') {
                  const newValue = Number(parseInt(newCountry.keywords[dateAgo][keyword]) + parseInt(valueRatio))

                  if (newValue > 0) {
                    newCountry.keywords[date][keyword] = newValue
                  } else {
                    newCountry.keywords[date][keyword] = 0
                  }
                } else if (type === 'deviation') {
                  if (getFirstCountryKeywordValue(newCountry, keyword) > Math.abs(parseInt(valueRatio))) {
                    const newValue = (getFirstCountryKeywordValue(newCountry, keyword)) + (Math.floor(Math.random() * (parseInt(valueRatio) * 2)) - parseInt(valueRatio))

                    if (newValue > 1) {
                      newCountry.keywords[date][keyword] = newValue;
                    } else {
                      newCountry.keywords[date][keyword] = 1;
                    }
                  } else {
                    throwToastError('The deviation value must be less than the minimum key value');
                  }
                }
              }
            }
          }
        }
        setRatioModalIsOpen(false);
        setRatioKeyForModal(null)
      } else {
        throwToastError('Please enter a value')
      }
    }
    putEditedCountryInList(newCountry, countryIndex);
  }

  const handleToggleRatioModal = (ratioKey) => {
    setRatioModalIsOpen(!ratioModalIsOpen);
    setRatioKeyForModal(ratioKey);
  }

  const handleFocusInputValue = (e) => {
    e.target.select();
  }

  return (
    <CountryTasksBlock>
      <CountryTasksCheckboxBlock>
        <CountryTasksCheckbox
          type={'checkbox'}
          checked={rowAutofill}
          onChange={() => setRowAutofill(!rowAutofill)}
        />
        Row autofill
      </CountryTasksCheckboxBlock>
      <CountryTableBlock>
        <TableButtonsBlock>
          <TableButtonsRow>
            <TableButton role={'randomAll'} onClick={() => handleToggleRatioModal(null)}/>
            <TableButton role={'clearAll'} onClick={() => handleSetRatio('clearAll', 0, null)}/>
          </TableButtonsRow>
          {checkLengthOfKeywords() && getKeysFromCountry(country)?.map(keyword =>
            <TableButtonsRow key={keyword}>
              <TableButton role={'removeRow'} onClick={() => handleSetRatio('deleteRow', null, keyword)}/>
              <TableButton role={'randomRow'} onClick={() => handleToggleRatioModal(keyword)}/>
              <TableButton role={'clearRow'} onClick={() => handleSetRatio('clearRow', null, keyword)}/>
            </TableButtonsRow>
          )}
        </TableButtonsBlock>
        <KeywordsTable>
          <KeywordsTHead>
            <KeywordsTr>
              <KeywordsTh>Keywords</KeywordsTh>
              {checkLengthOfKeywords() && getDatesListFromCountry(country).map((date) =>
                <KeywordsTh role={'date'} key={date}>{date}</KeywordsTh>
              )}
            </KeywordsTr>
          </KeywordsTHead>
          <KeywordsTBody>
            {checkLengthOfKeywords() && getKeysFromCountry(country)?.map((keyword, rowIndex) => (
              <KeywordsTr key={keyword}>
                <KeywordsTd role={'keyword'}
                            title={keyword}>{keyword === '#install_by_package#' ? 'by package' : keyword}</KeywordsTd>
                {getDatesListFromCountry(country).map((date, dateIndex) =>
                  <KeywordsTd role={'input'} key={date}>
                    <KeywordsTdInput
                      type='text'
                      min={0}
                      value={Object.values(country.keywords[date])[rowIndex]}
                      onFocus={handleFocusInputValue}
                      onChange={(e) => {
                        handleChangeTableInput(e, date, dateIndex, keyword)
                      }}
                    />
                  </KeywordsTd>
                )}
              </KeywordsTr>
            ))}
          </KeywordsTBody>
        </KeywordsTable>

        <Dialog
          onClose={handleToggleRatioModal}
          aria-labelledby="customized-dialog-title"
          open={ratioModalIsOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleToggleRatioModal}>
            Global ratio
          </DialogTitle>
          <DialogContent dividers>
            <NewCampaignRatioModal
              handleSetRatio={handleSetRatio}
              ratioKeyForModal={ratioKeyForModal}
            />
          </DialogContent>
        </Dialog>
      </CountryTableBlock>
    </CountryTasksBlock>
  );
};

export default NewCampaignItemTableBlock;