import * as React from "react";
import API from "../../../Api/API";
import {
  AddForm,
  StyledH1,
  ButtonApply,
  ButtonCancel,
  ButtonWrapper
} from './styled';

const ChangeDefaultTariff = ({reload, close, curTariff}) => {
  const handleSetAsDefault = () => {
      API.changeDefaultTariff(Number(curTariff.id)).then(r => {
        reload(true);
        close();
      })
  }

  const handleClose = () => {
    close();
  }

  return (<>
    <AddForm>
      <StyledH1>Do you really want to set "{curTariff.name}" tariff as default?</StyledH1>
      <ButtonWrapper>
        <ButtonApply onClick={handleSetAsDefault}>Apply</ButtonApply>
        <ButtonCancel onClick={handleClose}>Cancel</ButtonCancel>
      </ButtonWrapper>
    </AddForm>
  </>)
}

export default ChangeDefaultTariff;