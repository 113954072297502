import styled from "styled-components";

export const CountryItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const CountrySelectBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;

  position: relative;

  max-width: 400px;
`;

export const CountryExistText = styled.span`
  position: absolute;
  display: block;
  width: max-content;

  padding-left: 16px;

  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  color: #AF3A3A;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);

`;

export const CountrySelectLabel = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
`;

export const KeywordsContainer = styled.div`
  width: 100%;
  background-color: #D0D0D0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

export const KeywordsAddition = styled.div`
  display: flex;
  gap: 40px;
  padding-left: 5px;
`;

export const KeywordsAdditionSpan = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: right;
`;

export const KeywordsAdditionTextArea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  max-width: 245px;
`;

export const KeywordsAdditionButton = styled.button`
  background-color: #3E3E3E;
  border-radius: 5px;
  text-align: center;
  padding: 5px 21px;
  height: min-content;
  max-width: 200px;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  border: 0;
`;

export const RatesCheckboxesBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RatesCheckboxItem = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RatesCheckboxTitle = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
`;

export const RatesCheckbox = styled.input`
  width: 20px;
  height: 20px;
`;

export const KeywordsTotalBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ClearBtn = styled.button`
  background-color: transparent;
  color: #D27373;
  border: 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  margin-right: 20px;
`;

export const TotalValueContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 250px;
  display: flex;
  justify-content: space-between;
`;

export const TotalValueSpan = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  color: #AF3A3A;
`;

