import * as React from "react";
import {useState} from "react";
import API from "../../../Api/API";
import {
  AddBtn,
  AddForm,
  AddFormWrapper,
  InputLabel,
  StyledCol,
  StyledH1,
  StyledH2,
  StyledInput,
  StyledSelect,
  StyledTextArea,
  EditPartnerWrapper
} from './styled';

const EditUser = ({adminList, curUser, reload, close, tariffs}) => {

  const [group] = useState(localStorage.getItem('group'));
  const [user, setUser] = useState({
    id: curUser.id,
    name: curUser.name,
    limit: curUser.limit,
    tariff: curUser.tariff,
    comment: curUser.comment ? curUser.comment : '',
    install_cost: curUser.install_cost,
    rate_cost: curUser.rate_cost,
    a_install_cost: curUser.a_install_cost,
    a_rate_cost: curUser.a_rate_cost,
    referral: curUser.referral,
    custom_tariff: curUser.custom_tariff,
    cost_relaunch: curUser.cost_relaunch
  });

  const handleChange = (object) => {
    setUser({
      ...user,
      ...object,
    });
  };

  const handleSubmit = () => {
    if (user.name) {
      API.EditClient(user).then(r => {
        reload(true);
        close();
      })
    } else {
      alert('Fill in required fields')
    }
  }

  return (<>
    <AddForm>
      <StyledH1>Edit client</StyledH1>
      <AddFormWrapper>
        <StyledCol>
          <StyledH2>Client info</StyledH2>
          <StyledInput
            value={user.name}
            onChange={(e) => handleChange({name: e.target.value})}/>

          {group === 'superadmin' ?
            <>
              <StyledH2>Client's tariff</StyledH2>
              <StyledSelect onChange={(e) => {
                handleChange({custom_tariff: Number(e.target.value)})
              }}>
                {tariffs.length !== 0 && tariffs.map(item =>
                  <option selected={item.id === user.custom_tariff} value={item.id}>{item.name}</option>
                )}
              </StyledSelect>
            </>
            : ''
          }
          <StyledH2>Min balance</StyledH2>
          <StyledInput
            value={user.limit}
            type='number'
            onChange={(e) => {
              if (e.target.value) {
                handleChange({limit: parseFloat(e.target.value)})
              } else {
                handleChange({limit: null})
              }
            }}/>
          <StyledH2>About client</StyledH2>
          <StyledTextArea
            rows={3}
            value={user.comment}
            onChange={(e) => handleChange({comment: e.target.value})}/>
        </StyledCol>

        <StyledCol>
          <StyledH2>Cost for new client</StyledH2>
          <InputLabel>
            Launch cost
            <StyledInput
              type='number'
              value={user.cost_relaunch}
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({cost_relaunch: parseFloat(e.target.value)})
                } else {
                  handleChange({cost_relaunch: null})
                }
              }}
            />
          </InputLabel>

          <b style={{color: 'rgba(0, 149, 42, 1)'}}>Google play</b>

          <InputLabel>
            Install
            <StyledInput
              type='number'
              value={user.install_cost}
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({install_cost: parseFloat(e.target.value)})
                } else {
                  handleChange({install_cost: null})
                }
              }}/>
          </InputLabel>

          <InputLabel>
            Rate
            <StyledInput
              type='number'
              value={user.rate_cost}
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({rate_cost: parseFloat(e.target.value)})
                } else {
                  handleChange({rate_cost: null})
                }
              }}/>
          </InputLabel>

          <b style={{color: 'rgba(0, 87, 168, 1)'}}>App Store</b>

          <InputLabel>
            Install
            <StyledInput
              type='number'
              value={user.a_install_cost}
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({a_install_cost: parseFloat(e.target.value)})
                } else {
                  handleChange({a_install_cost: null})
                }
              }}/>
          </InputLabel>

          <InputLabel>
            Rate
            <StyledInput
              type='number'
              value={user.a_rate_cost}
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({a_rate_cost: parseFloat(e.target.value)})
                } else {
                  handleChange({a_rate_cost: null})
                }
              }}
            />
          </InputLabel>

        </StyledCol>
      </AddFormWrapper>
      <AddFormWrapper>
        {group === 'superadmin' || group === 'partner' ?
          <EditPartnerWrapper>
            <StyledH2>Partner</StyledH2>
            <StyledSelect onChange={(e) => {
              handleChange({referral: Number(e.target.value)})
            }}>
              {adminList.length !== 0 && adminList.map(item =>
                <option selected={item.id === user.referral * -1} value={item.id * -1}>{item.name}</option>
              )}
            </StyledSelect>
          </EditPartnerWrapper>
          : ''
        }
        {group === 'superadmin' ?
          <EditPartnerWrapper>
            <StyledH2>Partner's tariff</StyledH2>
            <StyledSelect onChange={(e) => {
              handleChange({tariff: Number(e.target.value)})
            }}>
              {tariffs.length !== 0 && tariffs.map(item =>
                <option selected={item.id === user.tariff} value={item.id}>{item.name}</option>
              )}
            </StyledSelect>
          </EditPartnerWrapper>
          : ''
        }
      </AddFormWrapper>
      <AddBtn onClick={handleSubmit}>Edit</AddBtn>
    </AddForm>
  </>)
}

export default EditUser;