import styled from "styled-components";

export const ImportModal = styled.div`
  display: block;
  width: 718px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const ImportHeading = styled.h3`
  font-family: 'Urbanist', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #3E3E3E;
  margin-bottom: 40px;
`;

export const ImportRulesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin: 0 0 16px;
  padding: 0 0 0 20px;
`;

export const ImportRulesListSpan = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  display: block;

  margin-bottom: 16px;
`;

export const ImportRulesItem = styled.li`
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #3E3E3E;
`;

export const ImportModalButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  position: relative;
`;

export const UploadedFileBlock = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;

  padding-right: 44px;
`;

export const ImportModalInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const ImportModalInputLabel = styled.label`
  position: relative;

  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  border-radius: 12px;
  cursor: pointer;
  padding: 16px 32px;
  width: max-content;
  height: min-content;

  color: #ffffff;
  background-color: #3E3E3E;
  border: 0;

  &:hover {
    background-color: #EAEAEA;
    color: #3e3e3e;
  }
`;

export const ImportFileButton = styled.input``