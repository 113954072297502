import {useState} from "react";
import * as React from "react";
import API from "../../../Api/API";
import {AddBtn, AddForm, AddFormWrapper, StyledCol, StyledInput, InputLabel, StyledH1, StyledH2} from './styled';


const EditSupplier = ({curSuplier, reload, close}) => {
    const [supplier, setSupplier] = useState({
        id: curSuplier.id,
        name: curSuplier.name,
        install_cost: curSuplier.install_cost,
        rate_cost: curSuplier.rate_cost,
        a_install_cost: curSuplier.a_install_cost,
        a_rate_cost: curSuplier.a_rate_cost,
    })

    const handleChange = (object) => {
        setSupplier({
            ...supplier,
            ...object,
        });
    };

    const handleSubmit = () => {
        console.log(curSuplier);
        console.log(supplier);
        if (supplier.name !== null && supplier.install_cost !== null && supplier.rate_cost !== null && supplier.a_install_cost !== null && supplier.a_rate_cost !== null) {
            API.EditSupplier(supplier).then(r => {
                reload(true);
                close();
            })
        }
    }

    //TODO МЕТОД ДЛЯ ОТПРАВКИ ОТКОРРЕКТИРОВАННОГО СУПЛАЕРА

    return (<>
        <AddForm>
            <StyledH1>Edit Supplier</StyledH1>
            <AddFormWrapper>
                <StyledCol>
                    <StyledH2>Supplier info</StyledH2>
                    <StyledInput value={supplier.name} onChange={(e) => handleChange({name: e.target.value})} style={{marginTop: "31px"}} />
                </StyledCol>
                <StyledCol>
                    <StyledH2>Cost for new Supplier</StyledH2>
                    <b style={{color: 'rgba(0, 149, 42, 1)'}}>Google play</b>
                    <InputLabel>
                        Install
                        <StyledInput type='number' value={supplier.install_cost} onChange={(e) => handleChange({install_cost: parseFloat(e.target.value)})} />
                    </InputLabel>
                    <InputLabel>
                        Rate
                        <StyledInput type='number' value={supplier.rate_cost} onChange={(e) => handleChange({rate_cost: parseFloat(e.target.value)})} />
                    </InputLabel>
                    <b style={{color: 'rgba(0, 87, 168, 1)'}}>App Store</b>
                    <InputLabel>
                        Install
                        <StyledInput type='number' value={supplier.a_install_cost} onChange={(e) => handleChange({a_install_cost: parseFloat(e.target.value)})} />
                    </InputLabel>
                    <InputLabel>
                        Rate
                        <StyledInput type='number' value={supplier.a_rate_cost} onChange={(e) => handleChange({a_rate_cost: parseFloat(e.target.value)})} />
                    </InputLabel>
                </StyledCol>
            </AddFormWrapper>
            <AddBtn onClick={handleSubmit}>Edit</AddBtn>
        </AddForm>
    </>)
}

export default EditSupplier;