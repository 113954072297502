import React from 'react';
import {KeywordsAddition, KeywordsAdditionButton, KeywordsAdditionSpan} from "../styled";

const PackageAdditionBlock = ({handleRemoveCountry, countryIndex}) => {
  return (
    <KeywordsAddition>
      <KeywordsAdditionSpan>Installs *</KeywordsAdditionSpan>
      <KeywordsAdditionButton onClick={() => handleRemoveCountry(countryIndex)}>Remove
        country</KeywordsAdditionButton>
    </KeywordsAddition>
  );
};

export default PackageAdditionBlock;