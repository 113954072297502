import * as React from "react";
import {useState} from "react";
import API from "../../../Api/API";
import {
  EditBtn,
  AddForm,
  StyledH1,
  StyledInputTitle,
  StyledInput,
  StyledSelect,
  InputWrapper,
  InputGap
} from './styled';

const EditTariff = ({curTariff, reload, close}) => {
  const [tariff, setTariff] = useState({
    id: curTariff.id,
    name: curTariff.name,
    type: curTariff.type,
    percent: curTariff.percent
  });

  const handleChange = (object) => {
    setTariff({
      ...tariff,
      ...object,
    });
  };

  const handleSubmit = () => {
    if (tariff.name) {
      API.editTariff(tariff).then(r => {
        reload(true);
        close();
      })
    } else {
      alert('Fill in required fields')
    }
  }

  return (<>
    <AddForm>
      <StyledH1>Edit tariff</StyledH1>
        <InputGap>
          <InputWrapper>
            <StyledInputTitle>Name</StyledInputTitle>
            <StyledInput
              value={tariff.name}
              placeholder="Enter the name"
              onChange={(e) => handleChange({name: e.target.value})}/>
          </InputWrapper>
          
          <InputWrapper>
            <StyledInputTitle>Type</StyledInputTitle>
            <StyledSelect onChange={(e) => handleChange({type: e.target.value})}>
              <option value="turnover" selected={tariff.type === "turnover"  ? true : false}>Turnover</option>
              <option value="marginality" selected={tariff.type === "marginality"  ? true : false} >Marginality</option>
            </StyledSelect>
          </InputWrapper>

          <InputWrapper>
            <StyledInputTitle>Percent</StyledInputTitle>
            <StyledInput
              value={tariff.percent}
              placeholder="Enter the percent"
              type='number'
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({percent: parseFloat(e.target.value)})
                } else {
                  handleChange({percent: null})
                }
              }}/>
          </InputWrapper>
        </InputGap>
      <EditBtn onClick={handleSubmit}>Edit</EditBtn>
    </AddForm>
  </>)
}

export default EditTariff;