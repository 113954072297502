import React from 'react';
import {
  CheckboxItemDateRow,
  CheckboxItemInput,
  CheckboxItemRowLong,
  CheckboxItemSmall,
  CheckboxItemTitle
} from "../../styled";

const DaysList = ({isDisabled, handleSetSelectedCheckboxesValues, selectedCheckboxesValues}) => {
  return (
    <CheckboxItemDateRow>
      <CheckboxItemRowLong>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            1-st
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            disabled={isDisabled}
            value={'la1day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la1day')}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            2-nd
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la2day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la2day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            3-rd
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la3day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la3day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            4-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la4day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la4day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            5-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la5day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la5day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            6-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la6day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la6day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            7-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la7day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la7day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
      </CheckboxItemRowLong>
      <CheckboxItemRowLong>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            8-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            disabled={isDisabled}
            value={'la8day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la8day')}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            9-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la9day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la9day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            10-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la10day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la10day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            11-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la11day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la11day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            12-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la12day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la12day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            13-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la13day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la13day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
        <CheckboxItemSmall>
          <CheckboxItemTitle disabled={isDisabled}>
            14-th
          </CheckboxItemTitle>
          <CheckboxItemInput
            type='checkbox'
            value={'la14day'}
            checked={!!selectedCheckboxesValues.find(attribute => attribute === 'la14day')}
            disabled={isDisabled}
            onChange={handleSetSelectedCheckboxesValues}
          />
        </CheckboxItemSmall>
      </CheckboxItemRowLong>
    </CheckboxItemDateRow>
  );
};

export default DaysList;