import * as React from 'react';
import {useEffect, useState} from 'react';
import {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import ReloadImg from '../../Images/reload.svg';
import DownloadImg from '../../Images/download.svg';
import CancelImg from '../../Images/cancel.svg';
import TrashImg from '../../Images/trash.svg';
import useDebounce from '../utils/use-debounce';
import API from "../../Api/API";
import {
  PopoverButton,
  PopoverContent,
  SettingsButton,
  StatusItem,
  StoreIcon,
  StyledHeading,
  StyledHeadTr,
  StyledPage,
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  TableBlock,
} from './styled';
import {Dialog, DialogContent, DialogTitle, Popover} from '@mui/material';
import {Loader} from 'rsuite';
import en from "date-fns/locale/en-GB";
import SettingsModal from "./SettingsModal";
import {throwToastError} from "../utils";
import OrdersFilterModule from "./Modules/OrdersFilterModule";
import OrdersStatusModule from "./Modules/OrdersStatusModule";
import ConfirmModal from "./Modals/ConfirmModal";
import {ORDERS_STATUSES} from "./Constants/CampaignsStatuses";

let isEnded = false

const Campaigns = () => {
  const [list, setList] = useState([]);
  const [appsList, setAppsList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [selectedApp, setSelectedApp] = useState('');
  const [client, setClient] = useState(0);
  const [status, setStatus] = useState(0);
  const [dateStart, setDateStart] = useState(null);
  const [dateStop, setDateStop] = useState(null);
  const [fetching, setFetching] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(0);
  const [loading, setLoading] = useState(true);

  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const debouncedDateStart = useDebounce(dateStart, 1000);
  const debouncedDateStop = useDebounce(dateStop, 1000);

  const [openArchiveConfirm, setOpenArchiveConfirm] = useState(false);
  const [archiveRowId, setArchiveRowId] = useState(null);

  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [cancelRowId, setCancelRowId] = useState(null);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [enabledTableColumns, setEnabledTableColumns] = useState({
    id: true,
    clientId: true,
    supplierId: true,
    application: true,
    geo: true,
    type: true,
    count: true,
    roi: true,
    start: true,
    end: true,
    days: true,
    status: true,
  })

  registerLocale("en", en);

  useEffect(() => {
    const storageApp = localStorage.getItem('campaignAppFilter');
    !!storageApp && setSelectedApp(storageApp);

    const storageClient = localStorage.getItem('campaignClientFilter');
    !!storageClient && setClient(Number(storageClient));

    const storageDateStart = localStorage.getItem('campaignDateStartFilter');
    !!storageDateStart && setDateStart(new Date(storageDateStart))

    const storageDateStop = localStorage.getItem('campaignDateStopFilter');
    !!storageDateStop && setDateStop(new Date(storageDateStop))

    if (!storageApp && !storageClient) {
      setFetching(true)
    }
    if (localStorage.getItem('CampaignsColumns')) {
      setEnabledTableColumns(JSON.parse(localStorage.getItem('CampaignsColumns')))
    }
  }, []);

  useEffect(() => {
    if (fetching) {
      const curDateStart = dateStart ? moment(dateStart).format('YYYY-MM-D') : 0;
      const curDateStop = dateStop ? moment(dateStop).format('YYYY-MM-D') : 0;

      API.GetCampaigns(offset, selectedApp, client, status, curDateStart, curDateStop, 20)
        .then((data) => {
          setList([...list, ...data.data]);
          setOffset(prevState => prevState + 20);
          if (data.data.length !== 20) {
            isEnded = true
          }
        })
        .finally(() => {
          setLoading(false)
          setFetching(false)
        })
    }
  }, [fetching])

  useEffect(() => {
    if (client !== 0 || selectedApp !== 0 || status !== 0 || status === 0 || debouncedDateStart !== 0 || debouncedDateStop !== 0) {
      isEnded = false;
      setList([]);
      setOffset(0)
      setFetching(true)
    }
  }, [selectedApp, client, status, debouncedDateStart, debouncedDateStop])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    API.GetApps(0, 999, client, '').then((data) => {
      setAppsList(data.data)
    })
    return function () {
      document.removeEventListener('scroll', scrollHandler);
    }
  }, [])

  const scrollHandler = (e) => {
    if ((e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) && !isEnded) {
      setFetching(true);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  // const isOneDayDifference = (date1, date2) => {
  //   const diff = new Date(date2).getTime() - new Date(new Date(date1).toISOString().slice(0, 10)).getTime();
  //   return diff <= 86400000 * 2;
  // }

  const handleChangeSelectedApp = (value) => {
    setSelectedApp(value)
    localStorage.setItem('campaignAppFilter', value);
  }
  const handleChangeSelectedClient = (value) => {
    setClient(value)
    localStorage.setItem('campaignClientFilter', value);
  }

  const handleChangeStartDate = (date) => {
    setDateStart(date)
    localStorage.setItem('campaignDateStartFilter', date);
  }

  const handleChangeStopDate = (date) => {
    setDateStop(date)
    localStorage.setItem('campaignDateStopFilter', date);
  }

  const handleClearStartDate = () => {
    setDateStart(0)
    localStorage.removeItem('campaignDateStartFilter');
  }

  const handleClearEndDate = () => {
    setDateStop(0)
    localStorage.removeItem('campaignDateStopFilter');
  }

  const handleOpenSettingsModal = () => {
    setOpenSettingsModal(true);
  }

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false)
  }

  const handleSaveColumnsList = (obj) => {
    setEnabledTableColumns(obj);
    localStorage.setItem('CampaignsColumns', JSON.stringify(obj))
    handleCloseSettingsModal(false)
  }

  const handleSetStatus = (newStatus) => {
    setStatus(newStatus)
  }

  const handleOpenArchiveConfirm = (status, rowId) => {
    if (status !== 2 && status !== 1 && status !== 7) {
      setArchiveRowId(rowId)
      setOpenArchiveConfirm(true)
    } else {
      throwToastError('You can\'t archive an active campaign')
    }
  }

  const handleCloseArchiveConfirm = () => {
    setArchiveRowId(null)
    setOpenArchiveConfirm(false)
  }

  const handleArchiveCampaign = (id) => {
    setConfirmLoading(true)
    API.archiveCompany({"campaign_id": id}).then(r => {
      if (r.status === 200) {
        setList([]);
        setOffset(0)
        setFetching(true)
      } else {
        throwToastError('Unknown error, try again')
      }
      handleCloseArchiveConfirm()
      setConfirmLoading(false)
    })
  }

  const handleOpenCancelConfirm = (status, rowId) => {
    if (status === 2 || status === 1 || status === 15 || status === 7) {
      setCancelRowId(rowId)
      setOpenCancelConfirm(true)
    } else {
      throwToastError('Only active or prepared campaigns can be canceled')
    }
  }

  const handleCloseCancelConfirm = () => {
    setCancelRowId(null);
    setOpenCancelConfirm(false)
  }

  const handleCancelCampaign = (id) => {
    setConfirmLoading(true)
    API.returnMoney({"campaign_id": id}).then(r => {
      if (r.status === 200) {
        setList([]);
        setOffset(0);
        setFetching(true)
      } else {
        throwToastError('Unknown error, try again')
      }
      handleCloseCancelConfirm()
      setConfirmLoading(false)
    })
  }

  const currentTypes = {
    'install': 'Install',
    'rate': 'Rate',
    'install_bp': 'Package'
  }

  return (
    <>
      <StyledPage>
        <StyledHeading>Campaigns</StyledHeading>
        <OrdersFilterModule
          client={client}
          selectedApp={selectedApp}
          dateStart={dateStart}
          dateStop={dateStop}
          handleChangeSelectedApp={handleChangeSelectedApp}
          handleChangeSelectedClient={handleChangeSelectedClient}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeStopDate={handleChangeStopDate}
          handleClearStartDate={handleClearStartDate}
          handleClearEndDate={handleClearEndDate}
        />
        {loading ? <Loader backdrop size="lg" content="loading..." vertical/> :
          <TableBlock>
            <OrdersStatusModule
              handleSetStatus={handleSetStatus}
              selectedStatus={status}
            />
            <StyledTable>
              <StyledThead>
                <StyledHeadTr>
                  {enabledTableColumns.id && <StyledTh>ID</StyledTh>}
                  {enabledTableColumns.clientId && <StyledTh>Client ID</StyledTh>}
                  {enabledTableColumns.supplierId && <StyledTh>Suppliers ID</StyledTh>}
                  {enabledTableColumns.application && <StyledTh appName>Application</StyledTh>}
                  {enabledTableColumns.geo && <StyledTh>GEO</StyledTh>}
                  {enabledTableColumns.type && <StyledTh>Type</StyledTh>}
                  {enabledTableColumns.count && <StyledTh>Count</StyledTh>}
                  {enabledTableColumns.roi && <StyledTh>ROI</StyledTh>}
                  {enabledTableColumns.start && <StyledTh>Start</StyledTh>}
                  {enabledTableColumns.end && <StyledTh>End</StyledTh>}
                  {enabledTableColumns.days && <StyledTh>Days</StyledTh>}
                  {enabledTableColumns.status && <StyledTh>Status</StyledTh>}
                  <StyledTh></StyledTh>
                  <StyledTh></StyledTh>
                  <StyledTh></StyledTh>
                  <StyledTh><SettingsButton onClick={handleOpenSettingsModal}/></StyledTh>
                </StyledHeadTr>
              </StyledThead>
              <StyledTbody>
                {list.map((row) => (
                  <StyledTr key={row.id}>
                    {enabledTableColumns.id && <StyledTd>{row.id}</StyledTd>}
                    {enabledTableColumns.clientId && <StyledTd>{row.clientId}</StyledTd>}
                    {enabledTableColumns.supplierId && <StyledTd>{row.supplierId}</StyledTd>}
                    {enabledTableColumns.application &&
                      <StyledTd title={row.appName} appName>
                        <div>
                          <StoreIcon
                            store={row.storeId?.includes('.') ? 'google' : 'apple'}/>
                          -
                          <img src={row.appIcon} height={15} width={15} alt=""/>
                          -
                          {/*{Object.values(enabledTableColumns).filter(value => value === true).length > 5 ?*/}
                          {/*  row.appName.length > 10 ? `${row.appName.slice(0, 10)}...` :*/}
                          {/*    row.appName : row.appName.slice(0, 30)}*/}
                          {}
                          {row.appName}
                        </div>
                      </StyledTd>
                    }
                    {enabledTableColumns.geo && <StyledTd
                      title={row.geo}>{row?.geo?.length > 10 ? `${row.geo.slice(0, 10)}...` : row.geo}</StyledTd>}
                    {enabledTableColumns.type && <StyledTd>{currentTypes[row.type]}</StyledTd>}
                    {enabledTableColumns.count && <StyledTd>{row.count}</StyledTd>}
                    {enabledTableColumns.roi &&
                      <StyledTd title={row.ROI}>{row.ROI.split("=")[1].trim()}</StyledTd>}
                    {enabledTableColumns.start && <StyledTd>{row.startTime}</StyledTd>}
                    {enabledTableColumns.end && <StyledTd>{row.endTime}</StyledTd>}
                    {enabledTableColumns.days && <StyledTd>{row.days}</StyledTd>}
                    {enabledTableColumns.status &&
                      <StyledTd>
                        <StatusItem data-color={row.status}>
                          {ORDERS_STATUSES.find(status => status.number === row.status) ?
                            ORDERS_STATUSES.find(status => status.number === row.status).title :
                            'Special'
                          }
                        </StatusItem>
                      </StyledTd>
                    }
                    <StyledTd component="th" scope="row">
                      <img aria-describedby={popoverId} style={{cursor: "pointer"}} src={ReloadImg}
                           alt="" onClick={(event) => {
                        if (row.appName.includes('banned')) {
                          throwToastError('This app has been banned')
                          return
                        }
                        if (appsList.find(app => app.id === row.appId)) {
                          setAnchorEl(event.currentTarget);
                          setCurrentRow(row.id);
                        } else {
                          throwToastError('This app has been removed.')
                        }
                      }
                      }/>
                      <Popover
                        id={popoverId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <PopoverContent>
                          <PopoverButton
                            onClick={() => {
                              if (row) {
                                window.location.href = `/NewCampaign/extend/${currentRow}`
                              }
                            }}
                          >Extend Campaign</PopoverButton>
                          <PopoverButton
                            onClick={() => {
                              window.location.href = `/NewCampaign/duplicate/${currentRow}`
                            }}
                          >Duplicate campaign</PopoverButton>
                        </PopoverContent>
                      </Popover>
                    </StyledTd>
                    <StyledTd>
                      <img src={DownloadImg} alt=""
                           style={{cursor: "pointer"}}
                           onClick={() => {
                             window.open(API.ApiUrl + '/xlsx/' + row.id + '.xlsx', '_blank');
                           }}/>
                    </StyledTd>
                    <StyledTd>
                      <img src={CancelImg} alt=""
                           style={{cursor: "pointer"}}
                           onClick={() => handleOpenCancelConfirm(row.status, row.id)}
                      />
                    </StyledTd>
                    <StyledTd>
                      <img src={TrashImg} alt=""
                           style={{cursor: "pointer"}}
                           onClick={() => handleOpenArchiveConfirm(row.status, row.id)}
                      />
                    </StyledTd>

                  </StyledTr>
                ))}
              </StyledTbody>
            </StyledTable>
          </TableBlock>
        }
        <Dialog open={openSettingsModal} onClose={handleCloseSettingsModal}>
          <DialogTitle>Columns settings</DialogTitle>
          <DialogContent>
            <SettingsModal
              enabledColumns={enabledTableColumns}
              handleSave={handleSaveColumnsList}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={openCancelConfirm} onClose={handleCloseCancelConfirm}>
          <DialogContent>
            <ConfirmModal
              confirmLoading={confirmLoading}
              campaignId={cancelRowId}
              onClose={handleCloseCancelConfirm}
              onSubmit={handleCancelCampaign}
              actionText={`Are you sure you want to cancel the campaign ${cancelRowId}?`}
              actionTitle={'Cancel campaign'}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={openArchiveConfirm} onClose={handleCloseArchiveConfirm}>
          <DialogContent>
            <ConfirmModal
              confirmLoading={confirmLoading}
              campaignId={archiveRowId}
              onClose={handleCloseArchiveConfirm}
              onSubmit={handleArchiveCampaign}
              actionText={`Are you sure you want to archive the campaign ${archiveRowId}?`}
              actionTitle={'Archive campaign'}
            />
          </DialogContent>
        </Dialog>
      </StyledPage>
    </>
  )
}
export default Campaigns;