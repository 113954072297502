import styled from "styled-components";
import chartSquarePurple from "../../../../Images/icons/chart-square-purple.svg";
import noteRemoveRed from "../../../../Images/icons/note-remove-red.svg";
import minusSquare from "../../../../Images/icons/minus-square.svg";
import chartSquare from "../../../../Images/icons/chart-square.svg";
import noteRemove from "../../../../Images/icons/note-remove.svg";

export const CountryTasksBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CountryTasksCheckboxBlock = styled.label`
  display: flex;
  gap: 10px;
  font-size: 18px;
  align-items: center;
  margin-left: 30px;
`;

export const CountryTasksCheckbox = styled.input`
  width: 20px;
  height: 20px;
`;

export const CountryTableBlock = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  margin-left: 30px;
`;

export const TableButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 90px;
`;

export const TableButtonsRow = styled.div`
  display: flex;
  gap: 15px;
  padding: 11px 0;
`;

export const TableButton = styled.button`
  width: 20px;
  height: 20px;
  border: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #d0d0d0;
  cursor: pointer;
  background-image: url(${props => props.role === 'randomAll' ? chartSquarePurple :
          props.role === 'clearAll' ? noteRemoveRed :
                  props.role === 'removeRow' ? minusSquare :
                          props.role === 'randomRow' ? chartSquare : noteRemove

  });
`;

export const KeywordsTable = styled.table`
  overflow: auto;
  white-space: nowrap;
`;

export const KeywordsTHead = styled.thead`

`;

export const KeywordsTBody = styled.tbody``;

export const KeywordsTr = styled.tr`

`;

export const KeywordsTh = styled.th`
  min-width: 135px;
  min-height: 40px;
  width: ${props => props.role !== 'date' ? 'max-content' : '135px'};
  //display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.role === 'date' ? '#3E3E3E' : '#ffffff'};
  position: ${props => props.role === 'date' ? 'relative' : 'sticky'};
  left: 0;
  z-index: ${props => props.role === 'date' ? '1' : '5'};

  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: ${props => props.role === 'date' ? '#ffffff' : '#3e3e3e'};
  box-sizing: border-box;
  border-right: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  padding: 7px 0;
`;

export const KeywordsTd = styled.td`
  white-space: nowrap;
  overflow: hidden;
  width: ${props => props.role === 'keyword' ? 'max-content' : '135px'};
  min-width: 135px;
  min-height: 40px;
  //display: inline-flex;
  align-items: center;
  justify-content: center;
  position: ${props => props.role === 'keyword' ? 'sticky' : 'relative'};
  left: 0;
  text-align: center;
  z-index: ${props => props.role === 'keyword' ? '5' : '1'};
  background-color: ${props => props.role === 'keyword' ? '#dedede' : '#3E3E3E'};
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: ${props => props.role === 'date' ? '#ffffff' : '#3e3e3e'};
  box-sizing: border-box;
  padding: 0 10px;
  border-right: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
`;

export const KeywordsTdInput = styled.input`
  width: 100%;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 2px;
  text-align: center;
`;