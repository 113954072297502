import {React, useState, useEffect} from 'react';
import {AppIcon, AppItem, AppSpan, AppsList, AppsSearchBlock, ClearBtn, SearchInput} from './styled';
import API from '../../../Api/API';
import useDebounce from '../../utils/use-debounce';
import {throwToastError} from "../../utils";

const AppsSearchSelect = ({client, handleSelectApp, size, selectedApp, selectorType}) => {
  const [appList, setAppList] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [searchApp, setSearchApp] = useState('');

  const debouncedSearch = useDebounce(searchApp, 500);

  useEffect(() => {
    if (selectedApp && appList && !selectorType) {
      setSearchApp(appList.find((app) => Number(app.id) === Number(selectedApp))?.name)
    } else if (selectedApp && appList && selectorType === 'storeId') {
      setSearchApp(appList.find((app) => app.appid === selectedApp)?.name)
    }
  }, [appList])

  useEffect(() => {
    API.GetApps(0, 999, client, '').then((data) => {
      const newApps = data.data;

      const uniqueList = Array.from(new Set(newApps.map(obj => obj.appid)))
        .map(appid => newApps.find(obj => obj.appid === appid));

      setAppList(uniqueList)
    }).catch((reason) => throwToastError(reason));
  }, [client])

  useEffect(() => {
    setAppList([]);
    API.GetApps(0, 999, client, searchApp).then((data) => {
      const newApps = data.data;

      const uniqueList = Array.from(new Set(newApps.map(obj => obj.appid)))
        .map(appid => newApps.find(obj => obj.appid === appid));

      setAppList(uniqueList)
    }).catch((reason) => throwToastError(reason))

    if (debouncedSearch === '' && selectedApp) {
      handleSelectApp(0)
    }
  }, [debouncedSearch])

  const handleOpenAppsList = () => {
    setOpenList(true);
  }

  const handleCloseAppsList = () => {
    setOpenList(false);
  }

  const handleSearchInputChange = (e) => {
    setSearchApp(e.target.value);
  }

  const handleClearInput = () => {
    setSearchApp('');
    handleSelectApp(0);
  }

  return (
    <AppsSearchBlock>
      <SearchInput type='text'
                   onFocus={handleOpenAppsList}
                   onBlur={handleCloseAppsList}
                   onChange={handleSearchInputChange}
                   value={searchApp}
                   placeholder='Choose app'
                   size={size}
      />
      {searchApp && <ClearBtn onClick={handleClearInput}>✖</ClearBtn>}
      <AppsList open={openList}>
        {appList.map((app) => (
          <AppItem
            key={app.id}
            onMouseDown={() => {
              if (selectorType === 'storeId') {
                handleSelectApp(app.appid);
              } else {
                handleSelectApp(app.id);
              }
              setSearchApp(app.name);
            }}
          >
            <AppIcon src={app.icon}></AppIcon>
            <AppSpan>{app.name}</AppSpan>
          </AppItem>
        ))}
      </AppsList>
    </AppsSearchBlock>
  );
}

export default AppsSearchSelect;
