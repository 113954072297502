import React, {useState, forwardRef, useEffect} from 'react';
import {
  ControlButtons,
  DateSelector,
  ExportButton,
  ModalWrapper,
  StyledDataPicker,
  StyledTable,
  TableBlock,
  DateFilterBlock,
  DateInput,
  StyledHeadTr,
  StyledTh,
  StyledThead,
  StyledTbody, StyledTr, StyledTd, StyledKeyTd
} from "./styled";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import API from "../../../Api/API";
import {utils, writeFile} from "xlsx";

const StatisticModal = ({app}) => {
  const [dateStart, setDateStart] = useState(getOneWeekAgo(new Date()));
  const [dateStop, setDateStop] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [statResponse, setStatResponse] = useState([]);

  const [statDates, setStatDates] = useState([]);

  const ExampleCustomInput = forwardRef(({value, onClick, placeholder}, ref) => (
    <StyledDataPicker onClick={onClick} ref={ref} value={value} placeholder={placeholder} readOnly />
  ));

  useEffect(() => {
    if (dateStart && dateStop) {
      API.getAppStatistic(app.id, moment(dateStart).format("YYYY-MM-DD"), moment(getNextDay(dateStop)).format("YYYY-MM-DD")).then((result) => {
          setStatResponse(result.data)
        }
      )
    }
  }, [dateStart, dateStop])

  useEffect(() => {
    if (statResponse.length > 0) {
      const dateList = Object.keys(statResponse[0]).filter(key => key !== 'key');

      setStatDates(dateList);
    }
  } ,[statResponse])

  function getOneWeekAgo(date) {
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 7);
    return moment(previousDate).format("YYYY-MM-DD");
  }

  const getNextDay = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(new Date(date).getDate() + 1);
    return nextDate;
  }

  const exportFile = () => {
    const headerRow = ["Keys", ...Object.keys(statResponse[0]).filter((key) => key !== "key")]
    const worksheetData = [headerRow];
    statResponse.forEach(obj => {
      const row = [obj.key];
      Object.keys(obj).map((key) => {
        if (key !== "key") {
          row.push(obj[key]);
        }
      })
      worksheetData.push(row);
    })

    const ws = utils.aoa_to_sheet(worksheetData);
    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, `Data`);

    writeFile(wb, `statistic_report_${app.name.replace(/ /g, "_")}_${moment(dateStart).format("YYYY-MM-DD")}_-_${moment(dateStop).format("YYYY-MM-DD")}.xlsx`);
  }

  return (
    <ModalWrapper>
      <ControlButtons>
        <DateSelector>
          <DateFilterBlock>
            <DateInput>
              <DatePicker
                onChange={(date) => {
                  setDateStart(date);
                }}
                selected={new Date(dateStart)}
                customInput={<ExampleCustomInput />}
                placeholderText={'Start date'}
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                maxDate={new Date(dateStop)}
              />
            </DateInput>
            <DateInput>
              <DatePicker
                onChange={(date) => {
                  setDateStop(date);
                }}
                selected={new Date(dateStop)}
                customInput={<ExampleCustomInput />}
                placeholderText={'Stop date'}
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                maxDate={getNextDay(new Date())}
                minDate={new Date(dateStart)}
              />
            </DateInput>
          </DateFilterBlock>
        </DateSelector>
        <ExportButton onClick={exportFile}>Export XLSX</ExportButton>
      </ControlButtons>
      <TableBlock>
        {statResponse?.length > 0 &&
          <StyledTable>
            <StyledThead>
              <StyledHeadTr>
                <StyledTh>keys</StyledTh>
                {statDates?.map(date => (
                  <StyledTh key={date}>{date}</StyledTh>
                ))}
              </StyledHeadTr>
            </StyledThead>
            <StyledTbody>
              {statResponse?.map(stat => (
                <StyledTr key={stat.key}>
                  <StyledKeyTd>{stat.key}</StyledKeyTd>
                  {statDates?.map(date => (
                    <StyledTd key={stat.key + date}>{stat[date]}</StyledTd>
                  )) }
                </StyledTr>
              ))}
            </StyledTbody>
          </StyledTable>
        }
      </TableBlock>
    </ModalWrapper>
  );
};

export default StatisticModal;