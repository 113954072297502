export const checkboxesAttributes = {
  distribute: 1 << 0,
  consoleVisible: 1 << 1,
  la1day: 1 << 2,
  la2day: 1 << 3,
  la3day: 1 << 4,
  la4day: 1 << 5,
  la5day: 1 << 6,
  la6day: 1 << 7,
  la7day: 1 << 8,
  la8day: 1 << 9,
  la9day: 1 << 10,
  la10day: 1 << 11,
  la11day: 1 << 12,
  la12day: 1 << 13,
  la13day: 1 << 14,
  la14day: 1 << 15,
  day: 1 << 16,
};