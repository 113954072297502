import React, {useCallback, useEffect, useState} from 'react';
import {CheckboxItemTimeBlock, TimeZoneSelect} from "../../styled";
import DatePicker from "react-datepicker";
import {throwToastError} from "../../../../../utils";

const DelayedStart = ({selectedDelayStart, handleSelectDelayStartTime, isEnabled, selectedDateStart, scenario}) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedOffset, setSelectedOffset] = useState(0); // 0 | string

  useEffect(() => {
    if (!isEnabled && (scenario !== 'extend')) {
      setSelectedOffset(0);
      setSelectedTime(null);
      handleSelectDelayStartTime(null);
    }
  }, [handleSelectDelayStartTime, isEnabled]);

  const getParsedTime = useCallback((timeString) => {
    const [timeValue, offsetValue] = timeString.split(' ');
    const [timeHours, timeMinutes] = timeValue.split(':');
    const offsetHours = offsetValue?.split(':')[0];

    if (offsetHours) {
      setSelectedOffset(offsetHours)

      if (selectedDateStart) {
        setSelectedTime(new Date(selectedDateStart.setHours(Number(timeHours), Number(timeMinutes))));
      } else {
        throwToastError('Choose start day date')
      }
    }
  }, [selectedDateStart])

  useEffect(() => {
    if (selectedDelayStart) {
      getParsedTime(selectedDelayStart)
    } else {
      setSelectedTime(null);
      setSelectedOffset(0);
    }
  }, [getParsedTime, selectedDelayStart])

  const getTimezoneOffsets = useCallback(() => {
    const gmtOffsets = ['-12', '-11', '-10', '-09', '-08', '-07', '-06', '-05', '-04', '-03', '-02', '-01', '+00', '+01', '+02', '+03', '+04', '+05', '+06', '+07', '+08', '+09', '+10', '+11', '+12'];

    return gmtOffsets.map(offset => ({
      timezone: `GMT${offset}:00`,
      offset
    }))
  }, [])

  const timezoneOffsets = getTimezoneOffsets();

  const getTimeStringForState = useCallback((timeValue, offsetValue) => {
    if (timeValue) {
      const hours = timeValue.getHours().toString().padStart(2, '0');
      const minutes = timeValue.getMinutes().toString().padStart(2, '0');

      return `${hours}:${minutes} ${offsetValue}:00`;
    }
  }, [])

  const handleSelectTimezone = (e) => {
    setSelectedOffset(e.target.value);
    setSelectedTime(null);
    handleSelectDelayStartTime(null);
  }

  const handleSelectCurrentTime = (date) => {
    if (date && selectedOffset) {
      handleSelectDelayStartTime(getTimeStringForState(date, selectedOffset))
    }
  }

  const getMinTimeValue = (dateStart) => {
    const currentDate = new Date();
    const currentOffset = currentDate.getTimezoneOffset() * 60 * 1000;

    const currentUTCTime = new Date(currentDate.getTime() + currentOffset);

    if (dateStart && new Date(dateStart.setHours(0, 0, 0, 0)).getTime() <= new Date(currentDate.setHours(0, 0, 0, 0)).getTime()) {
      if (selectedOffset) {
        return new Date(currentUTCTime.getTime() + (selectedOffset * 60 * 60 * 1000))
      } else {
        return new Date(currentDate.setHours(0, 0, 0, 0))
      }
    } else {
      return new Date(currentDate.setHours(0, 0, 0, 0))
    }
  }

  const getMaxTimeValue = () => {
    return new Date(new Date().setHours(23, 59, 0, 0));
  }

  return (
    <>
      <TimeZoneSelect onChange={handleSelectTimezone} value={selectedOffset} disabled={!isEnabled}>
        <option value={0}>Select timezone</option>
        {timezoneOffsets.map((timezone) =>
          <option value={timezone.offset} key={timezone.timezone}>{timezone.timezone}</option>
        )}
      </TimeZoneSelect>
      <CheckboxItemTimeBlock>
        <DatePicker
          minTime={getMinTimeValue(selectedDateStart)}
          maxTime={getMaxTimeValue()}
          placeholderText={'select offset'}
          disabled={!selectedOffset || !isEnabled}
          selected={selectedTime}
          onChange={handleSelectCurrentTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
      </CheckboxItemTimeBlock>
    </>
  );
};

export default DelayedStart;