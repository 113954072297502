import styled from "styled-components";

export const CountriesBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 25px 35px;
`;

export const CountriesList = styled.ul`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px 0;
`;

export const CountriesAddButton = styled.button`
  background-color: #3e3e3e;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  padding: 15px 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  max-width: 245px;
  width: 100%;
  margin: 0 0 40px 155px;
`;