import {ClearBtn, OptionSelect, OptionTitle, StyledOptionItem} from './styled';

const OptionItem = ({title, handle, optionList, value, setDisabled, cleanable}) => {
  const handleClear = () => {
    handle(0)
  }

  return (
    <StyledOptionItem>
      <OptionTitle>{title}</OptionTitle>
      <OptionSelect
        onChange={(e) => {
          handle(e.target.value);
        }}
        value={value}
        disabled={setDisabled}
      >
        <option value={0} disabled>{title}</option>
        {optionList?.map((x) => {
          return (<option value={x.id} key={x.id} disabled={x.name.includes('banned')}>{x.name}</option>);
        })}
      </OptionSelect>
      {cleanable && Number(value) !== 0 && <ClearBtn onClick={handleClear}>✖</ClearBtn>}
    </StyledOptionItem>
  );
}

export default OptionItem;
