import styled from 'styled-components';

export const AddForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 54px 74px;
`;

export const StyledH1 = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #3E3E3E;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ButtonApply = styled.button`
  padding: 10px 20px;
  background-color: #73D27C;
  color: #000000;
  border-radius: 8px;
`;

export const ButtonCancel = styled.button`
  padding: 10px 20px;
  background-color: #D27373;
  color: #FFFFFF;
  border-radius: 8px;
`;
