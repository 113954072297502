import React, {useState} from 'react';
import {ColCheckBox, ColLabel, ColList, ModalContent, SaveButton} from "./styled";

const SettingsModal = ({enabledColumns, handleSave}) => {
  const [selectedColumns, setSelectedColumns] = useState(enabledColumns);

  return (
    <ModalContent>
      <ColList>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={Object.values(selectedColumns)?.every(value => value === true)}
            onChange={(e) => {
              let newObj = {};
              Object.keys(selectedColumns)?.map((col) => {
                newObj[col] = e.target.checked
              })
              setSelectedColumns(newObj)
            }}/>
          All columns
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.id}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, id: e.target.checked})
            }}/>
          ID
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.clientId}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, clientId: e.target.checked})
            }}/>
          Client ID
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.supplierId}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, supplierId: e.target.checked})
            }}/>
          Supplier ID
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.application}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, application: e.target.checked})
            }}/>
          Application
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.geo}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, geo: e.target.checked})
            }}/>
          GEO
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.type}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, type: e.target.checked})
            }}/>
          Type
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.count}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, count: e.target.checked})
            }}/>
          Count
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.roi}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, roi: e.target.checked})
            }}/>
          ROI
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.start}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, start: e.target.checked})
            }}/>
          Start
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.end}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, end: e.target.checked})
            }}/>
          End
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.days}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, days: e.target.checked})
            }}/>
          Days
        </ColLabel>
        <ColLabel>
          <ColCheckBox
            type='checkbox'
            checked={selectedColumns.status}
            onChange={(e) => {
              setSelectedColumns({...selectedColumns, status: e.target.checked})
            }}/>
          Status
        </ColLabel>
      </ColList>
      <SaveButton onClick={() => {
        handleSave(selectedColumns)
      }}>Save</SaveButton>
    </ModalContent>
  );
};

export default SettingsModal;