import * as React from 'react';
import {DialogContent, Dialog} from '@mui/material';
import {Loader} from 'rsuite';

import SearchIcon from '../../Images/icons/search-icon.svg';
import EditRowIcon from '../../Images/icons/edit-row-icon.svg';
import DeleteIcon from '../../Images/trash.svg';
import {useEffect, useState} from "react";

import API from "../../Api/API";
import NewTariff from "./add-new-tariff/new-tariff";
import EditTariff from "./edit-tariff/edit-tariff";
import ChangeDefaultTariff from "./change-default-tariff/change-default-tariff";
import {
  ControlBlock,
  ControlButton,
  ControlButtons,
  PageContainer,
  PageHeading,
  PageWrapper,
  SearchBlock,
  SearchInput, StyledHeadTr, StyledTable, StyledTbody, StyledTh, StyledThead, StyledTr, TableBlock, SetDefaultButton
} from './styled';
import useDebounce from '../utils/use-debounce';

const Tariffs = () => {
  const group = localStorage.getItem('group');

  if (group !== 'superadmin') {
    window.location.href = 'https://autoreports.teracock.com/';
  }

  const [List, setList] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openChangeDefault, setOpenChangeDefault] = useState(false);
  const [curTariff, setCurTariff] = useState();
  const [loading, setLoading] = useState(true);
  const [clientsSearch, setClientsSearch] = useState();

  const debouncedSearch = useDebounce(clientsSearch, 500)

  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const handleClose = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setOpenChangeDefault(false);
  };
  useEffect(() => {
    API.getAllTariffs(0, 100, '').then((data) => {
      setList(data.data);
      setLoading(false);
    })
  }, [])

  useEffect(() => {
    if (debouncedSearch !== undefined) {
      API.getAllTariffs(0, 100, debouncedSearch).then((data) => {
        if (data) {
          setList(data.data)
          setLoading(false)
        }
      })
    }
  }, [debouncedSearch])

  const handleRestartTable = () => {
    setLoading(true)
    API.getAllTariffs(0, 100, '').then((data) => {
      if (data) {
        setList(data.data)
        setLoading(false);
      }
    })
  }

  const handleDeleteTariff = (id) => {
    API.deleteTariff(Number(id)).then(() => {
      handleRestartTable();
    })
  }

  const handleSearch = (e) => {
    setClientsSearch(e.target.value);
  }

  return (
    <>
      {loading ? <Loader backdrop size="lg" content="loading..." vertical/> :
        <>
          <PageContainer>
            <PageWrapper>
              <PageHeading>Tariffs</PageHeading>
              <ControlBlock>
                <SearchBlock>
                  <img src={SearchIcon} alt="" />
                  <SearchInput onChange={handleSearch} placeholder={'Search tariff'}/>
                </SearchBlock>
                <ControlButtons>
                  <ControlButton onClick={handleClickOpen}>Add tariff</ControlButton>
                </ControlButtons>
              </ControlBlock>
              <TableBlock>
                <StyledTable>
                  <StyledThead>
                    <StyledHeadTr>
                      <div>
                        <StyledTh>Tariffs ID</StyledTh>
                        <StyledTh>Name</StyledTh>
                        <StyledTh>Type</StyledTh>
                        <StyledTh>Percent</StyledTh>
                      </div>
                      <div>
                        <StyledTh></StyledTh>
                        <StyledTh></StyledTh>
                        <StyledTh></StyledTh>
                      </div>
                    </StyledHeadTr>
                  </StyledThead>
                  <StyledTbody>
                    {List.length !== 0 &&
                      List.map(tariff =>
                        <StyledTr style={tariff.default !==1 ?  {backgroundColor: ''} : {backgroundColor: '#F8F8F8'}} key={tariff.id}>
                          <div>
                            <StyledTh>{tariff.id}</StyledTh>
                            <StyledTh>{tariff.default !== 1 ? tariff.name : <span style={{color: '#2E0CFF'}}>{tariff.name}</span>}</StyledTh>
                            <StyledTh>{tariff.type}</StyledTh>
                            <StyledTh>{tariff.percent} %</StyledTh>
                          </div>
                          <div>
                            <StyledTh>{tariff.default !== 1 ?  <SetDefaultButton onClick={() => {setOpenChangeDefault(true); setCurTariff(tariff);}}>Set as default</SetDefaultButton> : ''}</StyledTh>
                            <StyledTh>
                                <img src={EditRowIcon}
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                    setOpenEdit(true);
                                    setCurTariff(tariff);
                                    }}
                                    alt=""
                                />
                            </StyledTh>
                            <StyledTh>
                              {tariff.default !== 1 ? 
                                    <img src={DeleteIcon}
                                      style={{cursor: "pointer"}}
                                      onClick={() => {
                                        handleDeleteTariff(tariff.id);
                                      }}
                                    alt=""/> : ''}
                            </StyledTh>
                          </div>
                        </StyledTr>
                      )
                    }
                  </StyledTbody>
                </StyledTable>
              </TableBlock>
            </PageWrapper>
          </PageContainer>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openAdd}
          >
            <DialogContent dividers>
              <NewTariff reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
            maxWidth={'800'}
          >
            <DialogContent dividers>
              <EditTariff curTariff={curTariff} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openChangeDefault}
          >
            <DialogContent dividers>
              <ChangeDefaultTariff curTariff={curTariff} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>
        </>
      }
    </>
  )
}
export default Tariffs;