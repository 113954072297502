import styled from 'styled-components';

export const EditForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const EditFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

export const EditBtn = styled.button`
    width: 100%;
    background-color: #3E3E3E;
    padding: 10px 0;
    color: #ffffff;
    border: 0;
    border-radius: 5px;
`;

export const StyledH1 = styled.h1`
    margin: 0;
`;

export const StyledH2 = styled.h2`
    margin: 0;
    text-align: center;
`;


export const StyledSelect = styled.select`
    width: 100%;
    font-size: 16px;
    padding: 15px;
    max-height: 150px;
    background-color: #ffffff;
    border: 1px solid #9C9C9C;
    border-radius: 5px;
`;