import React from 'react';
import {DownloadButton, DownloadButtonIcon, DownloadButtonSpan} from "./styled";

const DownloadTemplateButton = () => {
  return (
    <DownloadButton target={'_blank'} href={'/files/import_file_template.xlsx'}>
      <DownloadButtonIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
          <path
            d="M109.334 54.3465H93.9206C81.2806 54.3465 70.9873 44.0532 70.9873 31.4132V15.9998C70.9873 13.0665 68.5873 10.6665 65.654 10.6665H43.0406C26.614 10.6665 13.334 21.3332 13.334 40.3732V87.6265C13.334 106.667 26.614 117.333 43.0406 117.333H84.9606C101.387 117.333 114.667 106.667 114.667 87.6265V59.6798C114.667 56.7465 112.267 54.3465 109.334 54.3465ZM65.494 84.1598L54.8273 94.8265C54.454 95.1998 53.974 95.5198 53.494 95.6798C53.014 95.8932 52.534 95.9998 52.0006 95.9998C51.4673 95.9998 50.9873 95.8932 50.5073 95.6798C50.0807 95.5198 49.654 95.1998 49.334 94.8798C49.2806 94.8265 49.2273 94.8265 49.2273 94.7732L38.5606 84.1065C37.014 82.5598 37.014 79.9998 38.5606 78.4532C40.1073 76.9065 42.6673 76.9065 44.214 78.4532L48.0006 82.3465V59.9998C48.0006 57.8132 49.814 55.9998 52.0006 55.9998C54.1873 55.9998 56.0006 57.8132 56.0006 59.9998V82.3465L59.8406 78.5065C61.3873 76.9598 63.9473 76.9598 65.494 78.5065C67.0406 80.0532 67.0406 82.6132 65.494 84.1598Z"
            fill="currentColor"/>
          <path
            d="M92.9587 46.9863C98.0253 47.0396 105.065 47.0396 111.092 47.0396C114.132 47.0396 115.732 43.4663 113.599 41.333C105.919 33.5996 92.1587 19.6796 84.2653 11.7863C82.0787 9.59964 78.292 11.093 78.292 14.133V32.7463C78.292 40.533 84.9053 46.9863 92.9587 46.9863Z"
            fill="currentColor"/>
        </svg>
      </DownloadButtonIcon>
      <DownloadButtonSpan>Download the order file template</DownloadButtonSpan>
    </DownloadButton>
  );
};

export default DownloadTemplateButton;