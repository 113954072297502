import React, {useRef, useState} from 'react';
import {
  CountryExistText,
  CountryItem,
  CountrySelectBlock,
  CountrySelectLabel,
  KeywordsAdditionButton,
  KeywordsContainer,
  KeywordsTotalBlock, TotalValueContainer, TotalValueSpan
} from "./styled";
import CountrySearchSelect from "../../../components/country-search-select/country-search-select";
import {
  getDatesBetween,
  getDatesListFromCountry, getFormattedDate,
  getKeysFromCountry,
  getKeywordsFromTextarea,
  throwToastError
} from "../../../utils";
import NewCampaignItemTableBlock from "../NewCampaignItemTableBlock";
import KeywordsAdditionBlock from "./Components/KeywordsAdditionBlock";
import RatesAdditionBlock from "./Components/RatesAdditionBlock";
import PackageAdditionBlock from "./Components/PackageAdditionBlock";

const NewCampaignCountryItem = ({
                                  country,
                                  countryIndex,
                                  putEditedCountryInList,
                                  handleRemoveCountry,
                                  handleCloneCountry,
                                  dateStart,
                                  dateStop,
                                  selectedType,
                                  selectedCountries
                                }) => {
  const [newCountryKeys, setNewCountryKeys] = useState([]);
  const textAreaRef = useRef(null)

  const handleSelectCountry = (value) => {
    //обработчик селектора страны
    let newCountry = country;
    newCountry.geo = value;
    putEditedCountryInList(newCountry, countryIndex)
  }

  const dateListsCheck = (newDateArray) => {
    const oldDateList = getDatesListFromCountry(country);
    const newDateList = newDateArray;

    if (oldDateList.length === 0) {
      return true
    }

    if (oldDateList.length !== newDateList.length) {
      return false
    }

    return oldDateList.every((date, index) => date === newDateList[index])
  }

  const handleAddNewKeywords = () => {
    //функция добавления новых ключей в страну
    if (!dateStart || !dateStop) {
      throwToastError('Please select start and end date')
      return
    }
    if (!newCountryKeys.length) {
      throwToastError('Please enter the keys')
      return;
    }

    const dateArray = getDatesBetween(dateStart, dateStop);
    const newCountry = country;

    //проверка на соответствие массивов дат (если даты изменились, старые ключи остаются, новые добавляются, дата применяется новая)
    if (dateListsCheck(dateArray)) {
      dateArray.forEach(date => {
        if (!newCountry.keywords[date]) {
          newCountry.keywords[date] = {}
        }
        newCountryKeys.forEach(key => newCountry.keywords[date][key] = 0)
      })
    } else {
      const oldKeys = getKeysFromCountry(country)
      const allKeysArray = [...oldKeys, ...newCountryKeys]

      newCountry.keywords = {}

      dateArray.forEach(date => {
        if (!newCountry.keywords[date]) {
          newCountry.keywords[date] = {}
        }
        allKeysArray.forEach(key => newCountry.keywords[date][key] = 0)
      })
    }

    setNewCountryKeys([]);
    textAreaRef.current.value = '';
    putEditedCountryInList(newCountry, countryIndex);
  }

  const handleChooseRateValue = (event) => {
    if (!dateStart || !dateStop) {
      throwToastError('Please select start and end date');
      return;
    }

    const dateArray = getDatesBetween(dateStart, dateStop);

    const newCountry = JSON.parse(JSON.stringify(country));

    if (event.target.checked) {
      dateArray.forEach(date => {
        if (!newCountry.keywords[date]) {
          newCountry.keywords[date] = {};
        }
        newCountry.keywords[date][event.target.value] = 0;
      });
    } else {
      dateArray.forEach(date => {
        if (newCountry.keywords[date]) {
          delete newCountry.keywords[date][event.target.value];
        }
      });
    }

    putEditedCountryInList(newCountry, countryIndex);
  };

  const getSelectedRates = (curKey) => {

    if (getFormattedDate(dateStart) && country.keywords[getFormattedDate(dateStart)]) {
      return Object.keys(country.keywords[getFormattedDate(dateStart)]).includes(curKey);
    }
    return false;
  };

  const handleAddNewKeysFromTextarea = (e) => {
    //обработчик поля для ввода новых ключей
    setNewCountryKeys(getKeywordsFromTextarea(e.target.value));
  }

  const getTotalValue = (countryItem) => {
    return Object.values(countryItem.keywords).map((obj) => {
      const arr = Object.values(obj);
      const newArr = arr.map((num) => parseInt(num))
      return newArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  }

  const getExistCountry = (iso) => {
    if (selectedCountries && iso) {
      const count = selectedCountries.filter((code) => code === iso).length;
      return count > 1;
    }
  }

  const currentTypes = {
    '1': 'installs',
    '2': 'rates',
    '3': 'installs'
  }

  return (
    <CountryItem>
      <CountrySelectBlock>
        <CountrySelectLabel>Country*</CountrySelectLabel>
        <CountrySearchSelect selectCountry={handleSelectCountry} defaultVal={country.geo}/>
        {getExistCountry(country.geo) &&
          <CountryExistText>This country is already exist</CountryExistText>}
      </CountrySelectBlock>
      <KeywordsContainer>
        {String(selectedType) === '1' &&
          <KeywordsAdditionBlock
            handleAddNewKeywords={handleAddNewKeywords}
            countryIndex={countryIndex}
            handleAddNewKeysFromTextarea={handleAddNewKeysFromTextarea}
            textAreaRef={textAreaRef}
            handleRemoveCountry={handleRemoveCountry}
          />
        }
        {String(selectedType) === '2' &&
          <RatesAdditionBlock
            countryIndex={countryIndex}
            handleRemoveCountry={handleRemoveCountry}
            handleChooseRateValue={handleChooseRateValue}
            getSelectedRates={getSelectedRates}
          />
        }
        {String(selectedType) === '3' &&
          <PackageAdditionBlock
            countryIndex={countryIndex}
            handleRemoveCountry={handleRemoveCountry}
          />
        }

        {(!!getDatesListFromCountry(country).length && !!getKeysFromCountry(country).length) &&
          <NewCampaignItemTableBlock
            country={country}
            countryIndex={countryIndex}
            putEditedCountryInList={putEditedCountryInList}
          />
        }

        <KeywordsTotalBlock>
          <KeywordsAdditionButton onClick={() => handleCloneCountry(countryIndex)}>Clone
            country</KeywordsAdditionButton>
          {!!selectedType && <TotalValueContainer>
            <TotalValueSpan>Total {currentTypes[selectedType]}</TotalValueSpan>
            <TotalValueSpan>{getTotalValue(country)}</TotalValueSpan>
          </TotalValueContainer>}
        </KeywordsTotalBlock>
      </KeywordsContainer>

    </CountryItem>
  )
}

export default NewCampaignCountryItem;