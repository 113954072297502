import styled from "styled-components";
import logo from "../../Images/logo.png"
import user from "../../Images/user.svg"

export const Header = styled.header`
  width: 100%;
  height: 85px;
  display: grid;
  grid-template-columns: 270px 1fr;
  background-color: #ffffff;
`;

export const HeaderLogoBlock = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 70%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

export const HeaderUserBlock = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 107px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  box-sizing: border-box;
`;

export const HeaderUserButton = styled.button`
  background-color: transparent;
  border: 0;
  width: 37px;
  height: 37px;
  background-image: url(${user});
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
`;

export const StyledPopup = styled.div`
  position: absolute;
  top: 100%;
  transform: translateX(50%);
  z-index: 5;


  padding: 16px 32px 16px 16px;
  background-color: #ffffff;
  border: 1px solid #3E3E3E;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  & a {
    color: #3E3E3E;
    font-family: 'Urbanist', sans-serif;
    font-weight: 400;
    font-size: 18px;

    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LogOutButton = styled.a`
  width: max-content;
`;