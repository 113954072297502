import * as React from 'react';
import {useEffect, useState} from 'react';
import css from './style.module.css'
import {Box, Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import API from "../../Api/API";
import {Loader} from 'rsuite';

import AddIcon from '../../Images/icons/add-square-icon.svg';
import LogsIcon from '../../Images/icons/logs-icon.svg';
import UploadIcon from '../../Images/icons/document-upload-icon.svg';
import EditRowIcon from '../../Images/icons/edit-row-icon.svg';
import AddSupplier from "./add-supplier/add-supplier";
import EditSupplier from './edit-supplier/edit-supplier';
import {SearchInput} from './styled';
import useDebounce from '../utils/use-debounce';
import ChangesLogs from '../components/changes-logs/changes-logs';
import BalanceLogs from '../components/balance-logs/balance-logs';
import AddBalanceModal from "../components/AddBalanceModal";


const Suppliers = () => {
  const [List, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [userId, setUserId] = useState(null);
  const [supplier, setSuplier] = useState();
  const [openChangesLogs, setOpenChangesLogs] = useState(false);
  const [openBalanceLogs, setOpenBalanceLogs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [supplierSearch, setSuplierSearch] = useState();

  const debouncedSearch = useDebounce(supplierSearch, 500)

  const handleClose = () => {
    setOpenAdd(false);
    setOpen(false);
    setOpenChangesLogs(false);
    setOpenBalanceLogs(false);
    setOpenEdit(false);
  };
  useEffect(() => {
    API.GetSuppliers(0, 999, '').then((data) => {
      if (data) {
        setList(data.data)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (debouncedSearch !== undefined) {
      API.GetSuppliers(0, 999, debouncedSearch).then((data) => {
        if (data) {
          setList(data.data)
          setLoading(false)
        }
      })
    }
  }, [debouncedSearch])

  const handleRestartTable = () => {
    setLoading(true)
    API.GetSuppliers(0, 999, '').then((data) => {
      if (data) {
        setList(data.data)
        setLoading(false)
      }
    })
  }

  const handleSearch = (e) => {
    setSuplierSearch(e.target.value);
  }

  return (
    <>
      {loading ? <Loader backdrop size="lg" content="loading..." vertical/> :
        <>
          <h1 className={css.h1}>Suppliers</h1>
          <br/>
          <Grid container>
            <Grid item>
              <Box className={css.darkBtn} onClick={() => {
                setOpen(true)
              }}>
                Add Suppliers
              </Box>
            </Grid>
          </Grid>
          <br/>
          <br/>
          <Grid container>
            <Grid item>
              <SearchInput placeholder='Suppliers search' onChange={handleSearch}></SearchInput>
            </Grid>
          </Grid>
          <br/>
          <br/>
          <Box className={css.MainBox}>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead className={css.HeadTable}>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Add Balance</TableCell>
                    <TableCell>Android</TableCell>
                    <TableCell>Install</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>IOS</TableCell>
                    <TableCell>Install</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {List.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {(row.balance)?.toFixed(2)} $
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <img src={AddIcon}
                             style={{cursor: "pointer"}}
                             height={20}
                             width={20}
                             alt=""
                             onClick={() => {
                               setOpenAdd(true);
                               setUserId(row.id);
                             }}/>
                      </TableCell>
                      <TableCell component="th" scope="row">
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.install_cost}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.rate_cost}
                      </TableCell>
                      <TableCell component="th" scope="row">

                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.a_install_cost}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.a_rate_cost}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <img src={LogsIcon}
                             style={{cursor: "pointer"}}
                             onClick={() => {
                               setOpenChangesLogs(true);
                               setUserId(row.id);
                             }} alt=""/>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <img src={UploadIcon}
                             style={{cursor: "pointer"}}
                             onClick={() => {
                               setOpenBalanceLogs(true)
                               setUserId(row.id)
                             }}
                             alt=""/>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <img src={EditRowIcon}
                             style={{cursor: "pointer"}}
                             alt="" onClick={() => {
                          setOpenEdit(true);
                          setSuplier(row);
                        }}/>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={'800'}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Add Client
            </DialogTitle>
            <DialogContent dividers>
              <AddSupplier reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openAdd}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Add balance
            </DialogTitle>
            <DialogContent dividers>
              <AddBalanceModal id={userId} type={'supplier'} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
            maxWidth={'800'}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Edit supplier
            </DialogTitle>
            <DialogContent dividers>
              <EditSupplier curSuplier={supplier} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openChangesLogs}
            maxWidth={'1200'}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Changes Logs
            </DialogTitle>
            <DialogContent dividers>
              <ChangesLogs type={'suppliers'} logId={userId} close={handleClose} typeLog={'supplier'}></ChangesLogs>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openBalanceLogs}
            maxWidth={'1200'}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Balance Logs
            </DialogTitle>
            <DialogContent dividers>
              <BalanceLogs type={'suppliers'} logId={userId} close={handleClose}></BalanceLogs>
            </DialogContent>
          </Dialog>
        </>
      }
    </>
  )
}
export default Suppliers;