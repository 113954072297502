import styled from "styled-components";

export const StartPromotionBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
  gap: 20px;
  width: 400px;
  margin-left: auto;
  margin-bottom: 80px;
`;

export const CampaignPriceBlock = styled.div`
  opacity: ${props => props.visiable ? 1 : 0};
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const CampaignPriceSpan = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #3e3e3e;
`;

export const CampaignPriceValue = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  color: #000000;
`;

export const StartButtonsBlock = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  gap: 43px;
  max-width: 350px;
  width: 100%;
`;

export const StartGreenBtn = styled.button`
  background-color: #73D27C;
  border: 0;
  border-radius: 5px;
  padding: 10px 19px;
  max-width: 185px;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
`;

export const ClearBtn = styled.button`
  background-color: transparent;
  color: #D27373;
  border: 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  margin-right: 20px;
`;