import * as React from 'react';
import {useEffect, useState} from 'react';
import API from "../../../Api/API";
import {Form, InputBlock, SubmitButton} from './styled';
import CountrySearchSelect from "../../components/country-search-select/country-search-select";
import {getStoreLinkId, throwToastError} from "../../utils";
import ClientsSearchSelect from "../../components/clients-search-select/clients-search-select";
import BasicInput from "../../components/BasicInput";
import {Loader} from "rsuite";

const NewAppModal = ({handleAddNewApp, link, readOnly}) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [appLink, setAppLink] = useState(link ?? '');

  const [isSending, setIsSending] = useState(false);

  const [errorsList, setErrorsList] = useState([])

  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    setErrorsList([]);
  }, [selectedClient, appLink, selectedCountry]);

  const validateData = (link, country, client) => {
    let errors = [];
    const appId = getStoreLinkId(link);

    if (!country) {
      errors.push('wrong country');
    }

    if (!appId) {
      errors.push('wrong link');
    }

    if (!client) {
      errors.push('empty client')
    }

    if (!errors.length) {
      return true
    } else {
      setErrorsList(errors);
      return false
    }
  }

  const handleSubmit = () => {
    if (validateData(appLink, selectedCountry, selectedClient)) {
      const appId = getStoreLinkId(appLink);
      setIsSending(true)

      API.NewApp({
        appid: appId,
        country: selectedCountry,
        clientId: selectedClient
      }).then((result) => {
        if (result.data.status === 'ok') {
          handleAddNewApp()
        }
        if (result.data.error && result.data.error === 'can`t find application') {
          errorsList.push('wrong link', 'wrong country')
          throwToastError('Can not find application, try again');
        }
        setIsSending(false)
      })
    }
  }

  const handleChangeLinkInput = (e) => {
    setAppLink(e.target.value)
  }

  const handleClearLinkInput = () => {
    setAppLink('')
  }

  const handleChooseCountry = (iso) => {
    setSelectedCountry(iso)
  }

  const handleChooseClient = (client) => {
    setSelectedClient(client)
  }

  return (
    <Form>
      <InputBlock>
        <span>Customers</span>
        <ClientsSearchSelect
          handleSelectClient={handleChooseClient}
          selectedClient={selectedClient}
          size={'lg'}
          onError={errorsList.includes('empty client')}
          errorText={'Choose the client'}
        />
      </InputBlock>
      <InputBlock>
        <span>Link from Google Play or App Store</span>
        <BasicInput
          onChange={handleChangeLinkInput}
          value={appLink}
          disabled={readOnly}
          placeholder={'App link'}
          onError={errorsList.includes('wrong link')}
          errorText={'Wrong link'}
          handleClear={handleClearLinkInput}
          size={'lg'}
        />
      </InputBlock>
      <InputBlock>
        <span>Choose country for App Store</span>
        <CountrySearchSelect
          selectCountry={handleChooseCountry}
          size={'lg'}
          defaultVal={selectedCountry ? selectedCountry : ''}
          onError={errorsList.includes('wrong country')}
          errorText={'Choose the correct country'}
        />
      </InputBlock>


      <SubmitButton onClick={handleSubmit} disabled={isSending}>{isSending ? 'Adding...' : 'Submit'}</SubmitButton>

      {isSending && <Loader backdrop size="lg" content="loading..." vertical/>}
    </Form>
  );
}

export default NewAppModal;