import React from 'react';
import {ApplyBtn, RadioLabel, StyledForm, StyledInput, StyledRadioBtn, TextLabel} from './styled';

const NewCampaignRatioModal = ({handleSetRatio, ratioKeyForModal}) => {
  const [value, setValue] = React.useState(undefined);
  const [typeRatio, setTypeRatio] = React.useState('percent')
  const HandlerChangeValue = (e) => {
    setValue(e.target.value);
  }
  const HandlerChangeType = (e) => {
    setTypeRatio(e.target.value);
  }
  return (
    <div>
      <StyledForm>
        <RadioLabel>
          <StyledRadioBtn type={'radio'} name={'ratio'} value={'percent'} defaultChecked onChange={HandlerChangeType}/>
          Percent
        </RadioLabel>
        <RadioLabel>
          <StyledRadioBtn type={'radio'} name={'ratio'} value={'amount'} onChange={HandlerChangeType}/>
          Fixed
        </RadioLabel>
        <RadioLabel>
          <StyledRadioBtn type={'radio'} name={'ratio'} value={'deviation'} onChange={HandlerChangeType}/>
          Deviation
        </RadioLabel>
        {!ratioKeyForModal &&
          <RadioLabel>
            <StyledRadioBtn
              type={'radio'} name={'ratio'} value={'autofill'} onChange={HandlerChangeType}
            />
            Equal
          </RadioLabel>
        }
        <TextLabel>
          <StyledInput
            type={'number'}
            defaultValue={value}
            onChange={HandlerChangeValue}
            placeholder={'Enter quantity'}
            onKeyDown={(event) => {
              const disallowedKeys = ['+', 'e'];

              if (disallowedKeys.includes(event.key)) {
                event.preventDefault(); // Отменяем ввод недопустимых символов
              }
            }}
          />
          Daily
        </TextLabel>

        <ApplyBtn
          type={'submit'}
          value={'Apply'}
          onClick={(e) => {
            e.preventDefault()
            handleSetRatio(typeRatio, value, ratioKeyForModal);
          }}/>
      </StyledForm>
    </div>
  );
}

export default NewCampaignRatioModal;
