import React, {useCallback, useEffect, useState} from 'react';
import {
  CampaignForm,
  DatePickerContainer, ExtendDateInput,
  ImportButton, PackageInput, PackageLabel,
  ParamsBlock,
  ScenarioTitle,
  TimeOption,
  TimeOptionSpan, TypeRow
} from "./styled";
import OptionItem from "./Modules/Option/option-item";
import DatePicker, {registerLocale} from "react-datepicker";
import AvailableBalanceBlock from "./Modules/AvailableBalanceBlock";
import en from "date-fns/locale/en-GB";
import API from "../../../../Api/API";
import moment from "moment";
import NewCampaignCheckboxesBlock from "../NewCampaignCheckboxesBlock";

registerLocale("en", en);

const NewCampaignForm = React.memo(({
                                      scenario,
                                      allSuppliersList,
                                      allApplicationsList,
                                      allClientsList,
                                      selectedSupplier,
                                      selectedClient,
                                      selectedType,
                                      selectedApplication,
                                      handleSelectSupplier,
                                      handleSelectClient,
                                      handleSelectType,
                                      handleSelectApplication,
                                      selectedDateStart,
                                      selectedDateStop,
                                      handleSelectDates,
                                      countriesList,
                                      currentClientCost,
                                      handleSetCurrentClientCost,
                                      selectedApplicationInfo,
                                      selectedClientInfo,
                                      handleToggleImportModal,
                                      checkboxesValue,
                                      handleSelectCheckboxesValue,
                                      handleSelectDelayStartTime,
                                      selectedDelayStart
                                    }) => {
  const typeOptions = [{id: 1, name: 'Install'}, {id: 2, name: 'Rate'}];

  const [filteredClientsList, setFilteredClientsList] = useState(allClientsList);
  const [filteredAppsList, setFilteredAppsList] = useState(allApplicationsList);

  const [singleClientForApp, setSingleClientForApp] = useState(false);

  function getUniqueAppList(appList) {
    return appList.reduce((accumulator, currentApp) => {
      if (!accumulator.some((obj) => obj.appid === currentApp.appid)) {
        accumulator.push(currentApp);
      }
      return accumulator
    }, []);
  }

  const setAutocompleteApplications = () => {
    if (!selectedClient) {
      if (selectedApplication) {
        const selectedAppId = allApplicationsList.find((app) => Number(app.id) === Number(selectedApplication)).appid;
        const appInUniqueAppList = getUniqueAppList(allApplicationsList).find((a) => a.appid === selectedAppId)
        handleSelectApplication(appInUniqueAppList.id)
      } else {
        setFilteredClientsList(allClientsList)
      }
      setFilteredAppsList(getUniqueAppList(allApplicationsList))
    } else {
      if (selectedApplication && allApplicationsList.length > 0) {
        const selectedApp = allApplicationsList.find((a) => Number(a.id) === Number(selectedApplication));
        const appClientsIds = allApplicationsList.filter((application) => application.appid === selectedApp?.appid).map(app => app.client_id);
        if (!appClientsIds.find((c) => Number(c) === Number(selectedClient))) {
          handleSelectApplication(0);
          setFilteredAppsList(allApplicationsList);
        } else {
          const currentAppObject = allApplicationsList.find((a) => selectedApp.appid === a.appid && Number(a.client_id) === Number(selectedClient))
          handleSelectApplication(currentAppObject.id)
        }
      }
      API.GetApps(0, 999, selectedClient, '').then((result) => {
        setFilteredAppsList(result.data)
      })
    }
  }

  useEffect(() => {
    setAutocompleteApplications();
  }, [selectedClient]);

  const setAutocompleteClients = () => {
    if (selectedApplication) {
      const selectedApp = allApplicationsList.find((application) => Number(application.id) === Number(selectedApplication))
      // todo
      const clientsIds = allApplicationsList.filter((application) => application.appid.toString() === selectedApp.appid.toString()).map(app => app.client_id);
      if (clientsIds.length > 0) {
        const currentClients = allClientsList.filter((client) => clientsIds.includes(client?.id));
        // Если у приложения один клиент - автоматически его подставляет, работает с багом
        if (clientsIds.length === 1) {
          const currentClient = allClientsList.find((client) => Number(client?.id) === Number(clientsIds[0]));
          handleSelectClient(currentClient.id)
          setSingleClientForApp(true)
        } else {
          setSingleClientForApp(false)
        }
        setFilteredClientsList(currentClients);
      } else {
        setFilteredClientsList(allClientsList)
        handleSelectClient(0)
      }
    } else {
      setFilteredClientsList(allClientsList)
      setSingleClientForApp(false)
    }
  }

  useEffect(() => {
    setAutocompleteClients()
  }, [selectedApplication])

  return (
    <>
      <CampaignForm>
        <ScenarioTitle>{scenario} campaign</ScenarioTitle>
        <ParamsBlock>
          <AvailableBalanceBlock
            selectedClientInfo={selectedClientInfo}
            selectedApplicationInfo={selectedApplicationInfo}
            countriesList={countriesList}
            selectedType={selectedType}
            currentClientCost={currentClientCost}
            handleSetCurrentClientCost={handleSetCurrentClientCost}
            checkboxesValue={checkboxesValue}
          />
          {(scenario === 'Create' || scenario === 'Import') &&
            <ImportButton onClick={handleToggleImportModal}>
              Import file
            </ImportButton>
          }
          <OptionItem
            title={'Supplier*'}
            handle={handleSelectSupplier}
            optionList={allSuppliersList}
            value={selectedSupplier}
            setDisabled={scenario === 'Duplicate' || scenario === 'Extend'}
          />
          <TypeRow>
            <OptionItem
              title={'Type*'}
              handle={handleSelectType}
              optionList={typeOptions}
              value={selectedType}
              setDisabled={scenario === 'Duplicate' || scenario === 'Extend'}
            />
            {(Number(selectedType) === 1 || Number(selectedType) === 3) &&
              <PackageLabel>
                <PackageInput type="checkbox"
                              name={'package'}
                              value={'3'}
                              checked={String(selectedType) === '3'}
                              onChange={(event) => {
                                event.target.checked ? handleSelectType(String(event.target.value)) : handleSelectType('1')
                              }}
                />
                by package
              </PackageLabel>
            }
          </TypeRow>
          <OptionItem
            title={'Client*'}
            handle={handleSelectClient}
            optionList={filteredClientsList}
            value={selectedClient}
            setDisabled={scenario === 'Duplicate' || scenario === 'Extend'}
            cleanable={scenario !== 'Duplicate' && scenario !== 'Extend' && !singleClientForApp}
          />
          <OptionItem
            title={'Application*'}
            handle={handleSelectApplication}
            optionList={filteredAppsList}
            value={selectedApplication}
            setDisabled={scenario === 'Duplicate' || scenario === 'Extend' || scenario === 'Promote' || scenario === 'Import'}
            cleanable={scenario !== 'Duplicate' && scenario !== 'Extend' && scenario !== 'Promote' && scenario !== 'Import'}
          />
          <TimeOption>
            <TimeOptionSpan>Set Time*</TimeOptionSpan>
            <DatePickerContainer>
              {scenario === 'Extend' || scenario === 'Import' ? <>
                  <ExtendDateInput value={moment(selectedDateStart).format('YYYY-MM-DD')} readOnly/>
                  <ExtendDateInput value={moment(selectedDateStop).format('YYYY-MM-DD')} readOnly/>
                </> :
                <DatePicker
                  minDate={new Date()}
                  selected={selectedDateStart}
                  onChange={handleSelectDates}
                  startDate={selectedDateStart}
                  endDate={selectedDateStop}
                  selectsRange
                  inline
                  locale="en"
                  dayClassName={(date) =>
                    date.getDay() === 1 ? "monday" : undefined
                  }
                />
              }
            </DatePickerContainer>
          </TimeOption>
          <NewCampaignCheckboxesBlock
            selectedApplicationInfo={selectedApplicationInfo}
            checkboxesValue={checkboxesValue}
            handleSelectCheckboxesValue={handleSelectCheckboxesValue}
            handleSelectDelayStartTime={handleSelectDelayStartTime}
            selectedDelayStart={selectedDelayStart}
            selectedDateStart={selectedDateStart}
          />
        </ParamsBlock>
      </CampaignForm>
    </>
  );
});

export default NewCampaignForm;