import {useState, useEffect} from 'react';
import {
  ClientItem,
  SelectItemSpan,
  ClientsList,
  ClientSearchBlock,
  ClearBtn,
  SearchInput,
  ClientSearchErrorText
} from './styled';
import API from '../../../Api/API';
import useDebounce from '../../utils/use-debounce';

const ClientsSearchSelect = ({handleSelectClient, size, selectedClient, selectedApp, onError, errorText}) => {
  const [clientsList, setClientsList] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [searchClient, setSearchClient] = useState('')

  const debouncedSearch = useDebounce(searchClient, 500);

  useEffect(() => {
    API.GetClients(0, 9999, '').then((data) => {
      const newClientsList = data.data;

      if (selectedApp && newClientsList) {
        newClientsList.filter(client => client)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedClient && clientsList) {
      setSearchClient(clientsList.find((client) => Number(client.id) === Number(selectedClient))?.name)
    }
  }, [clientsList])

  useEffect(() => {
    setClientsList([]);
    API.GetClients(0, 9999, searchClient).then((data) => {
      setClientsList(data.data);
    })
    if (debouncedSearch === '' && selectedClient) {
      handleSelectClient(0)
    }
  }, [debouncedSearch])

  const handleOpenClientList = () => {
    setOpenList(true);
  }

  const handleCloseClientList = () => {
    setOpenList(false);
  }

  const handleSearchInputChange = (e) => {
    setSearchClient(e.target.value)
  }

  const handleClearInput = () => {
    setSearchClient('')
    handleSelectClient(0)
  }

  return (
    <ClientSearchBlock>
      <SearchInput
        type='text'
        onFocus={handleOpenClientList}
        onBlur={handleCloseClientList}
        onChange={handleSearchInputChange}
        value={searchClient}
        placeholder='Choose client'
        data-size={size}
        data-on-error={onError}
      />
      {onError && errorText && <ClientSearchErrorText>{errorText}</ClientSearchErrorText>}
      {searchClient && <ClearBtn onClick={handleClearInput}>✖</ClearBtn>}
      <ClientsList open={openList}>
        {clientsList.map((client) => (
          <ClientItem
            key={client.id}
            onMouseDown={() => {
              handleSelectClient(client.id)
              setSearchClient(client.name);
            }}
          >
            <SelectItemSpan>{client.name}</SelectItemSpan>
          </ClientItem>
        ))}
      </ClientsList>
    </ClientSearchBlock>
  );
}

export default ClientsSearchSelect;
