module.exports = [
  {
    'Country': 'Afghanistan',
    'Country_Ru': 'Афганистан',
    'ISO': 'AF',
    'China': '阿富汗',
    'Alias': [
      'Afghanistan',
      'Афганистан',
      'Переходное Исламское Государство Афганистан'
    ]
  },
  {
    'Country': 'Åland Islands',
    'Country_Ru': 'Аландские острова',
    'ISO': 'AX',
    'China': '阿兰群岛',
    'Alias': [
      'Åland Islands',
      'Aland Islands',
      'Аландские острова',
      'AX'
    ]
  },
  {
    'Country': 'Albania',
    'Country_Ru': 'Албания',
    'ISO': 'AL',
    'China': '阿尔巴尼亚',
    'Alias': [
      'Albania',
      'Албания',
      'Республика Албания'
    ]
  },
  {
    'Country': 'Algeria',
    'Country_Ru': 'Алжир',
    'ISO': 'DZ',
    'China': '阿尔及利亚',
    'Alias': [
      'Algeria',
      'Алжир',
      'Алжирская Народная Демократическая Республика',
      'DZ'
    ]
  },
  {
    'Country': 'American Samoa',
    'Country_Ru': 'Американское Самоа',
    'ISO': 'AS',
    'China': '美国萨摩亚',
    'Alias': [
      'American Samoa',
      'Американское Самоа',
      'AS'
    ]
  },
  {
    'Country': 'Andorra',
    'Country_Ru': 'Андорра',
    'ISO': 'AD',
    'China': '安道尔',
    'Alias': [
      'Andorra',
      'Андорра',
      'Княжество Андорра',
      'AD'
    ]
  },
  {
    'Country': 'Angola',
    'Country_Ru': 'Ангола',
    'ISO': 'AO',
    'China': '安哥拉',
    'Alias': [
      'Angola',
      'Ангола',
      'Республика Ангола',
      'AO'
    ]
  },
  {
    'Country': 'Anguilla',
    'Country_Ru': 'Ангилья',
    'ISO': 'AI',
    'China': '安圭拉',
    'Alias': [
      'Anguilla',
      'Ангилья',
      'AI'
    ]
  },
  {
    'Country': 'Antarctica',
    'Country_Ru': 'Антарктида',
    'ISO': 'AQ',
    'China': '南极洲',
    'Alias': [
      'Antarctica',
      'Антарктида',
      'AQ'
    ]
  },
  {
    'Country': 'Antigua and Barbuda',
    'Country_Ru': 'Антигуа и Барбуда',
    'ISO': 'AG',
    'China': '安提瓜和巴布达',
    'Alias': [
      'Antigua and Barbuda',
      'Антигуа и Барбуда',
      'AG'
    ]
  },
  {
    'Country': 'Argentina',
    'Country_Ru': 'Аргентина',
    'ISO': 'AR',
    'China': '阿根廷',
    'Alias': [
      'Argentina',
      'Аргентина',
      'Аргентинская Республика'
    ]
  },
  {
    'Country': 'Armenia',
    'Country_Ru': 'Армения',
    'ISO': 'AM',
    'China': '亚美尼亚',
    'Alias': [
      'Armenia',
      'Армения',
      'Республика Армения',
      'AM'
    ]
  },
  {
    'Country': 'Aruba',
    'Country_Ru': 'Аруба',
    'ISO': 'AW',
    'China': '阿鲁巴',
    'Alias': [
      'Aruba',
      'Аруба',
      'AW'
    ]
  },
  {
    'Country': 'Australia',
    'Country_Ru': 'Австралия',
    'ISO': 'AU',
    'China': '澳大利亚',
    'Alias': [
      'Australia',
      'Австралия'
    ]
  },
  {
    'Country': 'Austria',
    'Country_Ru': 'Австрия',
    'ISO': 'AT',
    'China': '奥地利',
    'Alias': [
      'Austria',
      'Австрия',
      'Австрийская Республика',
      'AT'
    ]
  },
  {
    'Country': 'Azerbaijan',
    'Country_Ru': 'Азербайджан',
    'ISO': 'AZ',
    'China': '阿塞拜疆',
    'Alias': [
      'Azerbaijan',
      'Азербайджан',
      'Республика Азербайджан'
    ]
  },
  {
    'Country': 'Bahamas',
    'Country_Ru': 'Багамы',
    'ISO': 'BS',
    'China': '巴哈马',
    'Alias': [
      'Bahamas',
      'Багамы',
      'Содружество Багамы',
      'BS'
    ]
  },
  {
    'Country': 'Bahrain',
    'Country_Ru': 'Бахрейн',
    'ISO': 'BH',
    'China': '巴林',
    'Alias': [
      'Bahrain',
      'Бахрейн',
      'Королевство Бахрейн',
      'BH'
    ]
  },
  {
    'Country': 'Bangladesh',
    'Country_Ru': 'Бангладеш',
    'ISO': 'BD',
    'China': '孟加拉国',
    'Alias': [
      'Bangladesh',
      'Бангладеш',
      'Народная Республика Бангладеш',
      'BD'
    ]
  },
  {
    'Country': 'Barbados',
    'Country_Ru': 'Барбадос',
    'ISO': 'BB',
    'China': '巴巴多斯',
    'Alias': [
      'Barbados',
      'Барбадос',
      'BB'
    ]
  },
  {
    'Country': 'Belarus',
    'Country_Ru': 'Беларусь',
    'ISO': 'BY',
    'China': '白俄罗斯',
    'Alias': [
      'Belarus',
      'Беларусь',
      'Республика Беларусь',
      'BY'
    ]
  },
  {
    'Country': 'Belgium',
    'Country_Ru': 'Бельгия',
    'ISO': 'BE',
    'China': '比利时',
    'Alias': [
      'Belgium',
      'Бельгия',
      'Королевство Бельгии'
    ]
  },
  {
    'Country': 'Belize',
    'Country_Ru': 'Белиз',
    'ISO': 'BZ',
    'China': '伯利兹',
    'Alias': [
      'Belize',
      'Белиз',
      'BZ'
    ]
  },
  {
    'Country': 'Benin',
    'Country_Ru': 'Бенин',
    'ISO': 'BJ',
    'China': '贝宁',
    'Alias': [
      'Benin',
      'Бенин',
      'Республика Бенин',
      'BJ'
    ]
  },
  {
    'Country': 'Bermuda',
    'Country_Ru': 'Бермуды',
    'ISO': 'BM',
    'China': '百慕大',
    'Alias': [
      'Bermuda',
      'Бермуды',
      'BM'
    ]
  },
  {
    'Country': 'Bhutan',
    'Country_Ru': 'Бутан',
    'ISO': 'BT',
    'China': '不丹',
    'Alias': [
      'Bhutan',
      'Бутан',
      'Королевство Бутан',
      'BT'
    ]
  },
  {
    'Country': 'Bolivia (Plurinational State of)',
    'Country_Ru': 'Боливия',
    'ISO': 'BO',
    'China': '玻利维亚（多元状态）',
    'Alias': [
      'Bolivia (Plurinational State of)',
      'Bolivia',
      'Bolivia, Plurinational State of',
      'Боливия'
    ]
  },
  {
    'Country': 'Bonaire, Sint Eustatius and Saba',
    'Country_Ru': 'Бонайре, Синт-Эстатиус и Саба',
    'ISO': 'BQ',
    'China': '博内尔，圣尤斯特歇斯和萨巴',
    'Alias': [
      'Bonaire, Sint Eustatius and Saba',
      'Bonaire, Sint Eustatius and Saba',
      'Бонайре, Синт-Эстатиус и Саба',
      'Бонайре',
      'BQ'
    ]
  },
  {
    'Country': 'Bosnia and Herzegovina',
    'Country_Ru': 'Босния и Герцеговина',
    'ISO': 'BA',
    'China': '波斯尼亚和黑塞哥维那',
    'Alias': [
      'Bosnia and Herzegovina',
      'Босния и Герцеговина',
      'Босния',
      'BA'
    ]
  },
  {
    'Country': 'Botswana',
    'Country_Ru': 'Ботсвана',
    'ISO': 'BW',
    'China': '博茨瓦纳',
    'Alias': [
      'Botswana',
      'Ботсвана',
      'Республика Ботсвана',
      'BW'
    ]
  },
  {
    'Country': 'Bouvet Island',
    'Country_Ru': 'Остров Буве',
    'ISO': 'BV',
    'China': '布威岛',
    'Alias': [
      'Bouvet Island',
      'Остров Буве',
      'Буве',
      'BV'
    ]
  },
  {
    'Country': 'Brazil',
    'Country_Ru': 'Бразилия',
    'ISO': 'BR',
    'China': '巴西',
    'Alias': [
      'Brazil',
      'Бразилия',
      'Федеративная Республика Бразилия'
    ]
  },
  {
    'Country': 'British Indian Ocean Territory',
    'Country_Ru': 'Британская Территория в Индийском Океане',
    'ISO': 'IO',
    'China': '英属印度洋领地',
    'Alias': [
      'British Indian Ocean Territory',
      'Британская Территория в Индийском Океане',
      'IO'
    ]
  },
  {
    'Country': 'Brunei Darussalam',
    'Country_Ru': 'Бруней',
    'ISO': 'BN',
    'China': '文莱达鲁萨兰国',
    'Alias': [
      'Brunei Darussalam',
      'Бруней',
      'BN'
    ]
  },
  {
    'Country': 'Bulgaria',
    'Country_Ru': 'Болгария',
    'ISO': 'BG',
    'China': '保加利亚',
    'Alias': [
      'Bulgaria',
      'Болгария',
      'Республика Болгария',
      'BG'
    ]
  },
  {
    'Country': 'Burkina Faso',
    'Country_Ru': 'Буркина-Фасо',
    'ISO': 'BF',
    'China': '布基纳法索',
    'Alias': [
      'Burkina Faso',
      'Буркина-Фасо',
      'BF'
    ]
  },
  {
    'Country': 'Burundi',
    'Country_Ru': 'Бурунди',
    'ISO': 'BI',
    'China': '布隆迪',
    'Alias': [
      'Burundi',
      'Бурунди',
      'Республика Бурунди',
      'BI'
    ]
  },
  {
    'Country': 'Cabo Verde',
    'Country_Ru': 'Кабо-Верде',
    'ISO': 'CV',
    'China': '佛得角',
    'Alias': [
      'Cabo Verde',
      'Cape Verde',
      'Кабо-Верде',
      'CV'
    ]
  },
  {
    'Country': 'Cambodia',
    'Country_Ru': 'Камбоджа',
    'ISO': 'KH',
    'China': '柬埔寨',
    'Alias': [
      'Cambodia',
      'Камбоджа',
      'Королевство Камбоджа',
      'KH'
    ]
  },
  {
    'Country': 'Cameroon',
    'Country_Ru': 'Камерун',
    'ISO': 'CM',
    'China': '喀麦隆',
    'Alias': [
      'Cameroon',
      'Камерун',
      'Республика Камерун',
      'CM'
    ]
  },
  {
    'Country': 'Canada',
    'Country_Ru': 'Канада',
    'ISO': 'CA',
    'China': '加拿大',
    'Alias': [
      'Canada',
      'Канада'
    ]
  },
  {
    'Country': 'Cayman Islands',
    'Country_Ru': 'Каймановы Острова',
    'ISO': 'KY',
    'China': '开曼群岛',
    'Alias': [
      'Cayman Islands',
      'Острова Кайман',
      'Каймановы Острова',
      'KY'
    ]
  },
  {
    'Country': 'Central African Republic',
    'Country_Ru': 'Центральноафриканская Республика',
    'ISO': 'CF',
    'China': '中非共和国',
    'Alias': [
      'Central African Republic',
      'Центральноафриканская Республика',
      'ЦАР',
      'CAR',
      'CF'
    ]
  },
  {
    'Country': 'Chad',
    'Country_Ru': 'Чад',
    'ISO': 'TD',
    'China': '乍得',
    'Alias': [
      'Chad',
      'Чад',
      'Республика Чад',
      'TD'
    ]
  },
  {
    'Country': 'Chile',
    'Country_Ru': 'Чили',
    'ISO': 'CL',
    'China': '智利',
    'Alias': [
      'Chile',
      'Чили',
      'Республика Чили',
      'CL'
    ]
  },
  {
    'Country': 'China',
    'Country_Ru': 'Китай',
    'ISO': 'CN',
    'China': '中国',
    'Alias': [
      'China',
      'Китай',
      'Китайская Народная Республика',
      'China',
      'CN'
    ]
  },
  {
    'Country': 'Christmas Island',
    'Country_Ru': 'Остров Рождества',
    'ISO': 'CX',
    'China': '圣诞岛',
    'Alias': [
      'Christmas Island',
      'Остров Рождества',
      'CX'
    ]
  },
  {
    'Country': 'Cocos (Keeling) Islands',
    'Country_Ru': 'Кокосовые острова',
    'ISO': 'CC',
    'China': '可可岛（龙骨）岛',
    'Alias': [
      'Cocos (Keeling) Islands',
      'Кокосовые острова',
      'CC'
    ]
  },
  {
    'Country': 'Colombia',
    'Country_Ru': 'Колумбия',
    'ISO': 'CO',
    'China': '哥伦比亚',
    'Alias': [
      'Colombia',
      'Колумбия',
      'Республика Колумбия'
    ]
  },
  {
    'Country': 'Comoros',
    'Country_Ru': 'Коморы',
    'ISO': 'KM',
    'China': '科莫罗斯',
    'Alias': [
      'Comoros',
      'Коморы',
      'Союз Коморы',
      'KM'
    ]
  },
  {
    'Country': 'Congo',
    'Country_Ru': 'Конго',
    'ISO': 'CG',
    'China': '刚果',
    'Alias': [
      'Congo',
      'Конго',
      'Республика Конго',
      'CG'
    ]
  },
  {
    'Country': 'Democratic Republic of the Congo',
    'Country_Ru': 'Демократическая Республика Конго',
    'ISO': 'CD',
    'China': '刚果，民主共和国',
    'Alias': [
      'Congo, Democratic Republic of the',
      'Congo, Democratic Republic',
      'Congo, the Democratic Republic of the',
      'Демократическая Республика Конго',
      'Конго',
      'CD'
    ]
  },
  {
    'Country': 'Cook Islands',
    'Country_Ru': 'Острова Кука',
    'ISO': 'CK',
    'China': '库克群岛',
    'Alias': [
      'Cook Islands',
      'Острова Кука',
      'Кука',
      'CK'
    ]
  },
  {
    'Country': 'Costa Rica',
    'Country_Ru': 'Коста-Рика',
    'ISO': 'CR',
    'China': '哥斯达黎加',
    'Alias': [
      'Costa Rica',
      'Коста-Рика',
      'CR'
    ]
  },
  {
    'Country': 'Côte d\'Ivoire',
    'Country_Ru': 'Кот-д\'Ивуар',
    'ISO': 'CI',
    'China': '科特迪瓦',
    'Alias': [
      'Côte d\'Ivoire',
      'Cote D"Ivoire',
      'Кот-д\'Ивуар',
      'CI'
    ]
  },
  {
    'Country': 'Croatia',
    'Country_Ru': 'Хорватия',
    'ISO': 'HR',
    'China': '克罗地亚',
    'Alias': [
      'Croatia',
      'Хорватия',
      'Республика Хорватия',
      'HR'
    ]
  },
  {
    'Country': 'Cuba',
    'Country_Ru': 'Куба',
    'ISO': 'CU',
    'China': '古巴',
    'Alias': [
      'Cuba',
      'Куба',
      'Республика Куба'
    ]
  },
  {
    'Country': 'Curaçao',
    'Country_Ru': 'Кюрасао',
    'ISO': 'CW',
    'China': '库拉索',
    'Alias': [
      'Curaçao',
      'Кюрасао',
      'CW'
    ]
  },
  {
    'Country': 'Cyprus',
    'Country_Ru': 'Кипр',
    'ISO': 'CY',
    'China': '塞浦路斯',
    'Alias': [
      'Cyprus',
      'Кипр',
      'Республика Кипр',
      'Cyprus'
    ]
  },
  {
    'Country': 'Czech Republic',
    'Country_Ru': 'Чехия',
    'ISO': 'CZ',
    'China': '捷克',
    'Alias': [
      'Czechia',
      'Czech Republic',
      'Чехия'

    ]
  },
  {
    'Country': 'Denmark',
    'Country_Ru': 'Дания',
    'ISO': 'DK',
    'China': '丹麦',
    'Alias': [
      'Denmark',
      'Дания',
      'Королевство Дания',
      'DK'
    ]
  },
  {
    'Country': 'Djibouti',
    'Country_Ru': 'Джибути',
    'ISO': 'DJ',
    'China': '吉布提',
    'Alias': [
      'Djibouti',
      'Джибути',
      'Республика Джибути'
    ]
  },
  {
    'Country': 'Dominica',
    'Country_Ru': 'Доминика',
    'ISO': 'DM',
    'China': '多米尼加',
    'Alias': [
      'Dominica',
      'Доминика',
      'Содружество Доминики',
      'DM'
    ]
  },
  {
    'Country': 'Dominican Republic',
    'Country_Ru': 'Доминиканская Республика',
    'ISO': 'DO',
    'China': '多明尼加共和国',
    'Alias': [
      'Dominican Republic',
      'Доминиканская Республика'
    ]
  },
  {
    'Country': 'Ecuador',
    'Country_Ru': 'Эквадор',
    'ISO': 'EC',
    'China': '厄瓜多尔',
    'Alias': [
      'Ecuador',
      'Эквадор',
      'Республика Эквадор'
    ]
  },
  {
    'Country': 'Egypt',
    'Country_Ru': 'Египет',
    'ISO': 'EG',
    'China': '埃及',
    'Alias': [
      'Egypt',
      'Египет',
      'Арабская Республика Египет'
    ]
  },
  {
    'Country': 'El Salvador',
    'Country_Ru': 'Сальвадор',
    'ISO': 'SV',
    'China': '萨尔瓦多',
    'Alias': [
      'El Salvador',
      'Сальвадор',
      'SV'
    ]
  },
  {
    'Country': 'Equatorial Guinea',
    'Country_Ru': 'Экваториальная Гвинея',
    'ISO': 'GQ',
    'China': '赤道几内亚',
    'Alias': [
      'Equatorial Guinea',
      'Экваториальная Гвинея',
      'Guinea',
      'Гвинея',
      'GQ'
    ]
  },
  {
    'Country': 'Eritrea',
    'Country_Ru': 'Эритрея',
    'ISO': 'ER',
    'China': '厄立特里亚',
    'Alias': [
      'Eritrea',
      'Эритрея'
    ]
  },
  {
    'Country': 'Estonia',
    'Country_Ru': 'Эстония',
    'ISO': 'EE',
    'China': '爱沙尼亚',
    'Alias': [
      'Estonia',
      'Эстония',
      'Эстонская Республика',
      'EE'
    ]
  },
  {
    'Country': 'Eswatini',
    'Country_Ru': 'Эсватини',
    'ISO': 'SZ',
    'China': '埃斯瓦蒂尼',
    'Alias': [
      'Eswatini',
      'Эсватини',
      'Королевство Эсватини',
      'Swaziland',
      'SZ'
    ]
  },
  {
    'Country': 'Ethiopia',
    'Country_Ru': 'Эфиопия',
    'ISO': 'ET',
    'China': '埃塞俄比亚',
    'Alias': [
      'Ethiopia',
      'Эфиопия',
      'Федеративная Демократическая Республика Эфиопия'
    ]
  },
  {
    'Country': 'Falkland Islands (Malvinas)',
    'Country_Ru': 'Фолклендские острова',
    'ISO': 'FK',
    'China': '福克兰群岛（马尔维纳斯群岛）',
    'Alias': [
      'Falkland Islands (Malvinas)',
      'Falkland Islands (Malvinas)',
      'Фолклендские острова',
      'FK'
    ]
  },
  {
    'Country': 'Faroe Islands',
    'Country_Ru': 'Фарерские острова',
    'ISO': 'FO',
    'China': '法罗群岛',
    'Alias': [
      'Faroe Islands',
      'Фарерские острова',
      'FO'
    ]
  },
  {
    'Country': 'Fiji',
    'Country_Ru': 'Фиджи',
    'ISO': 'FJ',
    'China': '斐济',
    'Alias': [
      'Fiji',
      'Фиджи',
      'Республика островов Фиджи',
      'FJ'
    ]
  },
  {
    'Country': 'Finland',
    'Country_Ru': 'Финляндия',
    'ISO': 'FI',
    'China': '芬兰',
    'Alias': [
      'Finland',
      'Финляндия',
      'Финляндская Республика'
    ]
  },
  {
    'Country': 'France',
    'Country_Ru': 'Франция',
    'ISO': 'FR',
    'China': '法国',
    'Alias': [
      'France',
      'Франция',
      'Французская Республика'
    ]
  },
  {
    'Country': 'French Guiana',
    'Country_Ru': 'Французская Гвиана',
    'ISO': 'GF',
    'China': '法属圭亚那',
    'Alias': [
      'French Guiana',
      'Французская Гвиана',
      'GF'
    ]
  },
  {
    'Country': 'French Polynesia',
    'Country_Ru': 'Французская Полинезия',
    'ISO': 'PF',
    'China': '法国波利尼西亚',
    'Alias': [
      'French Polynesia',
      'Французская Полинезия',
      'PF'
    ]
  },
  {
    'Country': 'French Southern Territories',
    'Country_Ru': 'Французские Южные и Антарктические территории',
    'ISO': 'TF',
    'China': '法国南部地区',
    'Alias': [
      'French Southern Territories',
      'Французские Южные и Антарктические территории',
      'French Southern and Antarctic Lands',
      'French Southern Lands',
      'TF'
    ]
  },
  {
    'Country': 'Gabon',
    'Country_Ru': 'Габон',
    'ISO': 'GA',
    'China': '加蓬',
    'Alias': [
      'Gabon',
      'Габон',
      'Габонская Республика'
    ]
  },
  {
    'Country': 'Gambia',
    'Country_Ru': 'Гамбия',
    'ISO': 'GM',
    'China': '冈比亚',
    'Alias': [
      'Gambia',
      'Гамбия',
      'Республика Гамбия',
      'GM'
    ]
  },
  {
    'Country': 'Georgia',
    'Country_Ru': 'Грузия',
    'ISO': 'GE',
    'China': '乔治亚州',
    'Alias': [
      'Georgia',
      'Грузия'
    ]
  },
  {
    'Country': 'Germany',
    'Country_Ru': 'Германия',
    'ISO': 'DE',
    'China': '德国',
    'Alias': [
      'Germany',
      'Германия',
      'Федеративная Республика Германия',
      'DE'
    ]
  },
  {
    'Country': 'Ghana',
    'Country_Ru': 'Гана',
    'ISO': 'GH',
    'China': '加纳',
    'Alias': [
      'Ghana',
      'Гана',
      'Республика Гана'
    ]
  },
  {
    'Country': 'Gibraltar',
    'Country_Ru': 'Гибралтар',
    'ISO': 'GI',
    'China': '直布罗陀',
    'Alias': [
      'Gibraltar',
      'Гибралтар'
    ]
  },
  {
    'Country': 'Greece',
    'Country_Ru': 'Греция',
    'ISO': 'GR',
    'China': '希腊',
    'Alias': [
      'Greece',
      'Греция',
      'Греческая Республика'
    ]
  },
  {
    'Country': 'Greenland',
    'Country_Ru': 'Гренландия',
    'ISO': 'GL',
    'China': '格陵兰',
    'Alias': [
      'Greenland',
      'Гренландия',
      'GL'
    ]
  },
  {
    'Country': 'Grenada',
    'Country_Ru': 'Гренада',
    'ISO': 'GD',
    'China': '格林纳达',
    'Alias': [
      'Grenada',
      'Гренада',
      'GD'
    ]
  },
  {
    'Country': 'Guadeloupe',
    'Country_Ru': 'Гваделупа',
    'ISO': 'GP',
    'China': '瓜德罗普',
    'Alias': [
      'Guadeloupe',
      'Гваделупа',
      'GP'
    ]
  },
  {
    'Country': 'Guam',
    'Country_Ru': 'Гуам',
    'ISO': 'GU',
    'China': '关岛',
    'Alias': [
      'Guam',
      'Гуам'
    ]
  },
  {
    'Country': 'Guatemala',
    'Country_Ru': 'Гватемала',
    'ISO': 'GT',
    'China': '危地马拉',
    'Alias': [
      'Guatemala',
      'Гватемала',
      'Республика Гватемала',
      'GT'
    ]
  },
  {
    'Country': 'Guernsey',
    'Country_Ru': 'Гернси',
    'ISO': 'GG',
    'China': '根西岛',
    'Alias': [
      'Guernsey',
      'Гернси',
      'GG'
    ]
  },
  {
    'Country': 'Guinea',
    'Country_Ru': 'Гвинея',
    'ISO': 'GN',
    'China': '几内亚',
    'Alias': [
      'Guinea',
      'Гвинея',
      'Гвинейская Республика',
      'GN'
    ]
  },
  {
    'Country': 'Guinea-Bissau',
    'Country_Ru': 'Гвинея-Бисау',
    'ISO': 'GW',
    'China': '几内亚 - 比索',
    'Alias': [
      'Guinea-Bissau',
      'Гвинея-Бисау',
      'GW'
    ]
  },
  {
    'Country': 'Guyana',
    'Country_Ru': 'Гайана',
    'ISO': 'GY',
    'China': '圭亚那',
    'Alias': [
      'Guyana',
      'Гайана',
      'Республика Гайана',
      'GY'
    ]
  },
  {
    'Country': 'Haiti',
    'Country_Ru': 'Гаити',
    'ISO': 'HT',
    'China': '海地',
    'Alias': [
      'Haiti',
      'Гаити',
      'Республика Гаити',
      'HT'
    ]
  },
  {
    'Country': 'Heard Island and McDonald Islands',
    'Country_Ru': 'Остров Херд и острова Макдональд',
    'ISO': 'HM',
    'China': '听到的岛和麦当劳群岛',
    'Alias': [
      'Heard Island and McDonald Islands',
      'Heard Island & Mcdonald Islands',
      'Остров Херд и острова Макдональд',
      'HM'
    ]
  },
  {
    'Country': 'Holy See',
    'Country_Ru': 'Святой Престол',
    'ISO': 'VA',
    'China': '教廷',
    'Alias': [
      'Holy See',
      'Holy See (Vatican City State)',
      'Святой Престол',
      'Холи си',
      'VA'
    ]
  },
  {
    'Country': 'Honduras',
    'Country_Ru': 'Гондурас',
    'ISO': 'HN',
    'China': '洪都拉斯',
    'Alias': [
      'Honduras',
      'Гондурас',
      'Республика Гондурас',
      'HN'
    ]
  },
  {
    'Country': 'Hong Kong',
    'Country_Ru': 'Гонконг',
    'ISO': 'HK',
    'China': '香港',
    'Alias': [
      'Hong Kong',
      'Гонконг',
      'HK'
    ]
  },
  {
    'Country': 'Hungary',
    'Country_Ru': 'Венгрия',
    'ISO': 'HU',
    'China': '匈牙利',
    'Alias': [
      'Hungary',
      'Венгрия',
      'Венгерская Республика'
    ]
  },
  {
    'Country': 'Iceland',
    'Country_Ru': 'Исландия',
    'ISO': 'IS',
    'China': '冰岛',
    'Alias': [
      'Iceland',
      'Исландия',
      'Республика Исландия',
      'IS'
    ]
  },
  {
    'Country': 'India',
    'Country_Ru': 'Индия',
    'ISO': 'IN',
    'China': '印度',
    'Alias': [
      'India',
      'Индия',
      'Республика Индия'
    ]
  },
  {
    'Country': 'Indonesia',
    'Country_Ru': 'Индонезия',
    'ISO': 'ID',
    'China': '印度尼西亚',
    'Alias': [
      'Indonesia',
      'Индонезия',
      'Республика Индонезия',
      'ID'
    ]
  },
  {
    'Country': 'Iran (Islamic Republic of)',
    'Country_Ru': 'Иран',
    'ISO': 'IR',
    'China': '伊朗（伊斯兰共和国）',
    'Alias': [
      'Iran (Islamic Republic of)',
      'Iran, Islamic Republic Of',
      'Иран'
    ]
  },
  {
    'Country': 'Iraq',
    'Country_Ru': 'Ирак',
    'ISO': 'IQ',
    'China': '伊拉克',
    'Alias': [
      'Iraq',
      'Ирак',
      'Республика Ирак',
      'IQ'
    ]
  },
  {
    'Country': 'Ireland',
    'Country_Ru': 'Ирландия',
    'ISO': 'IE',
    'China': '爱尔兰',
    'Alias': [
      'Ireland',
      'Ирландия',
      'IE'
    ]
  },
  {
    'Country': 'Isle of Man',
    'Country_Ru': 'Остров Мэн',
    'ISO': 'IM',
    'China': '人岛',
    'Alias': [
      'Isle of Man',
      'Остров Мэн',
      'IM'
    ]
  },
  {
    'Country': 'Israel',
    'Country_Ru': 'Израиль',
    'ISO': 'IL',
    'China': '以色列',
    'Alias': [
      'Israel',
      'Израиль',
      'Государство Израиль',
      'IL'
    ]
  },
  {
    'Country': 'Italy',
    'Country_Ru': 'Италия',
    'ISO': 'IT',
    'China': '意大利',
    'Alias': [
      'Italy',
      'Италия',
      'Итальянская Республика'
    ]
  },
  {
    'Country': 'Jamaica',
    'Country_Ru': 'Ямайка',
    'ISO': 'JM',
    'China': '牙买加',
    'Alias': [
      'Jamaica',
      'Ямайка',
      'JM'
    ]
  },
  {
    'Country': 'Japan',
    'Country_Ru': 'Япония',
    'ISO': 'JP',
    'China': '日本',
    'Alias': [
      'Japan',
      'Япония',
      'JP'
    ]
  },
  {
    'Country': 'Jersey',
    'Country_Ru': 'Джерси',
    'ISO': 'JE',
    'China': '球衣',
    'Alias': [
      'Jersey',
      'Джерси'
    ]
  },
  {
    'Country': 'Jordan',
    'Country_Ru': 'Иордания',
    'ISO': 'JO',
    'China': '约旦',
    'Alias': [
      'Jordan',
      'Иордания',
      'Иорданское Хашимитское Королевство'
    ]
  },
  {
    'Country': 'Kazakhstan',
    'Country_Ru': 'Казахстан',
    'ISO': 'KZ',
    'China': '哈萨克斯坦',
    'Alias': [
      'Kazakhstan',
      'Казахстан',
      'Республика Казахстан',
      'KZ'
    ]
  },
  {
    'Country': 'Kenya',
    'Country_Ru': 'Кения',
    'ISO': 'KE',
    'China': '肯尼亚',
    'Alias': [
      'Kenya',
      'Кения',
      'Республика Кения'
    ]
  },
  {
    'Country': 'Kiribati',
    'Country_Ru': 'Кирибати',
    'ISO': 'KI',
    'China': '基里巴蒂',
    'Alias': [
      'Kiribati',
      'Кирибати',
      'Республика Кирибати'
    ]
  },
  {
    'Country': 'Korea (Democratic People\'s Republic of)',
    'Country_Ru': 'Северная Корея',
    'ISO': 'KP',
    'China': '韩国（民主人民共和国）',
    'Alias': [
      'Korea (Democratic People\'s Republic of)',
      'North Korea',
      'Korea, Democratic People\'s Republic of',
      'Северная Корея',
      'КНДР',
      'Корея',
      'KP'
    ]
  },
  {
    'Country': 'Korea, Republic of',
    'Country_Ru': 'Южная Корея',
    'ISO': 'KR',
    'China': '韩国，共和国',
    'Alias': [
      'Korea, Republic of',
      'Korea',
      'Южная Корея',
      'Республика Корея',
      'Корея',
      'KR'
    ]
  },
  {
    'Country': 'Kuwait',
    'Country_Ru': 'Кувейт',
    'ISO': 'KW',
    'China': '科威特',
    'Alias': [
      'Kuwait',
      'Кувейт',
      'Государство Кувейт',
      'KW'
    ]
  },
  {
    'Country': 'Kyrgyzstan',
    'Country_Ru': 'Киргизия',
    'ISO': 'KG',
    'China': '吉尔吉斯斯坦',
    'Alias': [
      'Kyrgyzstan',
      'Киргизия',
      'Киргизская Республика',
      'KG'
    ]
  },
  {
    'Country': 'Lao People\'s Democratic Republic',
    'Country_Ru': 'Лаос',
    'ISO': 'LA',
    'China': '老挝人民民主共和国',
    'Alias': [
      'Lao People\'s Democratic Republic',
      'Lao People"s Democratic Republic',
      'Лаос'
    ]
  },
  {
    'Country': 'Latvia',
    'Country_Ru': 'Латвия',
    'ISO': 'LV',
    'China': '拉脱维亚',
    'Alias': [
      'Latvia',
      'Латвия',
      'Латвийская Республика',
      'LV'
    ]
  },
  {
    'Country': 'Lebanon',
    'Country_Ru': 'Ливан',
    'ISO': 'LB',
    'China': '黎巴嫩',
    'Alias': [
      'Lebanon',
      'Ливан',
      'Ливанская Республика',
      'LB'
    ]
  },
  {
    'Country': 'Lesotho',
    'Country_Ru': 'Лесото',
    'ISO': 'LS',
    'China': '莱索托',
    'Alias': [
      'Lesotho',
      'Лесото',
      'Королевство Лесото',
      'LS'
    ]
  },
  {
    'Country': 'Liberia',
    'Country_Ru': 'Либерия',
    'ISO': 'LR',
    'China': '利比里亚',
    'Alias': [
      'Liberia',
      'Либерия',
      'Республика Либерия',
      'LR'
    ]
  },
  {
    'Country': 'Libya',
    'Country_Ru': 'Ливия',
    'ISO': 'LY',
    'China': '利比亚',
    'Alias': [
      'Libya',
      'Libyan Arab Jamahiriya',
      'Ливия',
      'LY'
    ]
  },
  {
    'Country': 'Liechtenstein',
    'Country_Ru': 'Лихтенштейн',
    'ISO': 'LI',
    'China': '列支敦士登',
    'Alias': [
      'Liechtenstein',
      'Лихтенштейн',
      'Княжество Лихтенштейн'
    ]
  },
  {
    'Country': 'Lithuania',
    'Country_Ru': 'Литва',
    'ISO': 'LT',
    'China': '立陶宛',
    'Alias': [
      'Lithuania',
      'Литва',
      'Литовская Республика',
      'LT'
    ]
  },
  {
    'Country': 'Luxembourg',
    'Country_Ru': 'Люксембург',
    'ISO': 'LU',
    'China': '卢森堡',
    'Alias': [
      'Luxembourg',
      'Люксембург',
      'Великое Герцогство Люксембург'
    ]
  },
  {
    'Country': 'Macao',
    'Country_Ru': 'Макао',
    'ISO': 'MO',
    'China': '澳门',
    'Alias': [
      'Macao',
      'Макао',
      'Специальный административный регион Китая Макао',
      'MO'
    ]
  },
  {
    'Country': 'Madagascar',
    'Country_Ru': 'Мадагаскар',
    'ISO': 'MG',
    'China': '马达加斯加',
    'Alias': [
      'Madagascar',
      'Мадагаскар',
      'Республика Мадагаскар',
      'MG'
    ]
  },
  {
    'Country': 'Malawi',
    'Country_Ru': 'Малави',
    'ISO': 'MW',
    'China': '马拉维',
    'Alias': [
      'Malawi',
      'Малави',
      'Республика Малави',
      'MW'
    ]
  },
  {
    'Country': 'Malaysia',
    'Country_Ru': 'Малайзия',
    'ISO': 'MY',
    'China': '马来西亚',
    'Alias': [
      'Malaysia',
      'MY',
      'Малайзия'
    ]
  },
  {
    'Country': 'Maldives',
    'Country_Ru': 'Мальдивы',
    'ISO': 'MV',
    'China': '马尔代夫',
    'Alias': [
      'Maldives',
      'Мальдивы',
      'Мальдивская Республика',
      'MV'
    ]
  },
  {
    'Country': 'Mali',
    'Country_Ru': 'Мали',
    'ISO': 'ML',
    'China': '马里',
    'Alias': [
      'Mali',
      'Мали',
      'Республика Мали',
      'ML'
    ]
  },
  {
    'Country': 'Malta',
    'Country_Ru': 'Мальта',
    'ISO': 'MT',
    'China': '马耳他',
    'Alias': [
      'Malta',
      'Мальта',
      'Республика Мальта',
      'MT'
    ]
  },
  {
    'Country': 'Marshall Islands',
    'Country_Ru': 'Маршалловы острова',
    'ISO': 'MH',
    'China': '马绍尔群岛',
    'Alias': [
      'Marshall Islands',
      'Маршалловы острова',
      'MH'
    ]
  },
  {
    'Country': 'Martinique',
    'Country_Ru': 'Мартиника',
    'ISO': 'MQ',
    'China': '马提尼克',
    'Alias': [
      'Martinique',
      'Мартиника',
      'MQ'
    ]
  },
  {
    'Country': 'Mauritania',
    'Country_Ru': 'Мавритания',
    'ISO': 'MR',
    'China': '毛里塔尼亚',
    'Alias': [
      'Mauritania',
      'Мавритания',
      'Исламская Республика Мавритания',
      'MR'
    ]
  },
  {
    'Country': 'Mauritius',
    'Country_Ru': 'Маврикий',
    'ISO': 'MU',
    'China': '毛里求斯',
    'Alias': [
      'Mauritius',
      'Маврикий',
      'Республика Маврикий',
      'MU'
    ]
  },
  {
    'Country': 'Mayotte',
    'Country_Ru': 'Майотта',
    'ISO': 'YT',
    'China': 'Mayotte',
    'Alias': [
      'Mayotte',
      'Майотта',
      'YT'
    ]
  },
  {
    'Country': 'Mexico',
    'Country_Ru': 'Мексика',
    'ISO': 'MX',
    'China': '墨西哥',
    'Alias': [
      'Mexico',
      'Мексика',
      'Мексиканские Соединенные Штаты',
      'MX'
    ]
  },
  {
    'Country': 'Micronesia (Federated States of)',
    'Country_Ru': 'Микронезия',
    'ISO': 'FM',
    'China': '密克罗尼西亚（联邦的状态）',
    'Alias': [
      'Micronesia (Federated States of)',
      'Micronesia, Federated States Of',
      'Федеративные Штаты Микронезии',
      'Микронезия',
      'FM'
    ]
  },
  {
    'Country': 'Moldova, Republic of',
    'Country_Ru': 'Молдова',
    'ISO': 'MD',
    'China': '摩尔多瓦，共和国',
    'Alias': [
      'Moldova, Republic of',
      'Молдова,',
      'Республика Республика Молдова',
      'Moldova',
      'MD'
    ]
  },
  {
    'Country': 'Monaco',
    'Country_Ru': 'Монако',
    'ISO': 'MC',
    'China': '摩纳哥',
    'Alias': [
      'Monaco',
      'Монако',
      'Княжество Монако',
      'MC'
    ]
  },
  {
    'Country': 'Mongolia',
    'Country_Ru': 'Монголия',
    'ISO': 'MN',
    'China': '蒙古',
    'Alias': [
      'Mongolia',
      'Монголия',
      'MN'
    ]
  },
  {
    'Country': 'Montenegro',
    'Country_Ru': 'Черногория',
    'ISO': 'ME',
    'China': '黑山',
    'Alias': [
      'Montenegro',
      'Черногория',
      'Республика Черногория',
      'ME'
    ]
  },
  {
    'Country': 'Montserrat',
    'Country_Ru': 'Монтсеррат',
    'ISO': 'MS',
    'China': '蒙特塞拉特',
    'Alias': [
      'Montserrat',
      'Монтсеррат',
      'MS'
    ]
  },
  {
    'Country': 'Morocco',
    'Country_Ru': 'Марокко',
    'ISO': 'MA',
    'China': '摩洛哥',
    'Alias': [
      'Morocco',
      'Марокко',
      'Королевство Марокко',
      'MA'
    ]
  },
  {
    'Country': 'Mozambique',
    'Country_Ru': 'Мозамбик',
    'ISO': 'MZ',
    'China': '莫桑比克',
    'Alias': [
      'Mozambique',
      'Мозамбик',
      'Республика Мозамбик',
      'MZ'
    ]
  },
  {
    'Country': 'Myanmar',
    'Country_Ru': 'Мьянма',
    'ISO': 'MM',
    'China': '缅甸',
    'Alias': [
      'Myanmar',
      'Мьянма',
      'Союз Мьянма',
      'Burma',
      'MM'
    ]
  },
  {
    'Country': 'Namibia',
    'Country_Ru': 'Намибия',
    'ISO': 'NA',
    'China': '纳米比亚',
    'Alias': [
      'Namibia',
      'Намибия',
      'Республика Намибия'
    ]
  },
  {
    'Country': 'Nauru',
    'Country_Ru': 'Науру',
    'ISO': 'NR',
    'China': '瑙鲁',
    'Alias': [
      'Nauru',
      'Науру',
      'Республика Науру',
      'NR'
    ]
  },
  {
    'Country': 'Nepal',
    'Country_Ru': 'Непал',
    'ISO': 'NP',
    'China': '尼泊尔',
    'Alias': [
      'Nepal',
      'Непал',
      'Королевство Непал',
      'NP'
    ]
  },
  {
    'Country': 'Netherlands',
    'Country_Ru': 'Нидерланды',
    'ISO': 'NL',
    'China': '荷兰',
    'Alias': [
      'Netherlands',
      'Нидерланды',
      'Королевство Нидерландов',
      'NL'
    ]
  },
  {
    'Country': 'New Caledonia',
    'Country_Ru': 'Новая Каледония',
    'ISO': 'NC',
    'China': '新喀里多尼亚',
    'Alias': [
      'New Caledonia',
      'Новая Каледония',
      'NC'
    ]
  },
  {
    'Country': 'New Zealand',
    'Country_Ru': 'Новая Зеландия',
    'ISO': 'NZ',
    'China': '新西兰',
    'Alias': [
      'New Zealand',
      'Новая Зеландия',
      'Зеландия',
      'NZ'
    ]
  },
  {
    'Country': 'Nicaragua',
    'Country_Ru': 'Никарагуа',
    'ISO': 'NI',
    'China': '尼加拉瓜',
    'Alias': [
      'Nicaragua',
      'Никарагуа',
      'Республика Никарагуа'
    ]
  },
  {
    'Country': 'Niger',
    'Country_Ru': 'Нигер',
    'ISO': 'NE',
    'China': '尼日尔',
    'Alias': [
      'Niger',
      'Нигер',
      'Республика Нигер',
      'NE'
    ]
  },
  {
    'Country': 'Nigeria',
    'Country_Ru': 'Нигерия',
    'ISO': 'NG',
    'China': '尼日利亚',
    'Alias': [
      'Nigeria',
      'Нигерия',
      'Федеративная Республика Нигерия',
      'NG'
    ]
  },
  {
    'Country': 'Niue',
    'Country_Ru': 'Ниуэ',
    'ISO': 'NU',
    'China': '尼',
    'Alias': [
      'Niue',
      'Ниуэ',
      'Республика Ниуэ',
      'NU'
    ]
  },
  {
    'Country': 'Norfolk Island',
    'Country_Ru': 'Остров Норфолк',
    'ISO': 'NF',
    'China': '诺福克岛',
    'Alias': [
      'Norfolk Island',
      'Остров Норфолк',
      'Норфолк',
      'NF'
    ]
  },
  {
    'Country': 'North Macedonia',
    'Country_Ru': 'Северная Македония',
    'ISO': 'MK',
    'China': '北马其顿',
    'Alias': [
      'North Macedonia',
      'Macedonia',
      'Macedonia, the Former Yugoslav Republic of',
      'Северная Македония',
      'Македония',
      'MK'
    ]
  },
  {
    'Country': 'Northern Mariana Islands',
    'Country_Ru': 'Северные Марианские острова',
    'ISO': 'MP',
    'China': '北玛丽安娜群岛',
    'Alias': [
      'Northern Mariana Islands',
      'Северные Марианские острова',
      'MP'
    ]
  },
  {
    'Country': 'Norway',
    'Country_Ru': 'Норвегия',
    'ISO': 'NO',
    'China': '挪威',
    'Alias': [
      'Norway',
      'Норвегия',
      'Королевство Норвегия'
    ]
  },
  {
    'Country': 'Oman',
    'Country_Ru': 'Оман',
    'ISO': 'OM',
    'China': '阿曼',
    'Alias': [
      'Oman',
      'Оман',
      'Султанат Оман'
    ]
  },
  {
    'Country': 'Pakistan',
    'Country_Ru': 'Пакистан',
    'ISO': 'PK',
    'China': '巴基斯坦',
    'Alias': [
      'Pakistan',
      'Пакистан',
      'Исламская Республика Пакистан',
      'PK'
    ]
  },
  {
    'Country': 'Palau',
    'Country_Ru': 'Палау',
    'ISO': 'PW',
    'China': '帕劳',
    'Alias': [
      'Palau',
      'Палау',
      'Республика Палау',
      'PW'
    ]
  },
  {
    'Country': 'Palestine, State of',
    'Country_Ru': 'Палестина',
    'ISO': 'PS',
    'China': '巴勒斯坦，的状态',
    'Alias': [
      'Palestine, State of',
      'Palestinian Territory, Occupied',
      'Palestine, State of',
      'Палестина',
      'PS'
    ]
  },
  {
    'Country': 'Panama',
    'Country_Ru': 'Панама',
    'ISO': 'PA',
    'China': '巴拿马',
    'Alias': [
      'Panama',
      'Панама',
      'Республика Панама'
    ]
  },
  {
    'Country': 'Papua New Guinea',
    'Country_Ru': 'Папуа-Новая Гвинея',
    'ISO': 'PG',
    'China': '巴布亚新几内亚',
    'Alias': [
      'Papua New Guinea',
      'Папуа-Новая Гвинея',
      'Гвинея',
      'Новая Гвинея',
      'PG'
    ]
  },
  {
    'Country': 'Paraguay',
    'Country_Ru': 'Парагвай',
    'ISO': 'PY',
    'China': '巴拉圭',
    'Alias': [
      'Paraguay',
      'Парагвай',
      'Республика Парагвай',
      'PY'
    ]
  },
  {
    'Country': 'Peru',
    'Country_Ru': 'Перу',
    'ISO': 'PE',
    'China': '秘鲁',
    'Alias': [
      'Peru',
      'Перу',
      'Республика Перу'
    ]
  },
  {
    'Country': 'Philippines',
    'Country_Ru': 'Филиппины',
    'ISO': 'PH',
    'China': '菲律宾',
    'Alias': [
      'Philippines',
      'Филиппины',
      'Республика Филиппины'
    ]
  },
  {
    'Country': 'Pitcairn',
    'Country_Ru': 'Острова Питкэрн',
    'ISO': 'PN',
    'China': '皮特凯恩',
    'Alias': [
      'Pitcairn',
      'Острова Питкэрн',
      'PN'
    ]
  },
  {
    'Country': 'Poland',
    'Country_Ru': 'Польша',
    'ISO': 'PL',
    'China': '波兰',
    'Alias': [
      'Poland',
      'Польша',
      'Республика Польша',
      'PL'
    ]
  },
  {
    'Country': 'Portugal',
    'Country_Ru': 'Португалия',
    'ISO': 'PT',
    'China': '葡萄牙',
    'Alias': [
      'Portugal',
      'Португалия',
      'Португальская Республика',
      'PT'
    ]
  },
  {
    'Country': 'Puerto Rico',
    'Country_Ru': 'Пуэрто-Рико',
    'ISO': 'PR',
    'China': '波多黎各',
    'Alias': [
      'Puerto Rico',
      'Пуэрто-Рико',
      'PR'
    ]
  },
  {
    'Country': 'Qatar',
    'Country_Ru': 'Катар',
    'ISO': 'QA',
    'China': '卡塔尔',
    'Alias': [
      'Qatar',
      'Катар',
      'Государство Катар'
    ]
  },
  {
    'Country': 'Réunion',
    'Country_Ru': 'Реюньон',
    'ISO': 'RE',
    'China': '团圆',
    'Alias': [
      'Réunion',
      'Reunion',
      'Реюньон'
    ]
  },
  {
    'Country': 'Romania',
    'Country_Ru': 'Румыния',
    'ISO': 'RO',
    'China': '罗马尼亚',
    'Alias': [
      'Romania',
      'Румыния'
    ]
  },
  {
    'Country': 'Russia',
    'Country_Ru': 'Россия',
    'ISO': 'RU',
    'China': '俄罗斯联邦',
    'Alias': [
      'Russia',
      'Russian Federation',
      'Россия',
      'Российская Федерация'
    ]
  },
  {
    'Country': 'Rwanda',
    'Country_Ru': 'Руанда',
    'ISO': 'RW',
    'China': '卢旺达',
    'Alias': [
      'Rwanda',
      'Руанда',
      'Руандийская Республика'
    ]
  },
  {
    'Country': 'Saint Barthélemy',
    'Country_Ru': 'Сен-Бартелеми',
    'ISO': 'BL',
    'China': '圣巴塞莱米',
    'Alias': [
      'Saint Barthélemy',
      'Saint Barthelemy',
      'Сен-Бартелеми',
      'BL'
    ]
  },
  {
    'Country': 'Saint Helena, Ascension and Tristan da Cunha',
    'Country_Ru': 'Остров Святой Елены',
    'ISO': 'SH',
    'China': '圣赫勒拿岛，阿森松和特里斯坦达库尼亚',
    'Alias': [
      'Saint Helena, Ascension and Tristan da Cunha',
      'Saint Helena',
      'Saint Helena, Ascension and Tristan da Cunha',
      'Остров Святой Елены',
      'SH'
    ]
  },
  {
    'Country': 'Saint Kitts and Nevis',
    'Country_Ru': 'Сент-Китс и Невис',
    'ISO': 'KN',
    'China': '圣基茨和尼维斯',
    'Alias': [
      'Saint Kitts and Nevis',
      'Сент-Китс и Невис',
      'KN'
    ]
  },
  {
    'Country': 'Saint Lucia',
    'Country_Ru': 'Сент-Люсия',
    'ISO': 'LC',
    'China': '圣卢西亚',
    'Alias': [
      'Saint Lucia',
      'Сент-Люсия',
      'LC'
    ]
  },
  {
    'Country': 'Saint Martin (French part)',
    'Country_Ru': 'Сен-Мартен',
    'ISO': 'MF',
    'China': '圣马丁（法国部分）',
    'Alias': [
      'Saint Martin (French part)',
      'Saint Martin',
      'Сен-Мартен',
      'MF'
    ]
  },
  {
    'Country': 'Saint Pierre and Miquelon',
    'Country_Ru': 'Сен-Пьер и Микелон',
    'ISO': 'PM',
    'China': '圣皮埃尔和米克隆',
    'Alias': [
      'Saint Pierre and Miquelon',
      'Сен-Пьер и Микелон',
      'PM'
    ]
  },
  {
    'Country': 'Saint Vincent and the Grenadines',
    'Country_Ru': 'Сент-Винсент и Гренадины',
    'ISO': 'VC',
    'China': '圣文森特和格林纳丁斯',
    'Alias': [
      'Saint Vincent and the Grenadines',
      'Saint Vincent And Grenadines',
      'Сент-Винсент и Гренадины',
      'VC'
    ]
  },
  {
    'Country': 'Samoa',
    'Country_Ru': 'Самоа',
    'ISO': 'WS',
    'China': '萨摩亚',
    'Alias': [
      'Samoa',
      'Самоа',
      'Независимое Государство Самоа',
      'WS'
    ]
  },
  {
    'Country': 'San Marino',
    'Country_Ru': 'Сан-Марино',
    'ISO': 'SM',
    'China': '圣马力诺',
    'Alias': [
      'San Marino',
      'Сан-Марино',
      'SM'
    ]
  },
  {
    'Country': 'Sao Tome and Principe',
    'Country_Ru': 'Сан-Томе и Принсипи',
    'ISO': 'ST',
    'China': '圣托马和普林西比',
    'Alias': [
      'Sao Tome and Principe',
      'Сан-Томе и Принсипи',
      'ST'
    ]
  },
  {
    'Country': 'Saudi Arabia',
    'Country_Ru': 'Саудовская Аравия',
    'ISO': 'SA',
    'China': '沙特阿拉伯',
    'Alias': [
      'Saudi Arabia',
      'Саудовская Аравия',
      'Аравия'
    ]
  },
  {
    'Country': 'Senegal',
    'Country_Ru': 'Сенегал',
    'ISO': 'SN',
    'China': '塞内加尔',
    'Alias': [
      'Senegal',
      'Сенегал',
      'Республика Сенегал',
      'SN'
    ]
  },
  {
    'Country': 'Serbia',
    'Country_Ru': 'Сербия',
    'ISO': 'RS',
    'China': '塞尔维亚',
    'Alias': [
      'Serbia',
      'Сербия',
      'Республика Сербия',
      'RS'
    ]
  },
  {
    'Country': 'Seychelles',
    'Country_Ru': 'Сейшелы',
    'ISO': 'SC',
    'China': '塞舌尔',
    'Alias': [
      'Seychelles',
      'Сейшелы',
      'Республика Сейшелы',
      'Сейшельские Острова',
      'SC'
    ]
  },
  {
    'Country': 'Sierra Leone',
    'Country_Ru': 'Сьерра-Леоне',
    'ISO': 'SL',
    'China': '塞拉利昂',
    'Alias': [
      'Sierra Leone',
      'Сьерра-Леоне',
      'SL'
    ]
  },
  {
    'Country': 'Singapore',
    'Country_Ru': 'Сингапур',
    'ISO': 'SG',
    'China': '新加坡',
    'Alias': [
      'Singapore',
      'Сингапур',
      'Республика Сингапур',
      'SG'
    ]
  },
  {
    'Country': 'Sint Maarten (Dutch part)',
    'Country_Ru': 'Синт-Мартен',
    'ISO': 'SX',
    'China': '圣马丁岛（荷兰部分）',
    'Alias': [
      'Sint Maarten (Dutch part)',
      'Синт-Мартен',
      'SX'
    ]
  },
  {
    'Country': 'Slovakia',
    'Country_Ru': 'Словакия',
    'ISO': 'SK',
    'China': '斯洛伐克',
    'Alias': [
      'Slovakia',
      'Словакия',
      'Словацкая Республика',
      'SK'
    ]
  },
  {
    'Country': 'Slovenia',
    'Country_Ru': 'Словения',
    'ISO': 'SI',
    'China': '斯洛文尼亚',
    'Alias': [
      'Slovenia',
      'Словения',
      'Республика Словения',
      'SI'
    ]
  },
  {
    'Country': 'Solomon Islands',
    'Country_Ru': 'Соломоновы острова',
    'ISO': 'SB',
    'China': '所罗门群岛',
    'Alias': [
      'Solomon Islands',
      'Соломоновы острова',
      'острова',
      'SB'
    ]
  },
  {
    'Country': 'Somalia',
    'Country_Ru': 'Сомали',
    'ISO': 'SO',
    'China': '索马里',
    'Alias': [
      'Somalia',
      'Сомали',
      'Сомалийская Республика'
    ]
  },
  {
    'Country': 'South Africa',
    'Country_Ru': 'Южная Африка',
    'ISO': 'ZA',
    'China': '南非',
    'Alias': [
      'South Africa',
      'Южная Африка',
      'ЮАР',
      'Южно-Африканская Республика',
      'ZA'
    ]
  },
  {
    'Country': 'South Georgia and the South Sandwich Islands',
    'Country_Ru': 'Южная Георгия и Южные Сандвичевы Острова',
    'ISO': 'GS',
    'China': '乔治亚州南部和南三明治群岛',
    'Alias': [
      'South Georgia and the South Sandwich Islands',
      'South Georgia And Sandwich Isl.',
      'Южная Георгия и Южные Сандвичевы Острова',
      'Сандвичевы Острова',
      'GS'
    ]
  },
  {
    'Country': 'South Sudan',
    'Country_Ru': 'Южный Судан',
    'ISO': 'SS',
    'China': '南苏丹',
    'Alias': [
      'South Sudan',
      'Южный Судан',
      'Судан',
      'SS'
    ]
  },
  {
    'Country': 'Spain',
    'Country_Ru': 'Испания',
    'ISO': 'ES',
    'China': '西班牙',
    'Alias': [
      'Spain',
      'Испания',
      'Королевство Испания',
      'ES'
    ]
  },
  {
    'Country': 'Sri Lanka',
    'Country_Ru': 'Шри-Ланка',
    'ISO': 'LK',
    'China': '斯里兰卡',
    'Alias': [
      'Sri Lanka',
      'Шри-Ланка',
      'LK'
    ]
  },
  {
    'Country': 'Sudan',
    'Country_Ru': 'Судан',
    'ISO': 'SD',
    'China': '苏丹',
    'Alias': [
      'Sudan',
      'Судан',
      'Республика Судан',
      'SD'
    ]
  },
  {
    'Country': 'Suriname',
    'Country_Ru': 'Суринам',
    'ISO': 'SR',
    'China': '苏里南',
    'Alias': [
      'Suriname',
      'Суринам',
      'Республика Суринам',
      'SR'
    ]
  },
  {
    'Country': 'Svalbard and Jan Mayen',
    'Country_Ru': 'Шпицберген и Ян-Майен',
    'ISO': 'SJ',
    'China': '斯瓦尔巴群岛和扬马延',
    'Alias': [
      'Svalbard and Jan Mayen',
      'Шпицберген и Ян-Майен',
      'Шпицберген',
      'Ян-Майен',
      'SJ'
    ]
  },
  {
    'Country': 'Sweden',
    'Country_Ru': 'Швеция',
    'ISO': 'SE',
    'China': '瑞典',
    'Alias': [
      'Sweden',
      'Швеция',
      'Королевство Швеция',
      'SE'
    ]
  },
  {
    'Country': 'Switzerland',
    'Country_Ru': 'Швейцария',
    'ISO': 'CH',
    'China': '瑞士',
    'Alias': [
      'Switzerland',
      'Швейцария',
      'Швейцарская Конфедерация',
      'CH'
    ]
  },
  {
    'Country': 'Syrian Arab Republic',
    'Country_Ru': 'Сирия',
    'ISO': 'SY',
    'China': '叙利亚阿拉伯共和国',
    'Alias': [
      'Syrian Arab Republic',
      'Сирия',
      'Сирийская Арабская Республика'
    ]
  },
  {
    'Country': 'Taiwan, Province of China',
    'Country_Ru': 'Тайвань',
    'ISO': 'TW',
    'China': '台湾，中国省',
    'Alias': [
      'Taiwan, Province of China',
      'Taiwan',
      'Тайвань',
      'TW'
    ]
  },
  {
    'Country': 'Tajikistan',
    'Country_Ru': 'Таджикистан',
    'ISO': 'TJ',
    'China': '塔吉克斯坦',
    'Alias': [
      'Tajikistan',
      'Таджикистан',
      'Республика Таджикистан',
      'TJ'
    ]
  },
  {
    'Country': 'Tanzania, United Republic of',
    'Country_Ru': 'Танзания',
    'ISO': 'TZ',
    'China': '坦桑尼亚，联合共和国',
    'Alias': [
      'Tanzania, United Republic of',
      'Tanzania',
      'Танзания',
      'TZ'
    ]
  },
  {
    'Country': 'Thailand',
    'Country_Ru': 'Таиланд',
    'ISO': 'TH',
    'China': '泰国',
    'Alias': [
      'Thailand',
      'Таиланд',
      'Королевство Таиланд',
      'Тайланд'
    ]
  },
  {
    'Country': 'Timor-Leste',
    'Country_Ru': 'Восточный Тимор',
    'ISO': 'TL',
    'China': '帝汶',
    'Alias': [
      'Timor-Leste',
      'Восточный Тимор',
      'Тимор-Лесте',
      'Тимор',
      'TL'
    ]
  },
  {
    'Country': 'Togo',
    'Country_Ru': 'Того',
    'ISO': 'TG',
    'China': '多哥',
    'Alias': [
      'Togo',
      'Того',
      'Тоголезская Республика',
      'TG'
    ]
  },
  {
    'Country': 'Tokelau',
    'Country_Ru': 'Токелау',
    'ISO': 'TK',
    'China': '托克劳',
    'Alias': [
      'Tokelau',
      'TK',
      'Токелау'
    ]
  },
  {
    'Country': 'Tonga',
    'Country_Ru': 'Тонга',
    'ISO': 'TO',
    'China': '汤加',
    'Alias': [
      'Tonga',
      'Тонга',
      'Королевство Тонга'
    ]
  },
  {
    'Country': 'Trinidad and Tobago',
    'Country_Ru': 'Тринидад и Тобаго',
    'ISO': 'TT',
    'China': '特立尼达和多巴哥',
    'Alias': [
      'Trinidad and Tobago',
      'Тринидад и Тобаго',
      'Тобаго',
      'TT'
    ]
  },
  {
    'Country': 'Tunisia',
    'Country_Ru': 'Тунис',
    'ISO': 'TN',
    'China': '突尼斯',
    'Alias': [
      'Tunisia',
      'Тунис',
      'Тунисская Республика',
      'TN'
    ]
  },
  {
    'Country': 'Türkiye',
    'Country_Ru': 'Турция',
    'ISO': 'TR',
    'China': '土耳其',
    'Alias': [
      'Türkiye',
      'Турция',
      'Турецкая Республика',
      'Turkey',
      'TR'
    ]
  },
  {
    'Country': 'Turkmenistan',
    'Country_Ru': 'Туркменистан',
    'ISO': 'TM',
    'China': '土库曼斯坦',
    'Alias': [
      'Turkmenistan',
      'Туркмения',
      'Туркменистан',
      'TM'
    ]
  },
  {
    'Country': 'Turks and Caicos Islands',
    'Country_Ru': 'Острова Теркс и Кайкос',
    'ISO': 'TC',
    'China': '特克斯和凯科斯群岛',
    'Alias': [
      'Turks and Caicos Islands',
      'Острова Теркс и Кайкос',
      'Теркс и Кайкос',
      'Кайкос',
      'TC'
    ]
  },
  {
    'Country': 'Tuvalu',
    'Country_Ru': 'Тувалу',
    'ISO': 'TV',
    'China': '图瓦卢',
    'Alias': [
      'Tuvalu',
      'Тувалу',
      'TV'
    ]
  },
  {
    'Country': 'Uganda',
    'Country_Ru': 'Уганда',
    'ISO': 'UG',
    'China': '乌干达',
    'Alias': [
      'Uganda',
      'Уганда',
      'Республика Уганда'
    ]
  },
  {
    'Country': 'Ukraine',
    'Country_Ru': 'Украина',
    'ISO': 'UA',
    'China': '乌克兰',
    'Alias': [
      'Ukraine',
      'Украина',
      'UA'
    ]
  },
  {
    'Country': 'United Arab Emirates',
    'Country_Ru': 'Объединенные Арабские Эмираты',
    'ISO': 'AE',
    'China': '阿拉伯联合酋长国',
    'Alias': [
      'United Arab Emirates',
      'Объединенные Арабские Эмираты',
      'ОАЭ',
      'UAE',
      'AE'
    ]
  },
  {
    'Country': 'United Kingdom of Great Britain and Northern Ireland',
    'Country_Ru': 'Великобритания',
    'ISO': 'GB',
    'China': '英国英国和北爱尔兰',
    'Alias': [
      'United Kingdom of Great Britain and Northern Ireland',
      'United Kingdom',
      'Великобритания',
      'UK',
      'GB'

    ]
  },
  {
    'Country': 'United States of America',
    'Country_Ru': 'Соединенные Штаты Америки',
    'ISO': 'US',
    'China': '美国',
    'Alias': [
      'United States of America',
      'United States',
      'Соединенные Штаты Америки',
      'США',
      'USA'
    ]
  },
  {
    'Country': 'United States Minor Outlying Islands',
    'Country_Ru': 'Внешние малые острова США',
    'ISO': 'UM',
    'China': '美国较小的外围岛屿',
    'Alias': [
      'United States Minor Outlying Islands',
      'United States Outlying Islands',
      'United States Minor Outlying Islands',
      'Внешние малые острова США',
      'малые острова США',
      'UM'
    ]
  },
  {
    'Country': 'Uruguay',
    'Country_Ru': 'Уругвай',
    'ISO': 'UY',
    'China': '乌拉圭',
    'Alias': [
      'Uruguay',
      'Уругвай',
      'Восточная Республика Уругвай',
      'UY'
    ]
  },
  {
    'Country': 'Uzbekistan',
    'Country_Ru': 'Узбекистан',
    'ISO': 'UZ',
    'China': '乌兹别克斯坦',
    'Alias': [
      'Uzbekistan',
      'Узбекистан',
      'Республика Узбекистан'
    ]
  },
  {
    'Country': 'Vanuatu',
    'Country_Ru': 'Вануату',
    'ISO': 'VU',
    'China': '瓦努阿图',
    'Alias': [
      'Vanuatu',
      'Вануату',
      'Республика Вануату',
      'VU'
    ]
  },
  {
    'Country': 'Venezuela (Bolivarian Republic of)',
    'Country_Ru': 'Венесуэла',
    'ISO': 'VE',
    'China': '委内瑞拉（玻利瓦尔共和国）',
    'Alias': [
      'Venezuela (Bolivarian Republic of)',
      'Венесуэла',
      'Боливарианская Республика Боливарийская Республика Венесуэла',
      'Venezuela',
      'Venezuela, Bolivarian Republic of'
    ]
  },
  {
    'Country': 'Viet Nam',
    'Country_Ru': 'Вьетнам',
    'ISO': 'VN',
    'China': '越南',
    'Alias': [
      'Viet Nam',
      'Вьетнам',
      'Социалистическая Республика Вьетнам',
      'Vietnam',
      'VN'
    ]
  },
  {
    'Country': 'Virgin Islands (British)',
    'Country_Ru': 'Британские Виргинские острова',
    'ISO': 'VG',
    'China': '维尔京群岛（英国）',
    'Alias': [
      'Virgin Islands (British)',
      'Virgin Islands, British',
      'Британские Виргинские острова',
      'Виргинские острова',
      'VG'
    ]
  },
  {
    'Country': 'Virgin Islands (U.S.)',
    'Country_Ru': 'Американские Виргинские острова',
    'ISO': 'VI',
    'China': '维尔京群岛（美国）',
    'Alias': [
      'Virgin Islands (U.S.)',
      'Virgin Islands, U.S.',
      'Американские Виргинские острова',
      'Виргинские острова'
    ]
  },
  {
    'Country': 'Wallis and Futuna',
    'Country_Ru': 'Уоллис и Футуна',
    'ISO': 'WF',
    'China': '瓦利斯和富图纳',
    'Alias': [
      'Wallis and Futuna',
      'Уоллис и Футуна',
      'WF'
    ]
  },
  {
    'Country': 'Western Sahara',
    'Country_Ru': 'Западная Сахара',
    'ISO': 'EH',
    'China': '西撒哈拉',
    'Alias': [
      'Western Sahara',
      'Western Sahara',
      'Западная Сахара',
      'Сахара',
      'EH'
    ]
  },
  {
    'Country': 'Yemen',
    'Country_Ru': 'Йемен',
    'ISO': 'YE',
    'China': '也门',
    'Alias': [
      'Yemen',
      'Йемен',
      'Йеменская Республика',
      'YE'
    ]
  },
  {
    'Country': 'Zambia',
    'Country_Ru': 'Замбия',
    'ISO': 'ZM',
    'China': '赞比亚',
    'Alias': [
      'Zambia',
      'Замбия',
      'Республика Замбия',
      'ZM'
    ]
  },
  {
    'Country': 'Zimbabwe',
    'Country_Ru': 'Зимбабве',
    'ISO': 'ZW',
    'China': '津巴布韦',
    'Alias': [
      'Zimbabwe',
      'Зимбабве',
      'Республика Зимбабве',
      'ZW'
    ]
  }
];
