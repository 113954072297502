import styled from 'styled-components';

export const AppsSearchBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SearchInput = styled.input`
  background-color: inherit;
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 465px;
  min-height: 30px;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid rgba(62, 62, 62, 0.5);
  border-radius: 5px;
  max-width: 250px;
  cursor: pointer;
`;

export const AppsList = styled.div`
  display: ${(props) => props.open ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 40px;
  width: 100%;
  list-style-type: none;
  padding: 0;
  max-height: 600px;
  overflow: auto;
  z-index: 10;
`;

export const AppItem = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;

  &:hover {
    background: #d7d7d7;
  }
`;

export const AppSpan = styled.span``;

export const AppIcon = styled.img`
  max-width: 20px;
  object-fit: contain;
`;

export const ClearBtn = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  box-sizing: content-box;
  line-height: 100%;
`;