import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LoginWindow = styled.div`
    width: 400px;
    margin-top: 200px;
    padding: 40px;
    background: #3E3E3E;
    box-shadow: 0 15px 25px #727dd8;
    border-radius: 10px;
`;

export const StyledH2 = styled.h2`
    margin: 0 0 30px;
    padding: 0;
    color: #ffffff;
    text-align: center;
`;

export const LoginForm = styled.form``;

export const InputBlock = styled.div``;

export const StyledInput = styled.input`
    position: relative;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #ffffff;
    outline: none;
    background: transparent;

    &:hover + label {
        top: -85px;
        left: 0;
        color: #727dd8;
        font-size: 12px;
    }

    &:valid + label {
        top: -85px;
        left: 0;
        color: #727dd8;
        font-size: 12px;
    }
`;

export const InputLabel = styled.label`
    position: relative;
    left: 0;
    top: -60px;
    padding: 10px 0;
    font-size: 16px;
    color: #ffffff;
    pointer-events: none;
    transition: .5s;
`;

export const ButtonForm = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

export const SubmitButton = styled.button`
    padding: 10px 20px;
    color: #cbbddb;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 4px;
    border: 1px solid #727dd8;
    margin: auto;
    background: transparent;

    :hover {
        background: #727dd8;
        color: #ffffff;
        border-radius: 5px;
        box-shadow: 0 0 5px #727dd8, 0 0 25px #727dd8, 0 0 50px #727dd8, 0 0 100px #727dd8;
    }
`;

export const RegisterBlock = styled.div`
    font-size: 14px;
    text-decoration: none;
    color: #ffffff;
    margin: auto;
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
`;

export const RegisterHref = styled.a`
    margin: auto;
    color: #727dd8;
    text-decoration: none;
`;

