import styled from 'styled-components';
import deleteSvg from '../../../Images/icons/delete-mark.svg';
import statisticIcon from '../../../Images/icons/statistic-icon.svg'

const activeBtn = 'linear-gradient(323.88deg, #649C77 5.13%, #A6FFC4 93.73%)';
const disabledBtn = 'linear-gradient(323.88deg, #939393 5.13%, #E7E7E7 93.73%)';

export const StyledCard = styled.li`
  font-family: 'Urbanist', sans-serif;
  display: block;
  height: 200px;
  color: #3E3E3E;
  box-shadow: 2px 2px 0 #D7D7D7;
  box-sizing: border-box;
  flex: 0 1 32%;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 35px;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const CardImg = styled.img`
  display: block;
  object-fit: fill;
  width: 100px;
  height: 100px;
  border-radius: 10px;
`;

export const CardBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: auto;
  margin-left: 26px;
  width: 100%;
  height: 100%;

  a {
    display: block;
    width: max-content;
  }
`;

export const CardAppName = styled.h3`
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  margin: 0;
  display: block;
  width: 100%;
  word-wrap: anywhere;
`;

export const CardPlatform = styled.img`
  max-width: 21px;
  max-height: 21px;
  object-fit: contain;
`;

export const CardButton = styled.button`
  font-family: 'Urbanist', sans-serif;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 15px;
  background-color: #3E3E3E;
  max-width: 85px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #E7E7E7;
  }
`;

export const StatusButton = styled.div`
  position: relative;
  max-width: 19px;
  width: 100%;
  height: 19px;
  border-radius: 18.5px;
  background: ${props => props.status ? activeBtn : disabledBtn};
  align-self: flex-end;
`;

export const DeleteButton = styled.button`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(${deleteSvg});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin: 35px 35px 0 0;
  width: 15px;
  height: 15px;
  border: 0;
`;

export const DeleteModal = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 67px 40px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  gap: 70px;
`;

export const ModalHeading = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 100%;
  color: #3E3E3E;
  text-align: center;
`;

export const ModalText = styled.p`
  font-family: 'Urbanist', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  text-align: center;
`;

export const ModalButtonBlock = styled.div`
  display: flex;
  gap: 90px;
`;

export const RemoveButton = styled.button`
  background: transparent;
  max-width: 100px;
  padding: 5px 24px;
  border: 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #000000;

  &:hover {
    color: #3e3e3e;
  }
`;

export const StatisticButton = styled.button`
  background-color: transparent;
  background-image: url(${statisticIcon});
  background-repeat: no-repeat;
  background-size: contain;
  width: 19px;
  height: 19px;
  border: 0;

  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
`;

export const CancelButton = styled.button`
  background-color: #D27373;
  border: 0;
  max-width: 100px;
  padding: 5px 24px;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  border-radius: 5px;
`;