import styled from 'styled-components';

export const SearchInput = styled.input`
background-color: inherit;
color: #3E3E3E;
font-family: 'Urbanist', sans-serif;
font-weight: 600;
font-size: 14px;
width: 465px;
min-height: 30px;
padding: 8px 15px;
box-sizing: border-box;
border: 1px solid rgba(62, 62, 62, 0.5);
border-radius: 5px;
margin-left: 30px;
`;