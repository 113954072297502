import React, {forwardRef} from 'react';
import {AddButton, ClearBtn, FilterRow, StyledDataPicker, StyledLabel, TimeOption} from "../../styled";
import AppsSearchSelect from "../../../components/apps-search-select/apps-search-select";
import ClientsSearchSelect from "../../../components/clients-search-select/clients-search-select";
import DatePicker from "react-datepicker";

const OrdersFilterModule = ({
                              client,
                              selectedApp,
                              dateStart,
                              dateStop,
                              handleChangeSelectedApp,
                              handleChangeSelectedClient,
                              handleChangeStartDate,
                              handleChangeStopDate,
                              handleClearStartDate,
                              handleClearEndDate
                            }) => {
  const ExampleCustomInput = forwardRef(({value, onClick, placeholder}, ref) => (
    <StyledDataPicker onClick={onClick} ref={ref} value={value} placeholder={placeholder} readOnly/>
  ));

  return (
    <FilterRow>
      <AddButton onClick={() => {
        window.location.href = `/NewCampaign/create`
      }}>Add Campaign</AddButton>
      <StyledLabel icon={'search'}>
        <AppsSearchSelect
          client={client}
          handleSelectApp={handleChangeSelectedApp}
          selectedApp={selectedApp}
          selectorType={'storeId'}
        />
      </StyledLabel>
      <StyledLabel icon={'user'}>
        <ClientsSearchSelect
          handleSelectClient={handleChangeSelectedClient}
          selectedClient={client}
          selectedApp={selectedApp}
        />
      </StyledLabel>
      <StyledLabel>
        <TimeOption>
          <DatePicker
            onChange={handleChangeStartDate}
            selected={dateStart}
            customInput={<ExampleCustomInput/>}
            placeholderText={'Start date'}
            closeOnScroll={true}
            dateFormat="yyyy-MM-dd"
            locale="en"
            dayClassName={(date) =>
              date.getDay() === 1 ? "monday" : undefined
            }
            maxDate={dateStop ? new Date(dateStop) : null}
          />
          {dateStart ? <ClearBtn onClick={handleClearStartDate}>✖</ClearBtn> : ''}
        </TimeOption>
        <TimeOption>
          <DatePicker
            onChange={handleChangeStopDate}
            selected={dateStop}
            customInput={<ExampleCustomInput/>}
            placeholderText={'Stop date'}
            closeOnScroll={true}
            dateFormat="yyyy-MM-dd"
            locale="en"
            dayClassName={(date) =>
              date.getDay() === 1 ? "monday" : undefined
            }
            minDate={new Date(dateStart)}
          />
          {dateStop ? <ClearBtn onClick={handleClearEndDate}>✖</ClearBtn> : ''}
        </TimeOption>
      </StyledLabel>
    </FilterRow>
  );
};

export default OrdersFilterModule;