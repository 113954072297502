import styled from 'styled-components';

export const StyledAddCard = styled.button`font-family: 'Urbanist', sans-serif;
  display: block;
  height: 200px;
  color: #3E3E3E;
  box-shadow: 2px 2px 0 #D7D7D7;
  box-sizing: border-box;
  flex: 0 1 32%;
  background-color: #ffffff;
`;

export const AddCardWrapper = styled.div`
  display: flex;
  padding: 35px;
  gap: 26px;
`;

export const AddCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #3E3E3E;
  border-radius: 10px;

  &::after {
    content: '';
    margin: auto auto;
    position: absolute;
    display: block;
    width: 8px;
    height: 69px;
    border-radius: 50px;
    background-color: #ffffff;
  }

  &::before {
    content: '';
    margin: auto auto;
    position: absolute;
    display: block;
    width: 69px;
    height: 8px;
    border-radius: 50px;
    background-color: #ffffff;
  }
`;

export const AddCardText = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  margin: 0;
`;

