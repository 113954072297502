import React, {useCallback, useEffect, useRef, useState} from 'react';
import CreateNewCampaign from "./CreateNewCampaign";
import {useParams} from "react-router-dom";
import API from "../../Api/API";
import RecreateCampaign from "./RecreateCampaign";
import {
  getFormattedDate,
  throwToastError,
  throwToastSuccess,
  validationAvailableBalance,
  validationFinalCampaign
} from "../utils";
import ExtendCampaign from "./ExtendCampaign";
import PromoteCampaign from "./PromoteCampaign";
import {ERRORS_TEXTS} from "./constants";
import {Loader} from "rsuite";

const NewCampaignModule = () => {
  const {scenario, id} = useParams();

  const [allSuppliersList, setAllSuppliersList] = useState([]);
  const [allClientsList, setAllClientsList] = useState([]);
  const [allApplicationsList, setAllApplicationsList] = useState([]);

  const [selectedSupplier, setSelectedSupplier] = useState(0)
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState(0);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateStop, setSelectedDateStop] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const [currentClientCost, setCurrentClientCost] = useState(0);
  const [selectedClientInfo, setSelectedClientInfo] = useState(null);
  const [selectedApplicationInfo, setSelectedApplicationInfo] = useState(null);

  const [checkboxesValue, setCheckboxesValue] = useState(0);
  const [selectedDelayStart, setSelectedDelayStart] = useState(null); //'17:00 +00:00'

  const scenarioComponentRef = useRef(null);
  const [sendingCampaign, setSendingCampaign] = useState(false);

  const handleSetSupplier = useCallback((supplierId) => {
    setSelectedSupplier(supplierId)
  }, [])

  const handleSetClient = useCallback((clientId) => {
    setSelectedClient(clientId)
  }, [])

  const handleSetType = useCallback((type) => {
    setSelectedType(type)
  }, [])

  const handleSetApplication = (applicationId) => {
    setSelectedApplication(applicationId)
  }

  const handleSetDateStart = useCallback((dateStart) => {
    setSelectedDateStart(dateStart);
  }, [])

  const handleSetDateStop = useCallback((dateStop) => {
    setSelectedDateStop(dateStop)
  }, [])

  const handleSetCountriesList = useCallback((newCountriesList) => {
    setCountriesList(newCountriesList);
  }, []);

  const handleSetCheckboxesValue = useCallback((value) => {
    setCheckboxesValue(value)
  }, []);

  const handleSetSelectedDelayStart = useCallback((timeString) => {
    setSelectedDelayStart(timeString)
  }, [])

  useEffect(() => {
    //получение информации об определенном клиенте для расчета стоимости или баланса
    if (selectedClient) {
      API.findOneClient(selectedClient).then(result => setSelectedClientInfo(result.data))
    } else {
      setSelectedClientInfo(null)
    }
  }, [selectedClient])

  useEffect(() => {
    //получение информации об определенном приложении для расчета стоимости или баланса
    if (selectedApplication) {
      API.findOneApp(selectedApplication).then(result => setSelectedApplicationInfo(result.data))
    } else {
      setSelectedApplicationInfo(null)
    }
  }, [selectedApplication]);

  const handleSetCurrentClientCost = useCallback((cost) => setCurrentClientCost(cost), [])

  useEffect(() => {
    API.GetSuppliers(0, 999, '').then(data => {
      setAllSuppliersList(data.data)
    });
    API.GetClients(0, 999, '').then(data => {
      setAllClientsList(data.data)
    })
    API.GetApps(0, 999999, 0, '').then(data => {
      setAllApplicationsList(data.data);
    });
  }, []);

  const getNewAllAppsList = async () => {
    API.GetApps(0, 999999, 0, '').then(data => {
      setAllApplicationsList(data.data);
    });
  }

  const orderTypes = {
    0: '',
    1: 'install',
    2: 'rate',
    3: 'install_bp'
  }

  const handleSendNewCampaign = () => {
    const finalCampaignObject = {
      supplier_id: selectedSupplier,
      app_id: selectedApplication,
      client: selectedClient,
      type: !!selectedType && orderTypes[selectedType],
      settings_message: checkboxesValue,
      delayed_start: selectedDelayStart,
      date_start: getFormattedDate(selectedDateStart),
      date_stop: getFormattedDate(selectedDateStop),
      keywords: countriesList
    }

    const validationErrors = validationFinalCampaign(finalCampaignObject);

    if (selectedClientInfo && countriesList && currentClientCost && !validationAvailableBalance(selectedClientInfo, countriesList, currentClientCost)) {
      validationErrors.push('lack_money');
    }

    delete finalCampaignObject.client;

    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => throwToastError(ERRORS_TEXTS[error]))
    } else {
      setSendingCampaign(true)
      API.NewCampaign(finalCampaignObject).then((result) => {
        if (result.status !== 200) {
          setSendingCampaign(false)
          throwToastError(result.data.status)
          return
        }

        if (scenarioComponentRef.current && scenarioComponentRef.current.handleResetForm) {
          scenarioComponentRef.current.handleResetForm();
        }

        setSendingCampaign(false)

        throwToastSuccess('Campaign was successfully created')

        const campaignId = result.data.campaign_id;
        window.open(API.ApiUrl + '/xlsx/' + campaignId + '.xlsx', '_blank');
        setSendingCampaign(false)
        window.location.href = '/Campaigns';

        // console.log({
        //   supplier: selectedSupplier,
        //   app: selectedApplication,
        //   type: selectedType,
        //   startDate: getFormattedDate(selectedDateStart),
        //   endDate: getFormattedDate(selectedDateStop),
        //   countries: countriesList
        // })
      }).catch((reason) => console.log(reason))
    }
  }

  return (
    <>
      {scenario === 'create' &&
        <CreateNewCampaign
          ref={scenarioComponentRef}
          allSuppliersList={allSuppliersList}
          allClientsList={allClientsList}
          allApplicationsList={allApplicationsList}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedType={selectedType}
          selectedApplication={selectedApplication}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          countriesList={countriesList}
          currentClientCost={currentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          handleSetSupplier={handleSetSupplier}
          handleSetClient={handleSetClient}
          handleSetType={handleSetType}
          handleSetApplication={handleSetApplication}
          handleSetDateStart={handleSetDateStart}
          handleSetDateStop={handleSetDateStop}
          handleSetCountriesList={handleSetCountriesList}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          handleSendNewCampaign={handleSendNewCampaign}
          getNewAllAppsList={getNewAllAppsList}
          checkboxesValue={checkboxesValue}
          handleSetCheckboxesValue={handleSetCheckboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSetSelectedDelayStart={handleSetSelectedDelayStart}
        />
      }
      {scenario === 'duplicate' &&
        <RecreateCampaign
          ref={scenarioComponentRef}
          allSuppliersList={allSuppliersList}
          allClientsList={allClientsList}
          allApplicationsList={allApplicationsList}
          campaignId={id}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedType={selectedType}
          selectedApplication={selectedApplication}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          countriesList={countriesList}
          currentClientCost={currentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          handleSetSupplier={handleSetSupplier}
          handleSetClient={handleSetClient}
          handleSetType={handleSetType}
          handleSetApplication={handleSetApplication}
          handleSetDateStart={handleSetDateStart}
          handleSetDateStop={handleSetDateStop}
          handleSetCountriesList={handleSetCountriesList}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          handleSendNewCampaign={handleSendNewCampaign}
          checkboxesValue={checkboxesValue}
          handleSetCheckboxesValue={handleSetCheckboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSetSelectedDelayStart={handleSetSelectedDelayStart}
        />
      }
      {scenario === 'extend' &&
        <ExtendCampaign
          ref={scenarioComponentRef}
          allSuppliersList={allSuppliersList}
          allClientsList={allClientsList}
          allApplicationsList={allApplicationsList}
          campaignId={id}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedType={selectedType}
          selectedApplication={selectedApplication}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          countriesList={countriesList}
          currentClientCost={currentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          handleSetSupplier={handleSetSupplier}
          handleSetClient={handleSetClient}
          handleSetType={handleSetType}
          handleSetApplication={handleSetApplication}
          handleSetDateStart={handleSetDateStart}
          handleSetDateStop={handleSetDateStop}
          handleSetCountriesList={handleSetCountriesList}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          handleSendNewCampaign={handleSendNewCampaign}
          checkboxesValue={checkboxesValue}
          handleSetCheckboxesValue={handleSetCheckboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSetSelectedDelayStart={handleSetSelectedDelayStart}
        />
      }
      {scenario === 'promote' && !!allApplicationsList.length &&
        <PromoteCampaign
          ref={scenarioComponentRef}
          allSuppliersList={allSuppliersList}
          allClientsList={allClientsList}
          allApplicationsList={allApplicationsList}
          appId={id}
          selectedSupplier={selectedSupplier}
          selectedClient={selectedClient}
          selectedType={selectedType}
          selectedApplication={selectedApplication}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          countriesList={countriesList}
          currentClientCost={currentClientCost}
          selectedClientInfo={selectedClientInfo}
          selectedApplicationInfo={selectedApplicationInfo}
          handleSetSupplier={handleSetSupplier}
          handleSetClient={handleSetClient}
          handleSetType={handleSetType}
          handleSetApplication={handleSetApplication}
          handleSetDateStart={handleSetDateStart}
          handleSetDateStop={handleSetDateStop}
          handleSetCountriesList={handleSetCountriesList}
          handleSetCurrentClientCost={handleSetCurrentClientCost}
          handleSendNewCampaign={handleSendNewCampaign}
          checkboxesValue={checkboxesValue}
          handleSetCheckboxesValue={handleSetCheckboxesValue}
          selectedDelayStart={selectedDelayStart}
          handleSetSelectedDelayStart={handleSetSelectedDelayStart}
        />
      }
      {!!sendingCampaign && <Loader backdrop size="lg" content="sending..." vertical/>}
    </>
  );
};

export default NewCampaignModule;