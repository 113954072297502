import * as React from "react";
import {useState} from "react";
import API from "../../../Api/API";
import {
  EditBtn,
  AddForm,
  StyledH1,
  StyledInputTitle,
  StyledInput,
  StyledSelect,
  InputWrapper,
  InputGap
} from './styled';

const NewTariff = ({reload, close}) => {
  const [tariff, setTariff] = useState({
    name: null,
    type: 'turnover',
    percent: null
  });

  const handleChange = (object) => {
    setTariff({
      ...tariff,
      ...object,
    });
  };

  const handleSubmit = () => {
    if (tariff.name) {
      API.addTariff(tariff).then(r => {
        reload(true);
        close();
      })
    } else {
      alert('Fill in required fields')
    }
  }

  return (<>
    <AddForm>
      <StyledH1>Add tariff</StyledH1>
        <InputGap>
          <InputWrapper>
            <StyledInputTitle>Name</StyledInputTitle>
            <StyledInput
              placeholder="Enter the name"
              onChange={(e) => handleChange({name: e.target.value})}/>
          </InputWrapper>
          
          <InputWrapper>
            <StyledInputTitle>Type</StyledInputTitle>
            <StyledSelect placeholder="Enter the type" onChange={(e) => handleChange({type: e.target.value})}>
              <option value="turnover">Turnover</option>
              <option value="marginality">Marginality</option>
            </StyledSelect>
          </InputWrapper>

          <InputWrapper>
            <StyledInputTitle>Percent</StyledInputTitle>
            <StyledInput
              placeholder="Enter the percent"
              type='number'
              onChange={(e) => {
                if (e.target.value) {
                  handleChange({percent: parseFloat(e.target.value)})
                } else {
                  handleChange({percent: null})
                }
              }}/>
          </InputWrapper>
        </InputGap>
      <EditBtn onClick={handleSubmit}>Edit</EditBtn>
    </AddForm>
  </>)
}

export default NewTariff;