import * as React from "react";
import {useState} from "react";
import API from "../../../Api/API";
import {
  EditBtn,
  EditForm,
  EditFormWrapper,
  StyledH1,
  StyledH2,
  StyledSelect
} from './styled';

const EditAdmin = ({curUser, reload, close, tariffs}) => {

  const [user, setUser] = useState({
    id: curUser.id,
    tariff: curUser.tariff,
  });

  const handleChange = (object) => {
    setUser({
      ...user,
      ...object,
    });
  };

  const handleSubmit = () => {
      API.editAdminTariff(user).then(r => {
        reload(true);
        close();
      })
  }

  return (<>
    <EditForm>
      <StyledH1>Edit admin</StyledH1>
      <EditFormWrapper>
          <StyledH2>Tariff</StyledH2>
          <StyledSelect onChange={(e) => {handleChange({tariff: Number(e.target.value)})}}>
            {tariffs.length !== 0 && tariffs.map(item => 
              <option value={item.id}>{item.name}</option>
            )} 
          </StyledSelect>
      </EditFormWrapper>
      <EditBtn onClick={handleSubmit}>Edit</EditBtn>
    </EditForm>
  </>)
}

export default EditAdmin;