import * as React from 'react';
import {DialogTitle, DialogContent, Dialog} from '@mui/material';
import {Loader} from 'rsuite';

import AddIcon from '../../Images/icons/add-square-icon.svg';
import LogsIcon from '../../Images/icons/logs-icon.svg';
import UploadIcon from '../../Images/icons/document-upload-icon.svg';
import EditRowIcon from '../../Images/icons/edit-row-icon.svg';
import {useEffect, useState} from "react";

import API from "../../Api/API";
import NewUser from "./add-new-user/new-user";
import EditUser from "./edit-user/edit-user";
import {
  ControlBlock,
  ControlButton,
  ControlButtons,
  PageContainer,
  PageHeading,
  PageWrapper,
  SearchInput, StyledHeadTr, StyledTable, StyledTbody, StyledTh, StyledThead, StyledTr, TableBlock
} from './styled';
import useDebounce from '../utils/use-debounce';
import ChangesLogs from '../components/changes-logs/changes-logs';
import BalanceLogs from '../components/balance-logs/balance-logs';
import {Link} from "react-router-dom";
import AddBalanceModal from "../components/AddBalanceModal";

const Users = () => {
  const [List, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openChangesLogs, setOpenChangesLogs] = useState(false);
  const [openBalanceLogs, setOpenBalanceLogs] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [userId, setUserId] = useState(null);
  const [curUser, setCurUser] = useState();
  const [loading, setLoading] = useState(true);
  const [clientsSearch, setClientsSearch] = useState();
  const [tariffList, setTariffList] = useState(null);
  const [adminList, setAdminList] = useState(null);

  const debouncedSearch = useDebounce(clientsSearch, 500);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenAdd(false);
    setOpen(false);
    setOpenChangesLogs(false);
    setOpenEdit(false);
    setOpenBalanceLogs(false);
  };
  useEffect(() => {
    const getClientAndTariff = async () => {
      await API.GetClients(0, 10000, '').then((data) => {
        setList(data.data);
      })
      await API.getAllTariffs(0, 10000, '').then((data) => {
        setTariffList(data.data);
      });
      await API.getAllUsers(0, 10000, '').then((data) => {
        setAdminList(data.data);
      });
      setLoading(false);
    }
    getClientAndTariff();
  }, [])

  useEffect(() => {
    if (debouncedSearch !== undefined) {
      API.GetClients(0, 10000, debouncedSearch).then((data) => {
        if (data) {
          setList(data.data)
          setLoading(false)
        }
      })
    }
  }, [debouncedSearch]);

  const handleRestartTable = () => {
    setLoading(true)
    API.GetClients(0, 10000, '').then((data) => {
      if (data) {
        setList(data.data)
        setLoading(false);
      }
    })
  }

  const handleSearch = (e) => {
    setClientsSearch(e.target.value);
  }

  return (
    <>
      {loading ? <Loader backdrop size="lg" content="loading..." vertical/> :
        <>
          <PageContainer>
            <PageWrapper>
              <PageHeading>Clients</PageHeading>
              <ControlBlock>
                <SearchInput onChange={handleSearch} placeholder={'Search client'}/>
                <ControlButtons>
                  <Link to="/Financial"><ControlButton>Financial</ControlButton></Link>
                  <ControlButton onClick={handleClickOpen}>Add client</ControlButton>
                </ControlButtons>
              </ControlBlock>
              <TableBlock>
                <StyledTable>
                  <StyledThead>
                    <StyledHeadTr>
                      <StyledTh>ID</StyledTh>
                      <StyledTh>Name</StyledTh>
                      <StyledTh>Balance</StyledTh>
                      <StyledTh>Client's tariff</StyledTh>
                      <StyledTh>Partner's tariff</StyledTh>
                      <StyledTh>Partner</StyledTh>
                      <StyledTh>Add Balance</StyledTh>
                      <StyledTh>Android</StyledTh>
                      <StyledTh>Install</StyledTh>
                      <StyledTh>Rate</StyledTh>
                      <StyledTh>IOS</StyledTh>
                      <StyledTh>Install</StyledTh>
                      <StyledTh>Rate</StyledTh>
                      <StyledTh></StyledTh>
                      <StyledTh></StyledTh>
                      <StyledTh></StyledTh>
                    </StyledHeadTr>
                  </StyledThead>
                  <StyledTbody>
                    {List.length !== 0 &&
                      List.map(client =>
                        <StyledTr key={client.id}>
                          <StyledTh>{client.id}</StyledTh>
                          <StyledTh>{client.name}</StyledTh>
                          <StyledTh>{(client.balance)?.toFixed(2)} $</StyledTh>
                          <StyledTh>{tariffList.find((item) => item.id === client.custom_tariff)?.name}</StyledTh>
                          <StyledTh>{tariffList.find((item) => item.id === client.tariff)?.name}</StyledTh>
                          <StyledTh>{(client.referral < 0) ? adminList.find((item) => item.id === client.referral *-1)?.name : List.find((item) => item.id === client.referral)?.name}</StyledTh>
                          <StyledTh>
                            <img src={AddIcon} style={{cursor: "pointer"}} height={20} width={20} alt=""
                                 onClick={() => {
                                   setOpenAdd(true);
                                   setUserId(client.id);
                                 }}/>
                          </StyledTh>
                          <StyledTh></StyledTh>
                          <StyledTh>{client.install_cost}</StyledTh>
                          <StyledTh>{client.rate_cost}</StyledTh>
                          <StyledTh></StyledTh>
                          <StyledTh>{client.a_install_cost}</StyledTh>
                          <StyledTh>{client.a_rate_cost}</StyledTh>
                          <StyledTh>
                            <img src={LogsIcon}
                                 style={{cursor: "pointer"}}
                                 onClick={() => {
                                   setOpenChangesLogs(true);
                                   setUserId(client.id);
                                 }} alt=""/>
                          </StyledTh>
                          <StyledTh>
                            <img src={UploadIcon}
                                 style={{cursor: "pointer"}}
                                 onClick={() => {
                                   setOpenBalanceLogs(true);
                                   setUserId(client.id);
                                 }}
                                 alt=""/>
                          </StyledTh>
                          <StyledTh>
                            <img src={EditRowIcon}
                                 style={{cursor: "pointer"}}
                                 onClick={async () => {
                                   setCurUser(client);
                                   setOpenEdit(true);
                                 }}
                                 alt=""
                            />
                          </StyledTh>
                        </StyledTr>
                      )
                    }
                  </StyledTbody>
                </StyledTable>
              </TableBlock>
            </PageWrapper>
          </PageContainer>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={'800'}
          >
            <DialogContent dividers>
              <NewUser adminList={adminList} tariffs={tariffList} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
            maxWidth={'800'}
          >
            <DialogContent dividers>
              <EditUser adminList={adminList} curUser={curUser} tariffs={tariffList} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openAdd}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Add balance
            </DialogTitle>
            <DialogContent dividers>
              <AddBalanceModal id={userId} type={'client'} reload={handleRestartTable} close={handleClose}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openChangesLogs}
            maxWidth={'1200'}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Changes logs
            </DialogTitle>
            <DialogContent dividers>
              <ChangesLogs type={'clients'} logId={userId} close={handleClose} typeLog={'client'}/>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openBalanceLogs}
            maxWidth={'1200'}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Balance logs
            </DialogTitle>
            <DialogContent dividers>
              <BalanceLogs type={'clients'} logId={userId} close={handleClose}/>
            </DialogContent>
          </Dialog>
        </>
      }
    </>
  )
}
export default Users;