import {React, useState} from 'react';
import API from '../../Api/API';
import {Container, LoginWindow, RegisterHref, ButtonForm, SubmitButton, RegisterBlock, StyledH2, StyledInput, InputLabel, } from './styled';

const Login = () => {
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');

  const error = () => {
    console.log('error')
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await API.GetToken(login, pass) ? window.location = '/' : error()
  }

  return (
    <Container>
      <LoginWindow>
        <StyledH2>Login</StyledH2>
        <form action="#">
          <div className="user-box">
            <StyledInput
              required
              type="text"
              onChange={(e) => {
                setLogin(e.target.value);
              }}
            />
            <InputLabel>Username</InputLabel>
          </div>
          <div className="user-box">
            <StyledInput
              required
              type="password"
              onChange={(e) => {
                setPass(e.target.value);
              }}
            />
            <InputLabel>Password</InputLabel>
          </div>

          <ButtonForm>
            <SubmitButton
              onClick={handleSubmit}
            >Login</SubmitButton>

            <RegisterBlock>
              Dont have an account ?
              <RegisterHref href="">Register</RegisterHref>
            </RegisterBlock>
          </ButtonForm>

        </form>
      </LoginWindow>
    </Container>
  );
}

export default Login;
