import styled from 'styled-components';

export const StyledOptionItem = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  max-width: 400px;
`;

export const OptionTitle = styled.span`
  text-align: right;
`;

export const OptionSelect = styled.select`
  padding: 15px 10px 15px 15px;
  border: 1px solid #3E3E3E;
  border-radius: 5px;
  width: 245px;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  cursor: pointer;

  & option {
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #3E3E3E;
  }
`;

export const ClearBtn = styled.button`
  width: 14px;
  height: 14px;
  border: 0;
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  box-sizing: content-box;
  line-height: 90%;
  background-color: #ffffff;
`;