import styled from 'styled-components';
import clockImg from '../../../Images/icons/clock-icon.svg';

export const LogsBlock = styled.div`
    padding: 30px 47px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const FilterBlock = styled.div`
    display: flex;
    gap: 40px;
`;

export const DateFilterLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    &::before {
        content: '';
        display: block;
        background-image: url(${clockImg});
        background-repeat: no-repeat;
        background-size: contain;
        width: 22px;
        height: 22px;
    }
`;

export const DateFilterBlock = styled.div`
    display: flex;
    gap: 20px;
`;

export const DateInput = styled.div`
    position: relative;
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #3E3E3E;
    max-width: 150px;
`;

export const StyledDataPicker = styled.input`
    background-color: inherit;
    color: #3E3E3E;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    font-size: 14px;
    width: 465px;
    min-height: 30px;
    padding: 8px 15px;
    box-sizing: border-box;
    border: 1px solid rgba(62, 62, 62, 0.5);
    border-radius: 5px;
    max-width: 150px;
    cursor: pointer;
`;

export const TableBlock = styled.div`
    
    margin-top: 30px;
    min-height: 400px;
    max-height: 600px;
    overflow: auto;
    width: 100%;
`;

export const StyledTable = styled.table`
    width: 100%;
    overflow: hidden;
`;

export const StyledThead = styled.thead`
    background: rgba(222, 222, 222, 0.82);
    border-top: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledHeadTr = styled.tr`
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
`;

export const StyledTbody = styled.tbody``;

export const StyledTr = styled.tr`
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #3E3E3E;
    border-bottom: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledTh = styled.th`
    padding: 12px;
    color: ${props => props.color === 'green' ? '#649C77' : props.color === 'red' ? '#AF3A3A' : '#3e3e3e'};
    ${props => props.date && 'white-space: nowrap;'}
    ${props => props.comment && 'word-wrap: break-word; cursor: pointer;'}
`;

export const CloseButton = styled.button`
    margin-top: 30px;
    background-color: #3e3e3e;
    border: 0;
    color: #ffffff;
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    padding: 5px 30px;
    border-radius: 5px;
    align-self: center;
`;

export const ClearBtn = styled.button`
  font-size: 14px;
  width: 14px;
  height: 14px;
  border: 0;
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  box-sizing: content-box;
  line-height: 100%;
  background-color: #ffffff;
`;